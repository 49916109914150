import React, { useEffect, useState, useRef } from 'react';
import InnerpageHeader from './InnerpageHeader';
import Footer from './Footer';
import '../style/thoughts.css';
import queImage01 from '../assets/images/thought-starter-Q01.png'; 
import queImage02 from '../assets/images/thought-starter-Q02.png'; 
import queImage03 from '../assets/images/thought-starter-Q03.png'; 
import queImage04 from '../assets/images/thought-starter-Q04.png'; 
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios';

function ThoughtStarter(props) {

    const [ queCount, setQueCount ] = useState( 1 );

    const queImages = [ queImage01, queImage02, queImage03, queImage04 ];

    const queTexts = [ 'What do you see? Tell us', 'And this? What is this?', 'Can you see this? Tell us what you see', 'Clue, its not the flag. What is it?' ];

    const [ answers, setAnswers ] = useState( {} );

    const ansRef = useRef();

    const [ finished, setFinished ] = useState( false );

    const [ showThankyou, setShowThankyou ] = useState( false );

    const pleaseWait = useRef()

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.body.classList.add('inner-page', 'thoughts-page');
    }, []);

    const handleChange = () => {
        setAnswers( (prevAnswers) => ( { ...prevAnswers,  ['ts_ans_0'+queCount] : ansRef.current.value } ) );
    }

    const submitHandler = (e) => {
        e.preventDefault();
        console.log( answers )
        if ( queCount !== 4 ) {
            setQueCount( (prevQueCount) => ( prevQueCount + 1 ) );
            ansRef.current.value = ''
            if( queCount === 3 ) setFinished( true )
        } else {
            pleaseWait.current.style.display = 'block';
            if( Object.keys(answers).length === 0 ) {
                pleaseWait.current.textContent = 'Please provide some thoughts before submitting';
                setTimeout( () => window.location.reload() , 2000 );
            } else {
                pleaseWait.current.textContent = 'Please wait...'
                axios.post( 'https://sensiblyweird.com/phpmailer/thoughts_mail.php', answers ).then( response => {
                    console.log( response.data.data )
                    setShowThankyou( true )
                } )
            }
        }
    }

  return (
        <>
           <Helmet>
                <title>
                    {props.metaTitle}
                </title>
                <meta property="og:title" content={props.metaTitle} />
                <meta property="og:description" content={props.metaDescription} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={ window.location } />
                <meta property="og:image" content="" />
            </Helmet>
          <InnerpageHeader/>
          <div className='thoughtsPageContent' >
            <div className='thoughtspageBanner py-5'>
              <h1 className='text-center py-5' >
                THOUGHT STARTERS
              </h1>
            </div>
            <div className='thoughtsFormContainer py-5'>
                <div className='container'>
                    {
                        !showThankyou ? (
                            <div className='row g-lg-5 g-md-3 g-4 py-5 justify-content-md-center justify-content-center'>
                                <div className='col-lg-4 col-md-5 col-sm-7 col-9'>
                                    <img src={queImages[queCount - 1]} className='img-fluid' alt='thought starter' />
                                </div>
                                <div className='col-lg-5 col-md-7 thoughtsFormWrap'>
                                    <p className='mb-2' >
                                        {queTexts[queCount - 1]}
                                    </p>
                                    <textarea ref={ansRef} className='form-control' onChange={handleChange} placeholder='Write your answer here...' rows='6' required ></textarea>
                                    <div className='row justify-content-end m-0 pt-4'>
                                        <button className='btn btn-submit w-auto' onClick={submitHandler} >{ finished ? 'Submit' : 'Next' }</button>
                                    </div>
                                    <small className='text-center pt-4' ref={pleaseWait} >
                                        Please wait...
                                    </small>
                                </div>
                            </div>
                        ) : (
                           <div className='row py-5 justify-content-center thankyouWrap'>
                            <div className='col text-center'>
                                <h5 className='m-0' >
                                    You possess an unconventional nature, don't you?
                                </h5>
                                <p className='m-0' >
                                    Well, we believe you deserve a coffee on us. Feel free to join us at our meeting table for a delightful conversation.
                                </p>
                                <Link to='/' className='btn btn-submit mt-4' >Let's connect</Link>
                            </div>
                           </div>
                        )
                    }
                </div>
            </div>
          </div>
          <Footer />
        </>
  )
}

export default ThoughtStarter