import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import InnerpageHeader from '../../InnerpageHeader';
import Footer from '../../Footer';
import '../../../style/fonts.css';
import '../../../style/works.css';
import { Link } from 'react-router-dom';
import WorkPagination from '../WorkPagination';
import CategoryBadge from '../CategoryBadge';

function Lazza( props ) {
    useEffect(() => {
        document.body.removeAttribute('class');
        document.body.classList.add('inner-page', 'single-work-page');
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <>
            <Helmet>
                <title>
                    {props.metaTitle}
                </title>
                <meta property="og:title" content={props.metaTitle} />
                <meta property="og:description" content={props.metaDescription} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={ window.location } />
                <meta property="og:image" content="" />
            </Helmet>
            <InnerpageHeader/>
            <div className='single-work'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-xxl-12 col-11'>
                            <div className='pageBreadcrumb'>
                                <h6 className='m-0' >
                                    <Link to='/'>Home</Link> / <Link to='/works'>Works</Link> / Videos / Lazza Ice Creams
                                </h6>
                            </div>
                            <div className='singleWorkHead my-5'>
                                <h1 className='singleWorkTitle text-uppercase'>
                                Lazza Ice Creams
                                </h1>
                                <p>
                                For the low-budget Lazza campaign videos, the innovative idea was to combine motion graphics and photography. Models of different age groups were studio-shot, to be set in a creative world of our design; telling a story. The idea was to push the sugar-free ice creams with the excitement of the word 'free' within stories capturing the lives of different people. We used speech bubbles to communicate the dialogues in an old-comic book style, most of its content being just one word or a sound. A very economical solution for the client, while keeping creativity at its best.
                                </p>
                                <CategoryBadge badgeText={props.catBadgeText} badgeCircle={props.catBadgeCircle} badgeType="category" />
                            </div>
                            <div className='singleWorkContent videoWrap lazza p-3'>
                                <div className='row g-3'>
                                    <div className='col-12'>
                                        <iframe className='w-100 yt-video' src="https://www.youtube.com/embed/VPNE7JT4yQA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                    </div>
                                    <div className='col-12'>
                                        <iframe className='w-100 yt-video' src="https://www.youtube.com/embed/q9IfEKi9sVA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                    </div>
                                    <div className='col-12'>
                                        <iframe className='w-100 yt-video' src="https://www.youtube.com/embed/dt9rh9LpiHs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                    </div>
                                    <div className='col-12'>
                                        <iframe className='w-100 yt-video' src="https://www.youtube.com/embed/iEAvZbUE7Co" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                    </div>
                                    <div className='col-12'>
                                        <iframe className='w-100 yt-video' src="https://www.youtube.com/embed/o0kizMtCi-E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                    </div>
                                    <div className='col-12'>
                                        <iframe className='w-100 yt-video' src="https://www.youtube.com/embed/eeIdbWVFHqE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                    </div>
                                    <div className='col-md-6'>
                                        <iframe className='w-100 yt-video' src="https://www.youtube.com/embed/1zxpOEbTCmY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                    </div>
                                    <div className='col-md-6'>
                                        <iframe className='w-100 yt-video' src="https://www.youtube.com/embed/UykHoHU_2FI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                    </div>
                                    <div className='col-md-6'>
                                        <iframe className='w-100 yt-video' src="https://www.youtube.com/embed/T4UIhRqgmM4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                    </div>
                                    <div className='col-md-6'>
                                        <iframe className='w-100 yt-video' src="https://www.youtube.com/embed/Cp6mXH8G6tM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <WorkPagination current={props.workID} />
            </div>
            <Footer />
        </>
    )
}

export default Lazza