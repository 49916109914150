import React, { useEffect, useState } from 'react';
import InnerpageHeader from '../InnerpageHeader';
import Footer from '../Footer';
import '../../style/digital.css';
import image01 from '../../assets/images/digital-marketing/leadGenerationImage-001.png';
import image02 from '../../assets/images/digital-marketing/leadGenerationImage-002.png';
import image03 from '../../assets/images/digital-marketing/leadGenerationImage-003.png';
import image04 from '../../assets/images/digital-marketing/leadGenerationImage-004.png';
import { Helmet } from 'react-helmet';

function Seo(props) {

    const [ showMoreContent, setShowMoreContent ] = useState({
        para1 : false
    });

    const READ_MORE = 'read more';
    const READ_LESS = 'read less';

    useEffect(() => {
        document.body.removeAttribute('class');
        document.body.classList.add('inner-page', 'digital-seo-page');
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

  return (
    <>
   <Helmet>
                <title>
                    {props.metaTitle}
                </title>
                <meta property="og:title" content={props.metaTitle} />
                <meta property="og:description" content={props.metaDescription} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={ window.location } />
                <meta property="og:image" content="" />
            </Helmet>
        <InnerpageHeader/>
        <div className='servicesPageContent digitalPageContent category' >
            <div className='servicespageBanner'>
                <h1 className='text-center my-0 py-5 text-white'>
                    SEARCH ENGINE OPTIMIZATION
                </h1>
            </div>
            <div className='pt-5'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-11 col-sm-12 col-11'>
                            <div className='row justify-content-between g-4'>
                                <div className='col-sm-auto col-6'>
                                    <img src={image01} alt='seo-artwork-01' width="424" height="424" className='img-fluid lg-img01' />
                                </div>
                                <div className='col-sm-auto col-6 text-end'>
                                    <img src={image02} alt='seo-artwork-02' width="424" height="424" className='img-fluid lg-img02' />
                                </div>
                                <div className='col-sm-auto col-12'>
                                    <img src={image03} alt='seo-artwork-03' width="424" height="424" className='img-fluid lg-img03' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='py-5'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-9 col-sm-12 col-11'>
                            <p className='m-0' >
                                We believe that if you expend as much time learning people as we know Google, then you will win. Control over Google algorithms is having minimal possibility, so we choose to spend our time & effort in realizing your audience instead.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bgBlue py-5'>
                <div className='container'>
                    <div className='row g-0 justify-content-center'>
                        <div className='col-lg-11 col-sm-12 col-11'>
                            <h3 className='scriptText text-white text-center mb-4' >
                                Do your potential customers find you once they search online? Or do they see your competitors?
                            </h3>
                        </div>
                        <div className='col-lg-9 col-sm-12 col-11'>
                            <p className='m-0 textColor01 ' >
                                Our customer-centric perspective makes sure that your website, content, and all digital assets are being found online. However, this does not mean that Ranking 1 is our priority, instead getting ROI on your investment is.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='py-5 holyGrail'>
                <div className='container pt-5'>
                    <div className='row g-5 justify-content-center'>
                        <div className='col-lg-6 col-sm-9 col-10 pt-4'>
                            <img src={image04} alt='seo-artwork-04' width="424" height="424" className='img-fluid lg-img04' />
                        </div>
                        <div className='col-lg-9 col-sm-12 col-11'>
                            <p className='m-0' >
                                Did you know that a whopping 30% of website traffic is taken by businesses that rank on the first page of Google? But fear not, because we're here to help you do SEO smart.<br /><br />
                                Why waste your time and effort trying to control Google's ever-changing algorithms when you could be focusing on understanding your audience? At Sensibly Weird, we believe that paying attention to people's wants and needs is more important than chasing after Google's every whim.<br /><br />
                                Our customer-centric approach ensures that your website, content, and other digital assets are easily found online. But we're not just after a number one ranking; we want you to get a return on your investment.<br /><br />
                                So, let us help you achieve the Holy Grail of Google's first page and start driving more traffic to your website today.<br /><br />
                                SEO for the Sensibly Weird<br /><br />
                                <span className='text-center d-block' >We Cater to All Your SEO Objectives</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='lgHightlights'>
                <div className='container-fluid p-xxl-4 p-3'>
                    <div className='row g-xxl-4 g-3'>
                        <div className='col-lg-3 col-sm-6'>
                            <div className='lgh-box igbg-1'>
                                <h4 className='text-white' >
                                    Higher <br className='d-lg-inline d-none' />Rankings
                                </h4>
                                <p className='m-0 text-black' >
                                    Want to be found easily on the web? Our SEO services guarantee that your website will rank high on Google's search results. We know the importance of being on the first page of search results, and we work hard to get you there.
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-sm-6'>
                            <div className='lgh-box igbg-2'>
                                <h4 className='text-white' >
                                Targeted <br className='d-lg-inline d-none' />Traffic
                                </h4>
                                <p className='m-0 text-black' >
                                We understand the value of targeted traffic, which means getting the right kind of visitors to your website. Our SEO services drive relevant traffic to your site, which has a higher chance of converting into paying customers.
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-sm-6'>
                            <div className='lgh-box igbg-3'>
                                <h4 className='text-white' >
                                Increased <br className='d-lg-inline d-none' />Leads
                                </h4>
                                <p className='m-0 text-black' >
                                Our result-oriented SEO services help generate more leads by informing more visitors about your business. Our expert team ensures that your online presence is positively felt across all digital platforms.
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-sm-6'>
                            <div className='lgh-box igbg-4'>
                                <h4 className='text-white' >
                                SEO Services <br className='d-lg-inline d-none' />in its entirety!
                                </h4>
                                <p className='m-0 text-black' >
                                At Sensibly Weird, we believe that SEO is not just about ranking high on search results, but also about building a brand that connects with your audience. Our SEO services cover everything, from optimising your website for search engines to creating engaging content that resonates with your target audience.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='py-5 lgServices'>
                <div className='container pt-5'>
                    <div className='row g-3 justify-content-center'>
                        <div className='col-12 pt-4'>
                            <h3 className='comforterText text-center mb-1' >
                                SEO Services that will blow your mind
                            </h3>
                        </div>
                        <div className='col-lg-9 col-sm-12 col-11'>
                            <p className='m-0' >
                            Are you tired of being buried under layers of search results? Does your website feel like a tiny fish lost in the vast ocean of the internet? Fear not, for Sensibly Weird Company is here to help!<br/><br/>
                            Our top-notch SEO Services will help you reach new heights of online visibility and attract hordes of potential customers to your website. With our expert strategies, we'll help you climb the search engine rankings and dominate your niche.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='lgServices lgServices-bxs pb-5'>
                <div className='container mb-4'>
                    <div className='row g-md-0 g-3 justify-content-center'>
                        <div className='col-lg-10'>
                            <div className='igbg-5 px-5 py-4 igd'>
                                <h4 className='text-black mb-3' >
                                    Online Reputation Management that saves the day
                                </h4>
                                <p className='m-0' >
                                    We know that your reputation is everything in the digital world. One bad review can tarnish your image and drive potential customers away. That's why we offer the best Online Reputation Management services that will keep your online image sparkling clean. We'll monitor your online presence, respond to negative reviews, and showcase your best qualities to the world.
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-10'>
                            <div className='igbg-6 px-5 py-4 igd'>
                                <h4 className='text-black mb-3' >
                                Content Services that will make you a star
                                </h4>
                                <p className='m-0' >
                                In today's world, content is king. But with so much information out there, how do you make sure that your message stands out from the crowd? That's where we come in. Our Content Services will help you craft compelling content that resonates with your target audience and drives engagement. We'll help you tell your story in a way that captures hearts and minds.
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-10'>
                            <div className='igbg-7 px-5 py-4 igd'>
                                <h4 className='text-black mb-3' >
                                SEO for Local Business that puts you on the map
                                </h4>
                                <p className='m-0' >
                                Are you a local business struggling to make a mark in your community? Let us help you change that. Our SEO Services for Local Business will ensure that your customers can find you with ease. We'll optimize your website for local search, help you get listed on Google Maps, and drive foot traffic to your doorstep.
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-10'>
                            <div className='igbg-8 px-5 py-4 igd'>
                                <h4 className='text-black mb-3' >
                                Multilingual & Multiregional SEO that takes you global
                                </h4>
                                <p className='m-0' >
                                Are you ready to take your business to the world stage? Our Multilingual & Multiregional SEO services will help you do just that. With our expert team of multilingual SEO specialists, we'll help you conquer new markets and attract customers from all over the world. Whether you're targeting a specific region or looking to go global, we've got you covered.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='py-5 igbg-2 lgServices-2'>
                <div className='container py-4'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-9 col-sm-12 col-11 text-white'>
                            <h3 className='text-white mb-4' >
                                Looking to conquer the digital world<br className='d-lg-inline d-none' />
                                and leave your competitors in the  dust?
                            </h3>
                            <h3 className='text-white mb-5 pb-3' >
                                <b>
                                    <i>
                                        Sensibly Weird Company has got your back!
                                    </i>
                                </b>
                            </h3>
                            <p className='m-0' >
                                Our mind-blowing SEO Services will take your website from the depths of search results to the top of the rankings, attracting a flood of potential customers to your virtual doorstep. With our expert strategies and out-of-the-box thinking, we'll help you dominate your niche and make a name for yourself in the online world.<br/><br/>
                                Got a bad review or two dragging you down? Don't worry, our Online Reputation Management services will swoop in to save the day. We'll keep your online reputation spotless by monitoring your online presence, responding to negative reviews, and showcasing your best qualities to the world.<br/><br/>
                                {
                                    showMoreContent.para1 && (
                                        <span>
                                            In the age of information overload, standing out can be a challenge. But with our Content Services, you'll be a shining star in no time. Our team of expert content creators will help you craft compelling stories that resonate with your target audience and drive engagement. Get ready to capture hearts and minds with content that truly stands out.<br/><br/>
                                            Local business struggling to get noticed? Our SEO Services for Local Business will put you on the map. We'll help you get found by local customers by optimizing your website for local search and getting you listed on Google Maps. Say goodbye to obscurity and hello to foot traffic!<br/><br/>
                                            Ready to take the world by storm? Our Multilingual & Multiregional SEO services will help you do just that. Our team of multilingual SEO specialists will help you conquer new markets and attract customers from all over the globe. Whether you're looking to target a specific region or go global, we've got the expertise to make it happen.<br/><br/>
                                        </span>
                                    )
                                }
                            </p>
                            <span 
                                className='readMoreBtn-Text' 
                                onClick={() => {
                                    setShowMoreContent(prevState => {
                                        return {
                                            ...prevState,
                                            para1 : !prevState.para1
                                        }
                                    })
                                }}
                            >
                                {
                                    showMoreContent.para1 ? READ_LESS : READ_MORE
                                }
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='py-5 lg-faqs'>
                <div className='container py-4'>
                    <div className='row g-5 justify-content-center'>
                        <div className='col-lg-6 col-8'>
                            <h2 className='text-center igbg-8 p-3' >
                            FAQ's
                            </h2>
                        </div>
                        <div className='col-lg-9 col-sm-12 col-11'>
                            <div className='lgh-box pb-3'>
                                <h4 className='text-black mb-0' >
                                01
                                </h4>
                                <p className='m-0' >
                                    <span>Why does it take time for SEO to show results, and how can you speed up the process?</span><br/>
                                    SEO is a long-term investment that requires patience, dedication, and a strategic approach. While it may take several months for your website to start ranking higher on search engines, we use our expert strategies and tactics to speed up the process. Our team focuses on building reliable and relevant backlinks to your website, optimizing your content for keywords, and ensuring that your website is technically sound. This helps search engines to recognize the value of your website and improve its visibility in search results.
                                </p>
                            </div>
                            <div className='lgh-box pb-3'>
                                <h4 className='text-black mb-0' >
                                02
                                </h4>
                                <p className='m-0' >
                                <span>My competitor seems to be doing better in search results. How can I catch up to them?</span><br/>
                                Don't worry, we've got your back! While your competitor may be running a tight SEO strategy, our Guaranteed SEO services in India can help you level up your game. Our team analyzes the SEO tactics used by your competitors and creates a customized strategy to outrank them. Whether it's optimizing your website's content, building high-quality backlinks, or improving your website's technical SEO, we'll help you surpass your competitors and dominate the search results.
                                </p>
                            </div>
                            <div className='lgh-box pb-3'>
                                <h4 className='text-black mb-0' >
                                03
                                </h4>
                                <p className='m-0' >
                                <span>Why should I choose Sensibly Weird Company for SEO when there are cheaper options available?</span><br/>
                                At Sensibly Weird Company, we believe that quality always beats quantity. Our competitive pricing and uncompromised quality make us an investment that you can trust. We stay on top of the latest SEO practices to ensure that our customers get the best SEO services in India. We use only ethical and organic SEO practices, and we never purchase backlinks from third-party networks. With us, you'll get a reliable and sustainable SEO strategy that delivers long-term results.
                                </p>
                            </div>
                            <div className='lgh-box pb-3'>
                                <h4 className='text-black mb-0' >
                                04
                                </h4>
                                <p className='m-0' >
                                <span>Can you explain what SEO activities you perform for my website every month?</span><br/>
                                Our SEO services in India revolve around creating high-quality and optimized content that answers the questions and needs of your target audience. We conduct a thorough analysis of your website's keywords and build a customized SEO strategy that suits your business goals. Our team focuses on on-page optimization, building high-quality backlinks, improving website speed and usability, and conducting regular website audits to ensure that your website stays on top of the search results.
                                </p>
                            </div>
                            <div className='lgh-box pb-3'>
                                <h4 className='text-black mb-0' >
                                05
                                </h4>
                                <p className='m-0' >
                                <span>How many backlinks do you create per month, and how do you ensure their quality?</span><br/>
                                We focus on quality over quantity when it comes to building backlinks. Our team identifies relevant and authoritative websites in your niche and creates high-quality backlinks in a natural and organic way. We never purchase backlinks from third-party networks or engage in any black-hat SEO practices that could harm your website's reputation. We prioritize creating backlinks that add value to your website and help you establish authority in your industry.
                                </p>
                            </div>
                            <div className='lgh-box'>
                                <h4 className='text-black mb-0' >
                                06
                                </h4>
                                <p className='m-0' >
                                <span>Do you offer SEO services for E-commerce websites?</span><br/>
                                Yes, we do! E-commerce websites have unique SEO challenges, but our Guaranteed SEO services have worked with various shopping cart platforms such as Magento, Shopify, and Big Commerce. Our team creates a customized SEO strategy that addresses your website's specific needs and ensures that your products and services are easily discoverable by potential customers.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </>
  )
}

export default Seo