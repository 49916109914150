import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';

function WorkPagination(props) {

    const navigate = useNavigate();

    const workList = [
        "/works/branding/aptayu",
        "/works/media/palmtree",
        "/works/communication/ayurcentral",
        "/works/campaign/lazza",
        "/works/campaign/palmtree",
        "/works/campaign/manorama",
        "/works/packaging/aptayu",
        "/works/website/alf",
        "/works/website/ayurcentral",
        "/works/communication/palmtree",
        "/works/videos/hama",
        "/works/videos/lazza" 
    ]

    function getNextWork( id ) {
        const current = parseInt( id );
        let next = current + 1;
        if ( next === workList.length ) {
            next = 0;
        }
        navigate(workList[ next ]);
    }

    function getPrevWork( id ) {
        const current = parseInt( id );
        let prev = current - 1;
        if( prev < 0 ) {
            prev = workList.length - 1;
        }
        navigate(workList[ prev ]);
    }

    return (
        <div className='container pt-5'>
            <div className='row justify-content-center pt-3'>
                <div className='col-xxl-12 col-11'>
                    <div className='row justify-content-between'>
                        <div className='col text-start'>
                            <button onClick={ () => getPrevWork( props.current ) } className='btn wp-btn' >
                                Previous Project
                            </button>
                        </div>
                        <div className='col text-center'>
                            <Link to='/works' className='btn wp-btn' >
                                See all work
                            </Link>
                        </div>
                        <div className='col text-end'>
                            <button onClick={ () => getNextWork( props.current ) } className='btn wp-btn' >
                                Next Project
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WorkPagination