import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import InnerpageHeader from '../../InnerpageHeader';
import Footer from '../../Footer';
import '../../../style/fonts.css';
import '../../../style/works.css';
import { Link } from 'react-router-dom';
import aptayuImage01 from '../../../assets/images/works/packaging/aptayu/packaging-aptayu-image01.png';
import aptayuImage02 from '../../../assets/images/works/packaging/aptayu/packaging-aptayu-image02.png';
import aptayuImage03 from '../../../assets/images/works/packaging/aptayu/packaging-aptayu-image03.png';
import aptayuImage04 from '../../../assets/images/works/packaging/aptayu/packaging-aptayu-image04.png';
import aptayuImage05 from '../../../assets/images/works/packaging/aptayu/packaging-aptayu-image05.png';
import aptayuImage06 from '../../../assets/images/works/packaging/aptayu/packaging-aptayu-image06.png';
import aptayuImage07 from '../../../assets/images/works/packaging/aptayu/packaging-aptayu-image07.png';
import aptayuImage08 from '../../../assets/images/works/packaging/aptayu/packaging-aptayu-image08.png';
import aptayuImage09 from '../../../assets/images/works/packaging/aptayu/packaging-aptayu-image09.png';
import aptayuImage10 from '../../../assets/images/works/packaging/aptayu/packaging-aptayu-image10.png';
import aptayuImage11 from '../../../assets/images/works/packaging/aptayu/packaging-aptayu-image11.png';
import aptayuImage12 from '../../../assets/images/works/packaging/aptayu/packaging-aptayu-image12.png';
import aptayuImage13 from '../../../assets/images/works/packaging/aptayu/packaging-aptayu-image13.png';
import aptayuImage14 from '../../../assets/images/works/packaging/aptayu/packaging-aptayu-image14.png';
import WorkPagination from '../WorkPagination';
import CategoryBadge from '../CategoryBadge';

function Aptayu(props) {

    useEffect(() => {
        document.body.removeAttribute('class');
        document.body.classList.add('inner-page', 'single-work-page');
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <>
            <Helmet>
                <title>
                    {props.metaTitle}
                </title>
                <meta property="og:title" content={props.metaTitle} />
                <meta property="og:description" content={props.metaDescription} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={ window.location } />
                <meta property="og:image" content="" />
            </Helmet>
            <InnerpageHeader/>
            <div className='single-work'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-xxl-12 col-11'>
                            <div className='pageBreadcrumb'>
                                <h6 className='m-0' >
                                    <Link to='/'>Home</Link> / <Link to='/works'>Works</Link> / Packaging / Aptayu
                                </h6>
                            </div>
                            <div className='singleWorkHead my-5'>
                                <h1 className='singleWorkTitle text-uppercase mb-3'>
                                    Aptayu
                                </h1>
                                <p>
                                Aptayu is a renowned Ayurvedic brand offering a wide range of wellness products with a special focus on women and kids. The brand approached us to create a captivating packaging design that would not only reflect the essence of Ayurveda but also resonate with their target audience. We developed two distinct design routes for the packaging: one utilising earthy colours and ingredient-specific hues, and the other adopting a modern style.
                                </p>
                                <CategoryBadge badgeText={props.catBadgeText} badgeCircle={props.catBadgeCircle} badgeType="category" />
                            </div>
                            <div className='singleWorkContent pt-5'>
                                <div className='workMeta pb-4'>
                                    <h4 className='mb-0' >
                                        Design Approach:
                                    </h4>
                                </div>
                                <p className='mb-5' >
                                To begin the design process, our team thoroughly researched the Ayurvedic industry, studying Aptayu's target audience, market trends, and brand values. We aimed to create packaging that would visually communicate the brand's commitment to natural ingredients, sustainability, and overall well-being. With this information, we developed two design routes that could cater to different consumer preferences while maintaining brand consistency.
                                </p>
                                <img src={aptayuImage01} alt='aptayu packaging' className='img-fluid' />
                            </div>
                            <div className='singleWorkContent pt-5 mt-5'>
                                <div className='workMeta pb-4'>
                                    <h5 className='mb-0' >
                                    Route-1
                                    </h5>
                                    <h4 className='mb-0' >
                                    Earthy Colours and Ingredient-Specific Hues:
                                    </h4>
                                </div>
                                <p className='pb-4 m-0' >
                                In this design route, we drew inspiration from nature and Ayurvedic principles. Earthy tones, such as warm browns, muted greens, and soft yellows, formed the foundation of the colour palette. These colours evoked a sense of grounding, naturalness, and authenticity. Additionally, we incorporated ingredient-specific colours to showcase the herbal and botanical elements of Aptayu's products. For example, turmeric-inspired yellow for turmeric-based products, neem-infused green for neem-based products, etc.
                                </p>
                                <div className='row g-0'>
                                    <div className='col-md-6'>
                                        <img src={aptayuImage02} alt='aptayu packaging' className='img-fluid' />
                                    </div>
                                    <div className='col-md-6'>
                                        <img src={aptayuImage03} alt='aptayu packaging' className='img-fluid' />
                                    </div>
                                    <div className='col-md-6'>
                                        <img src={aptayuImage04} alt='aptayu packaging' className='img-fluid' />
                                    </div>
                                    <div className='col-md-6'>
                                        <img src={aptayuImage05} alt='aptayu packaging' className='img-fluid' />
                                    </div>
                                </div>
                            </div>
                            <div className='singleWorkContent pt-5 mt-5'>
                                <div className='workMeta pb-4'>
                                    <h5 className='mb-0' >
                                    Route-2
                                    </h5>
                                    <h4 className='mb-0' >
                                    Modern Style:
                                    </h4>
                                </div>
                                <p className='pb-4 m-0' >
                                The second design route aimed to appeal to a more contemporary and design-conscious audience. We opted for a sleek and minimalistic approach, utilising clean lines, bold typography, and a refined colour palette.
                                </p>
                                <div className='row g-0'>
                                    <div className='col-md-6'>
                                        <img src={aptayuImage06} alt='aptayu packaging' className='img-fluid' />
                                    </div>
                                    <div className='col-md-6'>
                                        <img src={aptayuImage07} alt='aptayu packaging' className='img-fluid' />
                                    </div>
                                    <div className='col-md-6'>
                                        <img src={aptayuImage08} alt='aptayu packaging' className='img-fluid' />
                                    </div>
                                    <div className='col-md-6'>
                                        <img src={aptayuImage09} alt='aptayu packaging' className='img-fluid' />
                                    </div>
                                </div>
                            </div>
                            <div className='singleWorkContent pt-5 mt-5'>
                                <h5 className='text-uppercase text-center' >
                                <strong>Prototypes</strong>
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='ptototypeWrap pt-4'>
                    <div className='container text-center'>
                        <img src={aptayuImage10} className='img-fluid' alt='prototype 01' />
                    </div>
                </div>
                <div className='ptototypeWrap'>
                    <div className='container text-center'>
                        <img src={aptayuImage11} className='img-fluid' alt='prototype 02' />
                    </div>
                </div>
                <div className='ptototypeWrap'>
                    <div className='container text-center'>
                        <img src={aptayuImage12} className='img-fluid' alt='prototype 03' />
                    </div>
                </div>
                <div className='ptototypeWrap'>
                    <div className='container text-center'>
                        <img src={aptayuImage13} className='img-fluid' alt='prototype 04' />
                    </div>
                </div>
                <div className='ptototypeWrap'>
                    <div className='container text-center'>
                        <img src={aptayuImage14} className='img-fluid' alt='prototype 05' />
                    </div>
                </div>
                <WorkPagination current={props.workID} />
            </div>
            <Footer />
        </>
    )
}

export default Aptayu