import React, { useEffect, useRef, useState } from 'react';
import InnerpageHeader from './InnerpageHeader';
import '../style/fonts.css';
import '../style/careers.css';
import Footer from './Footer';
import { Helmet } from 'react-helmet';
import image01 from '../assets/images/careers/careers-atr-01.jpg';
import image02 from '../assets/images/careers/careers-atr-02.jpg';
import image03 from '../assets/images/careers/careers-atr-03.jpg';
import image04 from '../assets/images/careers/careers-atr-04.jpg';
import image05 from '../assets/images/careers/careers-atr-05.jpg';
import image06 from '../assets/images/careers/careers-atr-06.jpg';
import image07 from '../assets/images/careers/careers-atr-07.jpg';
import image08 from '../assets/images/careers/careers-atr-08.jpg';
import { useParams } from 'react-router-dom';
import axios from 'axios';

export const CareersApplyNow = ( props ) => {

    let jobOpening = useParams();
    jobOpening = jobOpening.id.replace(/-/g, ' ');
    jobOpening = jobOpening.charAt(0).toUpperCase() + jobOpening.substr(1).toLowerCase();
    const formResponse = useRef();
    const formInput = useRef();
    const [ buttomClick, setButtomClick ] = useState(false);
    const [ inputClick, setInputClick ] = useState(false);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.body.classList.add('inner-page', 'apply-now-page');
    }, []);


    const submitHandler = ( e ) => {
        e.preventDefault();
            formResponse.current.textContent = 'Please wait ...';
            let fd = new FormData( document.getElementById('carrersForm') );
            fd.append( 'job', jobOpening )
           axios.post( 'https://sensiblyweird.com/phpmailer/careers_mail.php', fd ).then( response => {
                console.log( response.data.data )
                if( response.data.status === 'success' ) {
                    formResponse.current.innerHTML = 'Thank you!<br/>One of our colleagues will get back in touch with you soon.';
                } else {
                    formResponse.current.innerHTML = 'Whoops, something went wrong!<br/>Please try again later.';
                }
            } )
    }

    return (
        <>
           <Helmet>
                <title>
                    {props.metaTitle}
                </title>
                <meta property="og:title" content={props.metaTitle} />
                <meta property="og:description" content={props.metaDescription} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={ window.location } />
                <meta property="og:image" content="" />
            </Helmet>
            <InnerpageHeader/>
            <div className='apllyNowFormWrap'>
                <div className='container-xl'>
                    <div className='row justify-content-center'>
                        <div className='col-xxl-10 col-11'>
                            <form onSubmit={submitHandler} id='carrersForm' className='careersForm' encType="multipart/form-data" >
                                <div className='row gy-4 gx-1 justify-content-between align-items-end pb-5'>
                                    <div className='col-lg-4 col-md-4 col-sm-4'>
                                        <img src={image01} alt='form-field-01' className='img-fluid form-image' />
                                    </div>
                                    <div className='col-lg-7 col-md-7 pb-2'>
                                        <h6 className='form-text' >
                                            <img src={image05} alt='que-01-decor' className='form-text-decor' />
                                            If you could have a magical tool to assist you in your dream job, what would it be and how would it amplify your abilities? (Start with your dream job…)
                                        </h6>
                                        <textarea name='careerFormField01' id='careerFormField01' required className='form-field' placeholder='Type your answer...'  ></textarea>
                                    </div>
                                </div>
                                <div className='row gy-4 gx-1 justify-content-between align-items-end py-5'>
                                    <div className='col-lg-4 col-md-4 col-sm-4 order-md-last text-md-end'>
                                        <img src={image02} alt='form-field-02' className='img-fluid form-image' />
                                    </div>
                                    <div className='col-lg-7 col-md-7 pb-2'>
                                        <h6 className='form-text' >
                                            <img src={image06} alt='que-06-decor' className='form-text-decor' />
                                            In your opinion, what is the most important aspect of a successful team, and how would you contribute to fostering that aspect in your role?
                                        </h6>
                                        <textarea name='careerFormField02' id='careerFormField02' required className='form-field' placeholder='Type your answer...'  ></textarea>
                                    </div>
                                </div>
                                <div className='row gy-4 gx-1 justify-content-between align-items-end py-5'>
                                    <div className='col-lg-4 col-md-4 col-sm-4'>
                                        <img src={image03} alt='form-field-03' className='img-fluid form-image' />
                                    </div>
                                    <div className='col-lg-7 col-md-7 pb-2'>
                                        <h6 className='form-text' >
                                            <img src={image07} alt='que-07-decor' className='form-text-decor' />
                                            Describe a situation where you faced a significant challenge or obstacle while working on a project. How did you approach the problem and what steps did you take to overcome it?
                                        </h6>
                                        <textarea name='careerFormField03' id='careerFormField03' required className='form-field' placeholder='Type your answer...'  ></textarea>
                                    </div>
                                </div>
                                <div className='row gy-4 gx-1 justify-content-between align-items-end py-5'>
                                    <div className='col-lg-4 col-md-4 col-sm-4 order-md-last text-md-end'>
                                        <img src={image04} alt='form-field-04' className='img-fluid form-image' />
                                    </div>
                                    <div className='col-lg-7 col-md-7 pb-2'>
                                        <h6 className='form-text' >
                                            <img src={image08} alt='que-08-decor' className='form-text-decor' />
                                            If you were to choose an avatar or nickname that represents your personality or interests for your official email address at sensiblyweird.com, what would it be and why? Feel free to get creative!
                                        </h6>
                                        <textarea name='careerFormField04' id='careerFormField04' required className='form-field' placeholder='Type your answer...'  ></textarea>
                                    </div>
                                </div>
                                <div className='row py-5 g-3 form-submit-wrap align-items-center'>
                                    <div className='col-12'>
                                        <p className='m-0' >
                                            Time to make your move and show us what you've got! To embark on this wonderfully weird adventure, simply attach your CV and portfolio below. 
                                        </p>
                                    </div>
                                    <div className='col-auto'>
                                        <div className={ inputClick ? 'form-input-wrap clicked' : 'form-input-wrap' } >
                                            <input type='file' accept='.pdf, .doc' required className='form-input' ref={formInput} name='careerFormField05' id='careerFormField05'  onMouseDown={() => setInputClick( !inputClick )} onMouseUp={() => setInputClick( !inputClick )} />
                                        </div>
                                    </div>
                                    <div className='col-auto'>
                                        <div className={ buttomClick ? 'formSubmitWrap clicked' : 'formSubmitWrap' } >
                                            <input type='submit' className='formSubmit' value='submit Now' name='careerFormField06' id='careerFormField06' onMouseDown={() => setButtomClick( !buttomClick )} onMouseUp={() => setButtomClick( !buttomClick )} />
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <p className='m-0'  id='form-response' ref={formResponse} ></p>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}
