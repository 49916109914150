import React, { useEffect } from 'react';
import InnerpageHeader from './InnerpageHeader';
import '../style/fonts.css';
import '../style/works.css';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import brandingAptayu from '../assets/images/works/thumbnails/branding-aptayu.jpg';
import brandingPalmtree from '../assets/images/works/thumbnails/branding-palmtree.jpg';
import communicationAyurcentral from '../assets/images/works/thumbnails/communication-ayurcentral.jpg';
import communicationPalmtree from '../assets/images/works/thumbnails/communication-palmtree.jpg';
import communicationGrandHayatt from '../assets/images/works/thumbnails/communication-grandhayatt.jpg';
import campaignLazza from '../assets/images/works/thumbnails/campaign-lazza.jpg';
import campaignManorama from '../assets/images/works/thumbnails/campaign-manorama.jpg';
import campaignPalmtree from '../assets/images/works/thumbnails/campaign-palmtree.jpg';
import campaignPalmtreeOnam from '../assets/images/works/thumbnails/campaign-palmtree-onam.jpg';
import packagingAptayu from '../assets/images/works/thumbnails/packaging-aptayu.jpg';
import videosHama from '../assets/images/works/thumbnails/videos-hama.jpg';
import videosLazza from '../assets/images/works/thumbnails/videos-lazza.jpg';
import websiteAlf from '../assets/images/works/thumbnails/website-alf.jpg';
import websiteAyurcentral from '../assets/images/works/thumbnails/website-ayurcentral.jpg';

function Works(props) {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    document.body.classList.add('inner-page', 'works-page');
  }, []);

  function filterHandler( target ) {
    const items = Array.from( document.querySelectorAll('[data-filter] ') );
    const targetItems = items.filter( item => item.getAttribute('data-filter') === target );
    const restItems = items.filter( item => item.getAttribute('data-filter') !== target );
    items.forEach( item => {
      const listItem = item.getElementsByClassName('worksListItem')[0];
      listItem.classList.add('hide');
    } );
    function showItems() {
      targetItems.forEach( item => {
        item.style.display = 'block';
        const listItem = item.getElementsByClassName('worksListItem')[0];
        setTimeout( function() {
          listItem.classList.remove('hide');
        }, 200 );
      } );
    }
    setTimeout( function() {
      restItems.forEach( item => {
        item.style.display = 'none';
      } );
      showItems();
    }, 300 );
  }

  return (
    <>
      <Helmet>
          <title>
              {props.metaTitle}
          </title>
          <meta property="og:title" content={props.metaTitle} />
          <meta property="og:description" content={props.metaDescription} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={ window.location } />
          <meta property="og:image" content="" />
      </Helmet>
      <InnerpageHeader/>
      <div className='workspageHeader'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-xxl-11 col-xl-10 col-11'>
              <h1 className='moonDreamText' >
                WORKS
              </h1>
              <div className='workFilter'>
                <h5 className='workFilterTab m-0 py-1' onClick={() => filterHandler('branding')} >
                  Branding
                </h5>
                <h5 className='workFilterTab m-0 py-1' onClick={() => filterHandler('campaign')} >
                  Campaign
                </h5>
                <h5 className='workFilterTab m-0 py-1' onClick={() => filterHandler('communication')} >
                  Communication
                </h5>
                <h5 className='workFilterTab m-0 py-1' onClick={() => filterHandler('media')} >
                  Media
                </h5>
                <h5 className='workFilterTab m-0 py-1' onClick={() => filterHandler('packaging')} >
                  Packaging
                </h5>
                <h5 className='workFilterTab m-0 py-1' onClick={() => filterHandler('video')} >
                  Videos
                </h5>
                <h5 className='workFilterTab m-0 py-1' onClick={() => filterHandler('website')} >
                  Website
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='worksPage' >
        <div className='worksList pb-5 pt-0'>
          <div className='container py-4'>
            <div className='row justify-content-center'>
              <div className='col-xxl-11 col-xl-10'>
                <div className='row g-5 justify-content-md-start justify-content-center'>
                  <div className='col-md-6 col-11' data-filter="communication" >
                    <Link to='/works/communication/grandhayatt'>
                      <div className='worksListItem'> 
                        <div>
                        <img src={communicationGrandHayatt} alt='Grand Hyatt Kochi thumbnail' className='w-100' />
                        </div>
                        <h4 className='mb-1 mt-3' >         
                        Grand Hyatt Kochi
                        </h4>
                        <h6 className='mb-0' >
                        Communications
                        </h6>
                      </div>
                    </Link>
                  </div>
                  <div className='col-md-6 col-11' data-filter="branding" >
                    <Link to='/works/branding/aptayu'>
                      <div className='worksListItem'> 
                        <div>
                        <img src={brandingAptayu} alt='aptayu branding thumbnail' className='w-100' />
                        </div>
                        <h4 className='mb-1 mt-3' >         
                          Aptayu
                        </h4>
                        <h6 className='mb-0' >
                          Branding
                        </h6>
                      </div>
                    </Link>
                  </div>
                  <div className='col-md-6 col-11' data-filter="media" >
                    <Link to='/works/media/palmtree'>
                      <div className='worksListItem'> 
                        <div>
                        <img src={brandingPalmtree} alt='palmtree Media thumbnail' className='w-100' />
                        </div>
                        <h4 className='mb-1 mt-3' >                 
                          Palm Tree
                        </h4>
                        <h6 className='mb-0' >
                          Media
                        </h6>
                      </div>
                    </Link>
                  </div>
                  <div className='col-md-6 col-11' data-filter="communication" >
                    <Link to='/works/communication/ayurcentral'>
                      <div className='worksListItem'> 
                        <div>
                        <img src={communicationAyurcentral} alt='ayurcentral communications thumbnail' className='w-100' />
                        </div>
                        <h4 className='mb-1 mt-3' >                 
                          AyurCentral
                        </h4>
                        <h6 className='mb-0' >
                          Communication
                        </h6>
                      </div>
                    </Link>
                  </div>
                  <div className='col-md-6 col-11' data-filter="campaign" >
                    <Link to='/works/campaign/lazza'>
                      <div className='worksListItem'> 
                        <div>
                        <img src={campaignLazza} alt='lazza camapaign thumbnail' className='w-100' />
                        </div>
                        <h4 className='mb-1 mt-3' >                 
                          Lazza
                        </h4>
                        <h6 className='mb-0' >
                          Campaign
                        </h6>
                      </div>
                    </Link>
                  </div>
                  <div className='col-md-6 col-11' data-filter="campaign" >
                    <Link to='/works/campaign/manorama'>
                      <div className='worksListItem'> 
                        <div>
                        <img src={campaignManorama} alt='manorama camapaign thumbnail' className='w-100' />
                        </div>
                        <h4 className='mb-1 mt-3' >                 
                          Manorama
                        </h4>
                        <h6 className='mb-0' >
                          Campaign
                        </h6>
                      </div>
                    </Link>
                  </div>
                  <div className='col-md-6 col-11' data-filter="campaign" >
                    <Link to='/works/campaign/palmtree/lessgo'>
                      <div className='worksListItem'> 
                        <div>
                        <img src={campaignPalmtree} alt='palmtree campaign thumbnail' className='w-100' />
                        </div>
                        <h4 className='mb-1 mt-3' >                 
                          Palm Tree
                        </h4>
                        <h6 className='mb-0' >
                          Campaign
                        </h6>
                      </div>
                    </Link>
                  </div>
                  <div className='col-md-6 col-11' data-filter="campaign" >
                    <Link to='/works/campaign/palmtree/onam'>
                      <div className='worksListItem'> 
                        <div>
                        <img src={campaignPalmtreeOnam} alt='palmtree campaign thumbnail' className='w-100' />
                        </div>
                        <h4 className='mb-1 mt-3' >                 
                          Palm Tree
                        </h4>
                        <h6 className='mb-0' >
                          Campaign
                        </h6>
                      </div>
                    </Link>
                  </div>
                  <div className='col-md-6 col-11' data-filter="packaging" >
                    <Link to='/works/packaging/aptayu'>
                      <div className='worksListItem'> 
                        <div>
                        <img src={packagingAptayu} alt='aptayu packaging thumbnail' className='w-100' />
                        </div>
                        <h4 className='mb-1 mt-3' >                 
                          Aptayu
                        </h4>
                        <h6 className='mb-0' >
                          Packaging
                        </h6>
                      </div>
                    </Link>
                  </div>
                  <div className='col-md-6 col-11'  data-filter="website"  >
                    <Link to='/works/website/alf'>
                      <div className='worksListItem'> 
                        <div>
                        <img src={websiteAlf} alt='alf website thumbnail' className='w-100' />
                        </div>
                        <h4 className='mb-1 mt-3' >                 
                          ALF
                        </h4>
                        <h6 className='mb-0' >
                          Website
                        </h6>
                      </div>
                    </Link>
                  </div>
                  <div className='col-md-6 col-11'  data-filter="website"  >
                    <Link to='/works/website/ayurcentral'>
                      <div className='worksListItem'> 
                        <div>
                        <img src={websiteAyurcentral} alt='ayurcentral website thumbnail' className='w-100' />
                        </div>
                        <h4 className='mb-1 mt-3' >                 
                          AyurCentral
                        </h4>
                        <h6 className='mb-0' >
                          Website
                        </h6>
                      </div>
                    </Link>
                  </div>
                  <div className='col-md-6 col-11'  data-filter="communication"  >
                    <Link to='/works/communication/palmtree'>
                      <div className='worksListItem'> 
                        <div>
                        <img src={communicationPalmtree} alt='Palm Tree Communication thumbnail' className='w-100' />
                        </div>
                        <h4 className='mb-1 mt-3' >                 
                          Palm Tree
                        </h4>
                        <h6 className='mb-0' >
                          Communication
                        </h6>
                      </div>
                    </Link>
                  </div>
                  <div className='col-md-6 col-11'  data-filter="video"  >
                    <Link to='/works/videos/hama'>
                      <div className='worksListItem'> 
                        <div >
                        <img src={videosHama} alt='hama videos thumbnail' className='w-100' />
                        </div>
                        <h4 className='mb-1 mt-3' >                 
                          Hama
                        </h4>
                        <h6 className='mb-0' >
                          Videos
                        </h6>
                      </div>
                    </Link>
                  </div>
                  <div className='col-md-6 col-11'  data-filter="video"  >
                    <Link to='/works/videos/lazza'>
                      <div className='worksListItem'> 
                        <div>
                        <img src={videosLazza} alt='lazza videos thumbnail' className='w-100' />
                        </div>
                        <h4 className='mb-1 mt-3' >                 
                          Lazza
                        </h4>
                        <h6 className='mb-0' >
                          Videos
                        </h6>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  )
}

export default Works