import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import InnerpageHeader from '../../InnerpageHeader';
import Footer from '../../Footer';
import '../../../style/fonts.css';
import '../../../style/works.css';
import { Link, useParams } from 'react-router-dom';
import palmtreeImage01 from '../../../assets/images/works/campaign/palmtree/palmtreeLessgo01.png';
import palmtreeImage02 from '../../../assets/images/works/campaign/palmtree/palmtreeLessgo02.png';
import palmtreeImage03 from '../../../assets/images/works/campaign/palmtree/palmtreeLessgo03.png';
import palmtreeImage04 from '../../../assets/images/works/campaign/palmtree/palmtreeLessgo04.png';
import palmtreeImage05 from '../../../assets/images/works/campaign/palmtree/palmtreeLessgo05.png';
import palmtreeOnam01 from '../../../assets/images/works/campaign/palmtree/palmtreeOnam01.png';
import palmtreeOnam03 from '../../../assets/images/works/campaign/palmtree/palmtreeOnam03.png';
import palmtreeOnam04 from '../../../assets/images/works/campaign/palmtree/palmtreeOnam04.png';
import palmtreeOnam05 from '../../../assets/images/works/campaign/palmtree/palmtreeOnam05.png';
import palmtreeOnam06 from '../../../assets/images/works/campaign/palmtree/palmtreeOnam06.png';
import palmtreeOnam07 from '../../../assets/images/works/campaign/palmtree/palmtreeOnam07.png';
import palmtreeOnam08 from '../../../assets/images/works/campaign/palmtree/palmtreeOnam08.png';
import palmtreeOnam10 from '../../../assets/images/works/campaign/palmtree/palmtreeOnam10.png';
import palmtreeOnam11 from '../../../assets/images/works/campaign/palmtree/palmtreeOnam11.png';
import palmtreeOnam12 from '../../../assets/images/works/campaign/palmtree/palmtreeOnam12.png';
import palmtreeOnam13 from '../../../assets/images/works/campaign/palmtree/palmtreeOnam13.png';
import palmtreeOnam14 from '../../../assets/images/works/campaign/palmtree/palmtreeOnam14.png';
import palmtreeOnam15 from '../../../assets/images/works/campaign/palmtree/palmtreeOnam15.png';
import palmtreeOnam16 from '../../../assets/images/works/campaign/palmtree/palmtreeOnam16.png';
import palmtreeOnam17 from '../../../assets/images/works/campaign/palmtree/palmtreeOnam17.png';
import palmtreeOnam18 from '../../../assets/images/works/campaign/palmtree/palmtreeOnam18.png';
import palmtreeOnam19 from '../../../assets/images/works/campaign/palmtree/palmtreeOnam19.png';
import palmtreeOnam20 from '../../../assets/images/works/campaign/palmtree/palmtreeOnam20.png';
import palmtreeOnam21 from '../../../assets/images/works/campaign/palmtree/palmtreeOnam21.png';
import palmtreeOnam22 from '../../../assets/images/works/campaign/palmtree/palmtreeOnam22.png';
import palmtreeOnam23 from '../../../assets/images/works/campaign/palmtree/palmtreeOnam23.png';
import palmtreeOnam24 from '../../../assets/images/works/campaign/palmtree/palmtreeOnam24.png';
import palmtreeOnam25 from '../../../assets/images/works/campaign/palmtree/palmtreeOnam25.png';
import palmtreeOnam26 from '../../../assets/images/works/campaign/palmtree/palmtreeOnam26.png';
import palmtreeOnam27 from '../../../assets/images/works/campaign/palmtree/palmtreeOnam27.png';
import palmtreeOnam28 from '../../../assets/images/works/campaign/palmtree/palmtreeOnam28.png';
import palmtreeOnam29 from '../../../assets/images/works/campaign/palmtree/palmtreeOnam29.png';
import palmtreeOnam30 from '../../../assets/images/works/campaign/palmtree/palmtreeOnam30.png';
import palmtreeOnam31 from '../../../assets/images/works/campaign/palmtree/palmtreeOnam31.png';
import palmtreeOnam32 from '../../../assets/images/works/campaign/palmtree/palmtreeOnam32.png';
import palmtreeOnam33 from '../../../assets/images/works/campaign/palmtree/palmtreeOnam33.png';
import palmtreeOnam02 from '../../../assets/images/works/campaign/palmtree/palmtreeOnam02.png';
import palmtreeOnam09 from '../../../assets/images/works/campaign/palmtree/palmtreeOnam09.png';
import WorkPagination from '../WorkPagination';
import CategoryBadge from '../CategoryBadge';

function Palmtree(props) {

    const sectionID = useParams();

    const lessgo = useRef(null);
    const onam = useRef(null);

    const [ accordion1, setAccordion1 ] = useState( [ false, false, false ] );
    const [ accordion2, setAccordion2 ] = useState( [ false, false, false ] );

    useEffect(() => {
        document.body.removeAttribute('class');
        document.body.classList.add('inner-page', 'single-work-page');
        window.scrollTo({ top: 0, behavior: 'smooth' });
        console.log( sectionID )
        if( sectionID.id === 'onam' ) {
            onam.current.scrollIntoView();
        } else {
            lessgo.current.scrollIntoView();
        }
    }, [sectionID]);

    return (
        <>
            <Helmet>
                <title>
                    {props.metaTitle}
                </title>
                <meta property="og:title" content={props.metaTitle} />
                <meta property="og:description" content={props.metaDescription} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={ window.location } />
                <meta property="og:image" content="" />
            </Helmet>
            <InnerpageHeader/>
            <div ref={lessgo} className='single-work'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-xxl-12 col-11'>
                            <div className='pageBreadcrumb'>
                                <h6 className='m-0' >
                                    <Link to='/'>Home</Link> / <Link to='/works'>Works</Link> / Campaign / Palm Tree 
                                </h6>
                            </div>
                            <div className='singleWorkHead my-5'>
                                <h1 className='singleWorkTitle text-uppercase mb-3'>
                                World cup <br className='d-lg-inline d-none' />
                                campaign
                                </h1>
                                <p>
                                Through this UGC giveaway campaign titled Lessgo, we were able to  effectively propell Palmtree's digital growth, expanding their reach, and establishing a strong foundation of potential customers. Our meticulous approach to data collection, rapid follower growth, impactful influencer collaborations, and targeted advertising contributed to the campaign's resounding success.
                                </p>
                                <CategoryBadge badgeText={props.catBadgeText} badgeCircle={props.catBadgeCircle} badgeType="category" />
                            </div>
                            <div className='workMeta row g-5 pb-5'>
                                <div className='col-lg-3 col-auto'>
                                    <h5 className='mb-0' >
                                    Client
                                    </h5>
                                    <h4 className='mb-0' >
                                    Palm Tree
                                    </h4>
                                </div>
                                <div className='col-lg-3 col-auto'>
                                    <h5 className='mb-0' >
                                    Market
                                    </h5>
                                    <h4 className='mb-0' >
                                    Kerala
                                    </h4>
                                </div>
                            </div>
                            <div className='singleWorkContent pb-md-0 pb-5'>
                                <div className='row g-md-2 g-1'>
                                    <div className='col-12'>
                                        <img src={palmtreeImage01} alt='palm tree campaign image' className='w-100' />
                                    </div>
                                    <div className='col-md-6'>
                                        <img src={palmtreeImage02} alt='palm tree campaign image' className='w-100' />
                                    </div>
                                    <div className='col-md-6'>
                                        <img src={palmtreeImage03} alt='palm tree campaign image' className='w-100' />
                                    </div>
                                    <div className='col-md-6'>
                                        <img src={palmtreeImage04} alt='palm tree campaign image' className='w-100' />
                                    </div>
                                    <div className='col-md-6'>
                                        <img src={palmtreeImage05} alt='palm tree campaign image' className='w-100' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='workApproach'>
                    <div className='workApproachBG'>
                        <div className='container'>
                            <div className='row justify-content-center'>
                                <div className='col-xxl-12 col-11'>
                                    <div className='row justify-content-lg-end'>
                                    <CategoryBadge badgeText={props.resultText} badgeCircle={props.resultCircle} badgeType="result" />
                                        <div className='col-auto'>
                                            <h2 className='text-white text-uppercase my-5 py-lg-4' >
                                                Our <br />Approach
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='workApproachAccordion'>
                        <div className='row g-0 justify-content-lg-start justify-content-center'>
                            <div className='col-lg-auto col-md-9 col-11'>
                                <div className={ accordion1[0] ? 'wAAContent wAAOpen' : 'wAAContent' } >
                                    <div className='wAAContentTrigger' onClick={ () => { setAccordion1( [ !accordion1[0], false, false ] ) } } ></div>
                                    <div className='wAAContentHead ms-lg-0 ms-md-5 ms-4 pb-lg-0 pb-3'>
                                        <h4>
                                            01.
                                        </h4>
                                        <h3>
                                            Activation
                                        </h3>
                                    </div>
                                    <div className='wAAContentBody'>
                                        <p className='m-0 px-md-5 p-4 py-5 pt-lg-5 pt-3' >
                                            The activation phase consisted of targeted paid ads to reach custom affinity audiences interested in football and health across each district of Kerala. The campaign utilised real-time matches and interactive Instagram stories, including polls and match predictions, to engage with the audience and create a sense of community. Additionally, contest announcements were strategically scheduled in collaboration with influencers, creating a sense of FOMO (fear of missing out) and driving increased participation.The results of the campaign were exceptional. Palmtree's Instagram reach exceeded a million hits, with 99.1% of the traffic coming through paid media. The campaign resulted in a significant increase in followers, growing from 2,500 to an impressive 11,000 within just 14 days. Moreover, over 8,000 phone numbers and email addresses were collected from potential customers, providing valuable data for future marketing initiatives.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-auto col-md-9 col-11'>
                                <div className={ accordion1[1] ? 'wAAContent wAAOpen' : 'wAAContent' } >
                                    <div className='wAAContentTrigger' onClick={ () => { setAccordion1( [ false, !accordion1[1], false ] ) } } ></div>
                                    <div className='wAAContentHead ms-lg-0 ms-md-5 ms-4 pb-lg-0 pb-3'>
                                        <h4>
                                            02.
                                        </h4>   
                                        <h3>
                                        Insight and Strategy
                                        </h3>
                                    </div>
                                    <div className='wAAContentBody'>
                                        <p className='m-0 px-md-5 p-4 py-5 pt-lg-5 pt-3' >
                                        The insight was to leverage the power of user-generated content (UGC) and influencer collaborations to engage the target audience and build credibility. The strategy involved implementing a UGC giveaway campaign to collect valuable customer data while creating excitement around Palmtree's brand and products. Additionally, collaborating with one macro influencer and five micro-influencers would help amplify the campaign's reach and generate widespread publicity.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-auto col-md-9 col-11'>
                                <div className={ accordion1[2] ? 'wAAContent wAAOpen' : 'wAAContent' } >
                                    <div className='wAAContentTrigger' onClick={ () => { setAccordion1( [ false, false, !accordion1[2] ] ) } } ></div>
                                    <div className='wAAContentHead ms-lg-0 ms-md-5 ms-4 pb-lg-0 pb-3'>
                                        <h4>
                                            03.
                                        </h4>
                                        <h3>
                                        Challenges
                                        </h3>
                                    </div>
                                    <div className='wAAContentBody'>
                                        <p className='m-0 px-md-5 p-4 py-5 pt-lg-5 pt-3' >
                                        Palmtree, a super specialty store aligned with healthy living, faced the challenge of establishing itself as a vibrant and young brand and increasing its social media presence, particularly on Instagram. With a brand new website and no existing customer data, the primary challenge was to create a buzz, build a community of potential customers,
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='workResults py-5'>
                    <div className='container py-4'>
                        <div className='row justify-content-center'>
                            <div className='col-xxl-12 col-11'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <h2 className='text-white' >
                                            Result
                                        </h2>
                                    </div>
                                    <div className='col-lg-6'>
                                        <p className='m-0' >
                                        The results of the campaign were remarkable. Palmtree's Instagram reach exceeded a million hits, with 99.1 percent of traffic coming through paid media. However, a combination of paid media and influencer marketing achieved a high engagement rate. The campaign resulted in 10,000 new Instagram followers in 7 days, and they were able to collect over 8,000 phone numbers and email addresses from potential customers.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div ref={onam} className='single-work pt-5 pb-5'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-xxl-12 col-11'>
                            <div className='singleWorkHead mb-5'>
                                <h1 className='singleWorkTitle text-uppercase mb-3'>
                                The Onam <br className='d-lg-inline d-none' />Campaign
                                </h1>
                                <p>
                                No matter how old we are, a good old "Muthashi Kadha" still has the charm to put a smile on our faces. One such "muthashi kadha" was Palm Tree’s Onam campaign in 2021 . A marriage of great episodic storytelling and imaginative illustrations that resulted in a sweet, hilarious tale of rivalry between two islands (Oru Pazham Kadha) - Nutoor and Sughandhapuri. <br />
                                <strong>Fun Fact:</strong> the names are a wordplay on Nuts & Spices (Sugandha Dravyangal)  - Palm Tree's key product offerings.
                                </p>
                                <CategoryBadge badgeText={props.catBadgeText} badgeCircle={props.catBadgeCircle} badgeType="category" />
                            </div>
                            <div className='workMeta row g-5 pb-5'>
                                <div className='col-lg-3 col-auto'>
                                    <h5 className='mb-0' >
                                    Client
                                    </h5>
                                    <h4 className='mb-0' >
                                    Palm Tree
                                    </h4>
                                </div>
                                <div className='col-lg-3 col-auto'>
                                    <h5 className='mb-0' >
                                    Market
                                    </h5>
                                    <h4 className='mb-0' >
                                    Kerala
                                    </h4>
                                </div>
                            </div>
                            <div className='singleWorkContent pb-md-0 pb-5'>
                                <div className='row g-xxl-2 g-1'>
                                    <div className='col-xl-3 col-md-4 col-6'>
                                        <img src={palmtreeOnam01} alt='palm tree campaign image' className='w-100' />
                                    </div>
                                    <div className='col-xl-3 col-md-4 col-6'>
                                        <img src={palmtreeOnam02} alt='palm tree campaign image' className='w-100' />
                                    </div>
                                    <div className='col-xl-3 col-md-4 col-6'>
                                        <img src={palmtreeOnam03} alt='palm tree campaign image' className='w-100' />
                                    </div>
                                    <div className='col-xl-3 col-md-4 col-6'>
                                        <img src={palmtreeOnam04} alt='palm tree campaign image' className='w-100' />
                                    </div>
                                    <div className='col-xl-3 col-md-4 col-6'>
                                        <img src={palmtreeOnam05} alt='palm tree campaign image' className='w-100' />
                                    </div>
                                    <div className='col-xl-3 col-md-4 col-6'>
                                        <img src={palmtreeOnam06} alt='palm tree campaign image' className='w-100' />
                                    </div>
                                    <div className='col-xl-3 col-md-4 col-6'>
                                        <img src={palmtreeOnam07} alt='palm tree campaign image' className='w-100' />
                                    </div>
                                    <div className='col-xl-3 col-md-4 col-6'>
                                        <img src={palmtreeOnam08} alt='palm tree campaign image' className='w-100' />
                                    </div>
                                    <div className='col-xl-3 col-md-4 col-6'>
                                        <img src={palmtreeOnam09} alt='palm tree campaign image' className='w-100' />
                                    </div>
                                    <div className='col-xl-3 col-md-4 col-6'>
                                        <img src={palmtreeOnam10} alt='palm tree campaign image' className='w-100' />
                                    </div>
                                    <div className='col-xl-3 col-md-4 col-6'>
                                        <img src={palmtreeOnam11} alt='palm tree campaign image' className='w-100' />
                                    </div>
                                    <div className='col-xl-3 col-md-4 col-6'>
                                        <img src={palmtreeOnam12} alt='palm tree campaign image' className='w-100' />
                                    </div>
                                    <div className='col-xl-3 col-md-4 col-6'>
                                        <img src={palmtreeOnam13} alt='palm tree campaign image' className='w-100' />
                                    </div>
                                    <div className='col-xl-3 col-md-4 col-6'>
                                        <img src={palmtreeOnam14} alt='palm tree campaign image' className='w-100' />
                                    </div>
                                    <div className='col-xl-3 col-md-4 col-6'>
                                        <img src={palmtreeOnam15} alt='palm tree campaign image' className='w-100' />
                                    </div>
                                    <div className='col-xl-3 col-md-4 col-6'>
                                        <img src={palmtreeOnam16} alt='palm tree campaign image' className='w-100' />
                                    </div>
                                    <div className='col-xl-3 col-md-4 col-6'>
                                        <img src={palmtreeOnam17} alt='palm tree campaign image' className='w-100' />
                                    </div>
                                    <div className='col-xl-3 col-md-4 col-6'>
                                        <img src={palmtreeOnam18} alt='palm tree campaign image' className='w-100' />
                                    </div>
                                    <div className='col-xl-3 col-md-4 col-6'>
                                        <img src={palmtreeOnam19} alt='palm tree campaign image' className='w-100' />
                                    </div>
                                    <div className='col-xl-3 col-md-4 col-6'>
                                        <img src={palmtreeOnam20} alt='palm tree campaign image' className='w-100' />
                                    </div>
                                    <div className='col-xl-3 col-md-4 col-6'>
                                        <img src={palmtreeOnam21} alt='palm tree campaign image' className='w-100' />
                                    </div>
                                    <div className='col-xl-3 col-md-4 col-6'>
                                        <img src={palmtreeOnam22} alt='palm tree campaign image' className='w-100' />
                                    </div>
                                    <div className='col-xl-3 col-md-4 col-6'>
                                        <img src={palmtreeOnam23} alt='palm tree campaign image' className='w-100' />
                                    </div>
                                    <div className='col-xl-3 col-md-4 col-6'>
                                        <img src={palmtreeOnam24} alt='palm tree campaign image' className='w-100' />
                                    </div>
                                    <div className='col-xl-3 col-md-4 col-6'>
                                        <img src={palmtreeOnam25} alt='palm tree campaign image' className='w-100' />
                                    </div>
                                    <div className='col-xl-3 col-md-4 col-6'>
                                        <img src={palmtreeOnam26} alt='palm tree campaign image' className='w-100' />
                                    </div>
                                    <div className='col-xl-3 col-md-4 col-6'>
                                        <img src={palmtreeOnam27} alt='palm tree campaign image' className='w-100' />
                                    </div>
                                    <div className='col-xl-3 col-md-4 col-6'>
                                        <img src={palmtreeOnam28} alt='palm tree campaign image' className='w-100' />
                                    </div>
                                    <div className='col-xl-3 col-md-4 col-6'>
                                        <img src={palmtreeOnam29} alt='palm tree campaign image' className='w-100' />
                                    </div>
                                    <div className='col-xl-3 col-md-4 col-6'>
                                        <img src={palmtreeOnam30} alt='palm tree campaign image' className='w-100' />
                                    </div>
                                    <div className='col-xl-3 col-md-4 col-6'>
                                        <img src={palmtreeOnam31} alt='palm tree campaign image' className='w-100' />
                                    </div>
                                    <div className='col-xl-3 col-md-4 col-6'>
                                        <img src={palmtreeOnam32} alt='palm tree campaign image' className='w-100' />
                                    </div>
                                    <div className='col-12'>
                                        <img src={palmtreeOnam33} alt='palm tree campaign image' className='w-100' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='workApproach'>
                    <div className='workApproachBG'>
                        <div className='container'>
                            <div className='row justify-content-center'>
                                <div className='col-xxl-12 col-11'>
                                    <div className='row justify-content-lg-end'>
                                    <CategoryBadge badgeText={props.resultText} badgeCircle={props.resultCircle} badgeType="result" />
                                        <div className='col-auto'>
                                            <h2 className='text-white text-uppercase my-5 py-lg-4' >
                                                Our <br />Approach
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='workApproachAccordion'>
                        <div className='row g-0 justify-content-lg-start justify-content-center'>
                            <div className='col-lg-auto col-md-9 col-11'>
                                <div className={ accordion2[0] ? 'wAAContent wAAOpen' : 'wAAContent' } >
                                    <div className='wAAContentTrigger' onClick={ () => { setAccordion2( [ !accordion2[0], false, false ] ) } } ></div>
                                    <div className='wAAContentHead ms-lg-0 ms-md-5 ms-4 pb-lg-0 pb-3'>
                                        <h4>
                                            01.
                                        </h4>
                                        <h3>
                                            Activation
                                        </h3>
                                    </div>
                                    <div className='wAAContentBody'>
                                        <p className='m-0 px-md-5 p-4 py-5 pt-lg-5 pt-3' >
                                        The story was divided into 16 posts each ending with a cliffhanger.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-auto col-md-9 col-11'>
                                <div className={ accordion2[1] ? 'wAAContent wAAOpen' : 'wAAContent' } >
                                    <div className='wAAContentTrigger' onClick={ () => { setAccordion2( [ false, !accordion2[1], false ] ) } } ></div>
                                    <div className='wAAContentHead ms-lg-0 ms-md-5 ms-4 pb-lg-0 pb-3'>
                                        <h4>
                                            02.
                                        </h4>   
                                        <h3>
                                        Insight and Strategy
                                        </h3>
                                    </div>
                                    <div className='wAAContentBody'>
                                        <p className='m-0 px-md-5 p-4 py-5 pt-lg-5 pt-3' >
                                        Illustrations have always had their audience on social media platforms, especially Instagram. The strategy was to create an illustrated story that had the flavour of Onam. That's how "Oru Pazham Kadha" was born.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-auto col-md-9 col-11'>
                                <div className={ accordion2[2] ? 'wAAContent wAAOpen' : 'wAAContent' } >
                                    <div className='wAAContentTrigger' onClick={ () => { setAccordion2( [ false, false, !accordion2[2] ] ) } } ></div>
                                    <div className='wAAContentHead ms-lg-0 ms-md-5 ms-4 pb-lg-0 pb-3'>
                                        <h4>
                                            03.
                                        </h4>
                                        <h3>
                                        Challenges
                                        </h3>
                                    </div>
                                    <div className='wAAContentBody'>
                                        <p className='m-0 px-md-5 p-4 py-5 pt-lg-5 pt-3' >
                                        The account was going through a drought of engaging content. The challenge was to create a compelling campaign that stood out from the routine seasonal campaigns. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='workResults py-5'>
                    <div className='container py-4'>
                        <div className='row justify-content-center'>
                            <div className='col-xxl-12 col-11'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <h2 className='text-white' >
                                            Result
                                        </h2>
                                    </div>
                                    <div className='col-lg-6'>
                                        <p className='m-0' >
                                        The marriage of effective storytelling and imaginative visualization resonated with the TG. The audience was curious to know about the rest of the story. As the campaign ended, the Instagram handle clocked 2000 followers.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <WorkPagination current={props.workID} />
            </div>
            <Footer />
        </>
    )
}

export default Palmtree