import React from 'react';
import logoB from '../assets/images/swc_logo.png';
import logoW from '../assets/images/swc_logo_white.png';
import '../style/navigation.css';
import { Link } from 'react-router-dom';

function InnerpageHeader(props) {

    const handleBackToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

  return (
    <>
        <div className={ !props.whitePage ? 'innerPageHeader' : 'innerPageHeader blend-in' } >
            <div className='row justify-content-between align-items-center'>
                <div className='col-auto'>
                    <div className='iph-logoWrap'>
                        <Link to='/' >
                            <img src={ !props.whitePage ? logoB : logoW } alt="swc logo" className='img-fluid' />
                        </Link>
                    </div>
                </div>
                <div className='col-auto'>
                    <div className={ !props.whitePage ? "swc_hamburger iph-hamburger" : "swc_hamburger iph-hamburger white-ham" } data-bs-toggle="offcanvas" data-bs-target="#sidebarNavigation" aria-controls="sidebarNavigation" >
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>
        </div>
        <div className='backToTop' onClick={handleBackToTop} >
        </div>
    </>
  )
}

export default InnerpageHeader