import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import InnerpageHeader from '../../InnerpageHeader';
import Footer from '../../Footer';
import '../../../style/fonts.css';
import '../../../style/works.css';
import { Link } from 'react-router-dom';

import image01 from '../../../assets/images/works/communication/grandhayatt/Grand-Hyatt-Kochi---image01.jpg';
import image02 from '../../../assets/images/works/communication/grandhayatt/Grand-Hyatt-Kochi---image02.jpg';
import image03 from '../../../assets/images/works/communication/grandhayatt/Grand-Hyatt-Kochi---image03.jpg';
import image04 from '../../../assets/images/works/communication/grandhayatt/Grand-Hyatt-Kochi---image04.jpg';
import image05 from '../../../assets/images/works/communication/grandhayatt/Grand-Hyatt-Kochi---image05.jpg';
import image06 from '../../../assets/images/works/communication/grandhayatt/Grand-Hyatt-Kochi---image06.jpg';
import image07 from '../../../assets/images/works/communication/grandhayatt/Grand-Hyatt-Kochi---image07.jpg';
import image08 from '../../../assets/images/works/communication/grandhayatt/Grand-Hyatt-Kochi---image08.jpg';
import image09 from '../../../assets/images/works/communication/grandhayatt/Grand-Hyatt-Kochi---image09.jpg';
import image10 from '../../../assets/images/works/communication/grandhayatt/Grand-Hyatt-Kochi---image10.jpg';
import image11 from '../../../assets/images/works/communication/grandhayatt/Grand-Hyatt-Kochi---image11.jpg';
import image12 from '../../../assets/images/works/communication/grandhayatt/Grand-Hyatt-Kochi---image12.jpg';
import image13 from '../../../assets/images/works/communication/grandhayatt/Grand-Hyatt-Kochi---image13.png';
import image14 from '../../../assets/images/works/communication/grandhayatt/Grand-Hyatt-Kochi---image14.jpg';
import image15 from '../../../assets/images/works/communication/grandhayatt/Grand-Hyatt-Kochi---image15.jpg';
import image16 from '../../../assets/images/works/communication/grandhayatt/Grand-Hyatt-Kochi---image16.jpg';
import image17 from '../../../assets/images/works/communication/grandhayatt/Grand-Hyatt-Kochi---image17.jpg';
import image18 from '../../../assets/images/works/communication/grandhayatt/Grand-Hyatt-Kochi---image18.jpg';
import image19 from '../../../assets/images/works/communication/grandhayatt/Grand-Hyatt-Kochi---image19.jpg';
import image20 from '../../../assets/images/works/communication/grandhayatt/Grand-Hyatt-Kochi---image20.jpg';
import image21 from '../../../assets/images/works/communication/grandhayatt/Grand-Hyatt-Kochi---image21.jpg';
import image22 from '../../../assets/images/works/communication/grandhayatt/Grand-Hyatt-Kochi---image22.jpg';
import image23 from '../../../assets/images/works/communication/grandhayatt/Grand-Hyatt-Kochi---image23.jpg';
import image24 from '../../../assets/images/works/communication/grandhayatt/Grand-Hyatt-Kochi---image24.jpg';
import image25 from '../../../assets/images/works/communication/grandhayatt/Grand-Hyatt-Kochi---image25.jpg';
import image26 from '../../../assets/images/works/communication/grandhayatt/Grand-Hyatt-Kochi---image26.jpg';
import image27 from '../../../assets/images/works/communication/grandhayatt/Grand-Hyatt-Kochi---image27.jpg';
import image28 from '../../../assets/images/works/communication/grandhayatt/Grand-Hyatt-Kochi---image28.jpg';
import image29 from '../../../assets/images/works/communication/grandhayatt/Grand-Hyatt-Kochi---image29.jpg';
import image30 from '../../../assets/images/works/communication/grandhayatt/Grand-Hyatt-Kochi---image30.jpg';
import image31 from '../../../assets/images/works/communication/grandhayatt/Grand-Hyatt-Kochi---image31.jpg';
import image32 from '../../../assets/images/works/communication/grandhayatt/Grand-Hyatt-Kochi---image32.jpg';
import image33 from '../../../assets/images/works/communication/grandhayatt/Grand-Hyatt-Kochi---image33.jpg';
import image34 from '../../../assets/images/works/communication/grandhayatt/Grand-Hyatt-Kochi---image34.jpg';
import image35 from '../../../assets/images/works/communication/grandhayatt/Grand-Hyatt-Kochi---image35.jpg';
import image36 from '../../../assets/images/works/communication/grandhayatt/Grand-Hyatt-Kochi---image36.jpg';
import image37 from '../../../assets/images/works/communication/grandhayatt/Grand-Hyatt-Kochi---image37.jpg';
import image38 from '../../../assets/images/works/communication/grandhayatt/Grand-Hyatt-Kochi---image38.jpg';
import image39 from '../../../assets/images/works/communication/grandhayatt/Grand-Hyatt-Kochi---image39.jpg';

import WorkPagination from '../WorkPagination';
import CategoryBadge from '../CategoryBadge';

function Ayurcentral(props) {

    const [ accordion1, setAccordion1 ] = useState( [ false, false, false ] );

    useEffect(() => {
        document.body.removeAttribute('class');
        document.body.classList.add('inner-page', 'single-work-page');
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <>
            <Helmet>
                <title>
                    {props.metaTitle}
                </title>
                <meta property="og:title" content={props.metaTitle} />
                <meta property="og:description" content={props.metaDescription} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={ window.location } />
                <meta property="og:image" content="" />
            </Helmet>
            <InnerpageHeader/>
            <div className='single-work'>
                <div className='container pb-4'>
                    <div className='row justify-content-center'>
                        <div className='col-xxl-12 col-11'>
                            <div className='pageBreadcrumb'>
                                <h6 className='m-0' >
                                    <Link to='/'>Home</Link> / <Link to='/works'>Works</Link> / Comunication / Grand Hyatt Kochi
                                </h6>
                            </div>
                            <div className='singleWorkHead my-5'>
                                <h1 className='singleWorkTitle text-uppercase mb-3'>
                                Grand Hyatt Kochi
                                </h1>
                                <p>
                                The partnership with Grand Hyatt represents yet another remarkable milestone in our journey. As we dive into this creative alliance, our enthusiasm soars to new heights. From conceptualizing and designing captivating visual narratives to formulating strategies that resonate with the global appeal of Grand Hyatt, we are committed to delivering innovative and effective solutions. The canvas is vast, the possibilities endless, and our determination unwavering as we embark on this creative voyage. 
                                </p>
                                <CategoryBadge badgeText={props.catBadgeText} badgeCircle={props.catBadgeCircle} badgeType="category" />
                            </div>
                            <div className='workMeta row g-5 pb-5'>
                                <div className='col-lg-5 col-auto'>
                                    <h5 className='mb-0' >
                                    Services we have done 
                                    </h5>
                                    <h4 className='mb-0' >
                                    Social media creatives
                                    </h4>
                                </div>
                            </div>
                            <div className='singleWorkContent hayattGrid mb-5 px-lg-5 px-4 py-5'>
                                <div className='row g-lg-3 g-md-2 g-4'>
                                    <div className='col-lg-4 col-sm-6'>
                                        <img src={image01} alt='post-01' className='w-100 rounded' />
                                    </div>
                                    <div className='col-lg-4 col-sm-6'>
                                        <img src={image02} alt='post-02' className='w-100 rounded' />
                                    </div>
                                    <div className='col-lg-4 col-sm-6'>
                                        <img src={image03} alt='post-03' className='w-100 rounded' />
                                    </div>
                                </div>
                                <h3 className='sectionTitle text-center my-0 py-5' >
                                    Feed
                                </h3>
                                <div className='row g-lg-3 g-md-2 g-1'>
                                    <div className='col-4'>
                                        <img src={image04} alt='post-04' className='w-100' />
                                    </div>
                                    <div className='col-4'>
                                        <img src={image05} alt='post-05' className='w-100' />
                                    </div>
                                    <div className='col-4'>
                                        <img src={image06} alt='post-06' className='w-100' />
                                    </div>
                                    <div className='col-4'>
                                        <img src={image07} alt='post-07' className='w-100' />
                                    </div>
                                    <div className='col-4'>
                                        <img src={image08} alt='post-08' className='w-100' />
                                    </div>
                                    <div className='col-4'>
                                        <img src={image09} alt='post-09' className='w-100' />
                                    </div>
                                    <div className='col-4'>
                                        <img src={image10} alt='post-10' className='w-100' />
                                    </div>
                                    <div className='col-4'>
                                        <img src={image11} alt='post-11' className='w-100' />
                                    </div>
                                    <div className='col-4'>
                                        <img src={image12} alt='post-12' className='w-100' />
                                    </div>
                                </div>
                                <img src={image13} alt='post-13' className='w-100 no-shadow' />
                                <div className='row gx-lg-4 gx-2 gy-3 justify-content-center'>
                                    <div className='col-lg-4 col-6'>
                                        <img src={image14} alt='post-14' className='w-100 rounded' />
                                    </div>
                                    <div className='col-lg-4 col-6'>
                                        <img src={image15} alt='post-15' className='w-100 rounded' />
                                    </div>
                                    <div className='col-12'></div>
                                    <div className='col-lg-4 col-6'>
                                        <img src={image16} alt='post-16' className='w-100 rounded' />
                                    </div>
                                    <div className='col-lg-4 col-6'>
                                        <img src={image17} alt='post-17' className='w-100 rounded' />
                                    </div>
                                </div>
                                <img src={image18} alt='post-18' className='w-100 no-shadow' />
                                <h3 className='sectionTitle text-center my-0 pb-5' >
                                    Stories
                                </h3>
                                <div className='row gx-lg-4 gx-2 gy-lg-5 gy-3 justify-content-center'>
                                    <div className='col-lg-4 col-6'>
                                        <img src={image19} alt='post-19' className='w-100' />
                                    </div>
                                    <div className='col-lg-4 col-6'>
                                        <img src={image20} alt='post-20' className='w-100' />
                                    </div>
                                    <div className='col-lg-4 col-6'>
                                        <img src={image21} alt='post-21' className='w-100' />
                                    </div>
                                    <div className='col-lg-4 col-6'>
                                        <img src={image22} alt='post-22' className='w-100' />
                                    </div>
                                    <div className='col-lg-4 col-6'>
                                        <img src={image23} alt='post-23' className='w-100' />
                                    </div>
                                    <div className='col-lg-4 col-6'>
                                        <img src={image24} alt='post-24' className='w-100' />
                                    </div>
                                    <div className='col-lg-4 col-6'>
                                        <img src={image25} alt='post-25' className='w-100' />
                                    </div>
                                    <div className='col-lg-4 col-6'>
                                        <img src={image26} alt='post-26' className='w-100' />
                                    </div>
                                    <div className='col-lg-4 col-6'>
                                        <img src={image27} alt='post-27' className='w-100' />
                                    </div>
                                    <div className='col-lg-4 col-6'>
                                        <img src={image28} alt='post-28' className='w-100' />
                                    </div>
                                    <div className='col-lg-4 col-6'>
                                        <img src={image29} alt='post-29' className='w-100' />
                                    </div>
                                    <div className='col-lg-4 col-6'>
                                        <img src={image30} alt='post-30' className='w-100' />
                                    </div>
                                    <div className='col-lg-4 col-6'>
                                        <img src={image31} alt='post-31' className='w-100' />
                                    </div>
                                    <div className='col-lg-4 col-6'>
                                        <img src={image32} alt='post-32' className='w-100' />
                                    </div>
                                    <div className='col-lg-4 col-6'>
                                        <img src={image33} alt='post-33' className='w-100' />
                                    </div>
                                </div>
                                <h3 className='sectionTitle text-center my-0 py-5' >
                                Illustrations
                                </h3>
                                <div className='row gx-lg-4 gx-2 gy-3 justify-content-center'>
                                    <div className='col-lg-4 col-6'>
                                        <img src={image34} alt='post-34' className='w-100 rounded' />
                                    </div>
                                    <div className='col-lg-4 col-6'>
                                        <img src={image35} alt='post-35' className='w-100 rounded' />
                                    </div>
                                    <div className='col-12'></div>
                                    <div className='col-lg-4 col-6'>
                                        <img src={image36} alt='post-36' className='w-100 rounded' />
                                    </div>
                                    <div className='col-lg-4 col-6'>
                                        <img src={image37} alt='post-37' className='w-100 rounded' />
                                    </div>
                                    <div className='col-12'></div>
                                    <div className='col-lg-4 col-6'>
                                        <img src={image38} alt='post-38' className='w-100 rounded' />
                                    </div>
                                    <div className='col-lg-4 col-6'>
                                        <img src={image39} alt='post-39' className='w-100 rounded' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='workApproach'>
                    <div className='workApproachBG'>
                        <div className='container'>
                            <div className='row justify-content-center'>
                                <div className='col-xxl-12 col-11'>
                                    <div className='row justify-content-lg-end'>
                                    <CategoryBadge badgeText={props.resultText} badgeCircle={props.resultCircle} badgeType="result" />
                                        <div className='col-auto'>
                                            <h2 className='text-white text-uppercase my-5 py-lg-4' >
                                                Our <br />Approach
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='workApproachAccordion'>
                        <div className='row g-0 justify-content-lg-start justify-content-center'>
                            <div className='col-lg-auto col-md-9 col-11'>
                                <div className={ accordion1[0] ? 'wAAContent wAAOpen' : 'wAAContent' } >
                                    <div className='wAAContentTrigger' onClick={ () => { setAccordion1( [ !accordion1[0], false, false ] ) } } ></div>
                                    <div className='wAAContentHead ms-lg-0 ms-md-5 ms-4 pb-lg-0 pb-3'>
                                        <h4>
                                            01.
                                        </h4>
                                        <h3>
                                            Activation
                                        </h3>
                                    </div>
                                    <div className='wAAContentBody'>
                                        <p className='m-0 px-md-5 p-4 py-5 pt-lg-5 pt-3' >
                                        We intend to keep our communications fresh and visually captivating while maintaining alignment with Hyatt's brand identity. We remain committed to delivering innovative and effective content that aligns with the unique character of this property and its significance in the heart of Cochin.  We endeavour to tailor our approach to meet and exceed the unique requirements of our client, Hyatt, an esteemed international brand.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-auto col-md-9 col-11'>
                                <div className={ accordion1[1] ? 'wAAContent wAAOpen' : 'wAAContent' } >
                                    <div className='wAAContentTrigger' onClick={ () => { setAccordion1( [ false, !accordion1[1], false ] ) } } ></div>
                                    <div className='wAAContentHead ms-lg-0 ms-md-5 ms-4 pb-lg-0 pb-3'>
                                        <h4>
                                            02.
                                        </h4>   
                                        <h3>
                                        Insight and Strategy
                                        </h3>
                                    </div>
                                    <div className='wAAContentBody'>
                                        <p className='m-0 px-md-5 p-4 py-5 pt-lg-5 pt-3' >
                                        In pursuit of our strategic objectives, we have meticulously crafted a detailed monthly content plan. Located in the heart of Cochin, our property engages with a diverse audience, leading us to occasionally communicate in both English and Malayalam for more effective engagement. We've embraced creative approaches, including illustrations, diverse colour schemes, and different design styles, to keep our content engaging and aligned with Hyatt's brand.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-auto col-md-9 col-11'>
                                <div className={ accordion1[2] ? 'wAAContent wAAOpen' : 'wAAContent' } >
                                    <div className='wAAContentTrigger' onClick={ () => { setAccordion1( [ false, false, !accordion1[2] ] ) } } ></div>
                                    <div className='wAAContentHead ms-lg-0 ms-md-5 ms-4 pb-lg-0 pb-3'>
                                        <h4>
                                            03.
                                        </h4>
                                        <h3>
                                        Challenges
                                        </h3>
                                    </div>
                                    <div className='wAAContentBody'>
                                        <p className='m-0 px-md-5 p-4 py-5 pt-lg-5 pt-3' >
                                        To elevate the global brand identity to a local level and construct a robust social media strategy with the power to captivate a broader and more diverse audience. We embarked on this journey with the intent of bridging the gap between international excellence and local relevance, establishing a harmonious synergy that would resonate with the heart and soul of the community. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='workResults py-5'>
                    <div className='container py-4'>
                        <div className='row justify-content-center'>
                            <div className='col-xxl-12 col-11'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <h2 className='text-white' >
                                            Result
                                        </h2>
                                    </div>
                                    <div className='col-lg-6'>
                                        <p className='m-0' >
                                        The synergy of strategic planning and a creative visual approach proved instrumental in the success of our initiatives. The brand's social media pages have been effectively positioned as one of the top 5 properties in Kerala. Concurrently, there was a substantial boost in Instagram followers, witnessing an increase from 23.7K to an impressive 30.5K, underscoring our effectiveness in expanding the brand's reach and resonance with the audience.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <WorkPagination current={props.workID} />
            </div>
            <Footer />
        </>
    )
}

export default Ayurcentral