import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import InnerpageHeader from '../../InnerpageHeader';
import Footer from '../../Footer';
import '../../../style/fonts.css';
import '../../../style/works.css';
import { Link } from 'react-router-dom';
import image01 from '../../../assets/images/works/campaign/lazza/campaign-lazzaImage01.png';
import image02 from '../../../assets/images/works/campaign/lazza/campaign-lazzaImage02.png';
import image03 from '../../../assets/images/works/campaign/lazza/campaign-lazzaImage03.png';
import image04 from '../../../assets/images/works/campaign/lazza/campaign-lazzaImage04.png';
import image05 from '../../../assets/images/works/campaign/lazza/campaign-lazzaImage05.png';
import image06 from '../../../assets/images/works/campaign/lazza/campaign-lazzaImage06.png';
import image07 from '../../../assets/images/works/campaign/lazza/campaign-lazzaImage07.png';
import image08 from '../../../assets/images/works/campaign/lazza/campaign-lazzaImage08.png';
import image09 from '../../../assets/images/works/campaign/lazza/campaign-lazzaImage09.png';
import WorkPagination from '../WorkPagination';
import CategoryBadge from '../CategoryBadge';

function Lazza(props) {

    const [ accordion1, setAccordion1 ] = useState( [ false, false, false ] );

    useEffect(() => {
        document.body.removeAttribute('class');
        document.body.classList.add('inner-page', 'single-work-page');
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <HelmetProvider>
            <Helmet>
                <title>
                    {props.metaTitle}
                </title>
                <meta property="og:title" content={props.metaTitle} />
                <meta property="og:description" content={props.metaDescription} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={ window.location } />
                <meta property="og:image" content="" />
            </Helmet>
            <InnerpageHeader/>
            <div className='single-work'>
                <div className='container pb-5 mb-3'>
                    <div className='row justify-content-center'>
                        <div className='col-xxl-12 col-11'>
                            <div className='pageBreadcrumb'>
                                <h6 className='m-0' >
                                    <Link to='/'>Home</Link> / <Link to='/works'>Works</Link> / Campaigns / Lazza Ice Creams
                                </h6>
                            </div>
                            <div className='singleWorkHead my-5'>
                                <h1 className='singleWorkTitle text-uppercase mb-3'>
                                Lazza Ice Creams
                                </h1>
                                <p>
                                During the peak of the Covid-19 pandemic, we collaborated with Lazza Ice Creams on an inspiring project focused on their Sugar Free Ice Creams. We had a digital target of 10,000 website visits. We surpassed it under a low-budget with flying colours by hitting 30,000 visits in a short period of time.  
                                </p>
                                <CategoryBadge badgeText={props.catBadgeText} badgeCircle={props.catBadgeCircle} badgeType="category" />
                            </div>
                            <div className='singleWorkContent pt-5'>
                                <img src={image01} alt='alf digital' className='w-100' />
                                <img src={image02} alt='alf digital' className='w-100' />
                                <img src={image03} alt='alf digital' className='w-100' />
                                <img src={image04} alt='alf digital' className='w-100' />
                                <img src={image05} alt='alf digital' className='w-100' />
                                <img src={image06} alt='alf digital' className='w-100' />
                                <img src={image07} alt='alf digital' className='w-100' />
                                <img src={image08} alt='alf digital' className='w-100' />
                                <img src={image09} alt='alf digital' className='w-100' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='workApproach'>
                    <div className='workApproachBG'>
                        <div className='container'>
                            <div className='row justify-content-center'>
                                <div className='col-xxl-12 col-11'>
                                    <div className='row justify-content-lg-end'>
                                    <CategoryBadge badgeText={props.resultText} badgeCircle={props.resultCircle} badgeType="result" />
                                        <div className='col-auto'>
                                            <h2 className='text-white text-uppercase my-5 py-lg-4' >
                                                Our <br />Approach
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='workApproachAccordion'>
                        <div className='row g-0 justify-content-lg-start justify-content-center'>
                            <div className='col-lg-auto col-md-9 col-11'>
                                <div className={ accordion1[0] ? 'wAAContent wAAOpen' : 'wAAContent' } >
                                    <div className='wAAContentTrigger' onClick={ () => { setAccordion1( [ !accordion1[0], false, false ] ) } } ></div>
                                    <div className='wAAContentHead ms-lg-0 ms-md-5 ms-4 pb-lg-0 pb-3'>
                                        <h4>
                                            01.
                                        </h4>
                                        <h3>
                                            Activation
                                        </h3>
                                    </div>
                                    <div className='wAAContentBody'>
                                        <p className='m-0 px-md-5 p-4 py-5 pt-lg-5 pt-3' >
                                        The irresistible appeal of sugar-free ice creams alongside the convenience of doorstep delivery helped us break barriers and successfully captured the hearts of ice cream lovers everywhere with our creative designs. <br /><br/>
                                        We employed geo-fencing techniques to ensure that our campaign reached the right audience.This strategy not only made 30,000 website visits but also provided Lazza Icecreams with valuable data to refine their UI/UX experience and enhance customer satisfaction.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-auto col-md-9 col-11'>
                                <div className={ accordion1[1] ? 'wAAContent wAAOpen' : 'wAAContent' } >
                                    <div className='wAAContentTrigger' onClick={ () => { setAccordion1( [ false, !accordion1[1], false ] ) } } ></div>
                                    <div className='wAAContentHead ms-lg-0 ms-md-5 ms-4 pb-lg-0 pb-3'>
                                        <h4>
                                            02.
                                        </h4>   
                                        <h3>
                                        Insight and Strategy
                                        </h3>
                                    </div>
                                    <div className='wAAContentBody'>
                                        <p className='m-0 px-md-5 p-4 py-5 pt-lg-5 pt-3' >
                                        Taking a unique approach, we designed a captivating campaign that blended a vintage aesthetic with modern motion graphics. The true highlight of the campaign was the innovative concept of home delivery, which was a groundbreaking idea during those uncertain times. <br/><br/>
                                        We used a series of visually stunning vintage motion graphics enriched with vibrant, captivating themes and set specific parameters, such as Cochin city and targeted pin codes to maximise the impact of our marketing efforts. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-auto col-md-9 col-11'>
                                <div className={ accordion1[2] ? 'wAAContent wAAOpen' : 'wAAContent' } >
                                    <div className='wAAContentTrigger' onClick={ () => { setAccordion1( [ false, false, !accordion1[2] ] ) } } ></div>
                                    <div className='wAAContentHead ms-lg-0 ms-md-5 ms-4 pb-lg-0 pb-3'>
                                        <h4>
                                            03.
                                        </h4>
                                        <h3>
                                        Challenges
                                        </h3>
                                    </div>
                                    <div className='wAAContentBody'>
                                        <p className='m-0 px-md-5 p-4 py-5 pt-lg-5 pt-3' >
                                        Employing a creative campaign of our design, we had to hit 10,000 website visits during a period when business was faltering due to the economic and health crisis. It had to be a quick and precise idea, and to be pulled off with effective strategic measures.  
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='workResults py-5'>
                    <div className='container py-4'>
                        <div className='row justify-content-center'>
                            <div className='col-xxl-12 col-11'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <h2 className='text-white' >
                                            Result
                                        </h2>
                                    </div>
                                    <div className='col-lg-6'>
                                        <p className='m-0' >
                                        The combination of precise geo-targeting, engaging motion graphics, and a deep understanding of consumer preferences culminated in a resounding digital success for the campaign culminating in 30,000 website visits in a short period of time. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <WorkPagination current={props.workID} />
            </div>
            <Footer />
        </HelmetProvider>
    )
}

export default Lazza