import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import InnerpageHeader from '../../InnerpageHeader';
import Footer from '../../Footer';
import '../../../style/fonts.css';
import '../../../style/works.css';
import { Link } from 'react-router-dom';
import image01 from '../../../assets/images/works/website/alf/digital-alfimage01.png';
import image02 from '../../../assets/images/works/website/alf/digital-alfimage02.png';
import image03 from '../../../assets/images/works/website/alf/digital-alfimage03.png';
import image04 from '../../../assets/images/works/website/alf/digital-alfimage04.png';
import image05 from '../../../assets/images/works/website/alf/digital-alfimage05.png';
import image06 from '../../../assets/images/works/website/alf/digital-alfimage06.png';
import image07 from '../../../assets/images/works/website/alf/digital-alfimage07.png';
import image08 from '../../../assets/images/works/website/alf/digital-alfimage08.png';
import image09 from '../../../assets/images/works/website/alf/digital-alfimage09.png';
import image10 from '../../../assets/images/works/website/alf/digital-alfimage10.png';
import image11 from '../../../assets/images/works/website/alf/digital-alfimage11.png';
import image12 from '../../../assets/images/works/website/alf/digital-alfimage12.png';
import image13 from '../../../assets/images/works/website/alf/digital-alfimage13.png';
import image14 from '../../../assets/images/works/website/alf/digital-alfimage14.png';
import WorkPagination from '../WorkPagination';
import CategoryBadge from '../CategoryBadge';

function Alf(props) {

    useEffect(() => {
        document.body.removeAttribute('class');
        document.body.classList.add('inner-page', 'single-work-page');
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <HelmetProvider>
            <Helmet>
                <title>
                    {props.metaTitle}
                </title>
                <meta property="og:title" content={props.metaTitle} />
                <meta property="og:description" content={props.metaDescription} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={ window.location } />
                <meta property="og:image" content="" />
            </Helmet>
            <InnerpageHeader/>
            <div className='single-work'>
                <div className='container pb-4'>
                    <div className='row justify-content-center'>
                        <div className='col-xxl-12 col-11'>
                            <div className='pageBreadcrumb'>
                                <h6 className='m-0' >
                                    <Link to='/'>Home</Link> / <Link to='/works'>Works</Link> / Website / Alliance Literary Festival
                                </h6>
                            </div>
                            <div className='singleWorkHead my-5'>
                                <h1 className='singleWorkTitle text-uppercase mb-3'>
                                ALF
                                </h1>
                                <p>
                                Our agency had the pleasure of collaborating with ALF (Alliance Literary Festival on their website, and boy oh boy, we delivered. We developed an innovative and interactive website with a remarkable concept—a literal map of the festival grounds, complete with charming, interactive characters. This creative visual approach enabled users to embark on a virtual journey through the festival's enchanting environment, guiding them effortlessly to the information they sought.
                                </p>
                                <CategoryBadge badgeText={props.catBadgeText} badgeCircle={props.catBadgeCircle} badgeType="category" />
                            </div>
                            <div className='singleWorkContent'>
                                <div className='workMeta row pb-3 pt-4'>
                                    <div className='col-lg-5 col-auto'>
                                        <h4 className='mb-0' >
                                        Desktop View
                                        </h4>
                                    </div>
                                </div>
                                <img src={image01} alt='alf digital' className='w-100' />
                                <img src={image02} alt='alf digital' className='w-100' />
                                <div className='row g-md-1 py-md-1'>
                                    <div className='col-md-6'>
                                        <img src={image03} alt='alf digital' className='w-100' />
                                    </div>
                                    <div className='col-md-6'>
                                    <img src={image04} alt='alf digital' className='w-100' />
                                    </div>
                                    <div className='col-md-6'>
                                    <img src={image05} alt='alf digital' className='w-100' />
                                    </div>
                                    <div className='col-md-6'>
                                    <img src={image06} alt='alf digital' className='w-100' />
                                    </div>
                                </div>
                                <img src={image07} alt='alf digital' className='w-100' />
                                <img src={image08} alt='alf digital' className='w-100' />
                                <img src={image09} alt='alf digital' className='w-100' />
                                <div className='workMeta row pb-3 pt-5 mt-4'>
                                    <div className='col-lg-5 col-auto'>
                                        <h4 className='mb-0' >
                                        Mobile View
                                        </h4>
                                    </div>
                                </div>
                                <img src={image10} alt='alf digital' className='w-100' />
                                <div className='row g-md-1 py-md-1'>
                                    <div className='col-md-4'>
                                    <img src={image11} alt='alf digital' className='w-100' />
                                    </div>
                                    <div className='col-md-4'>
                                    <img src={image12} alt='alf digital' className='w-100' />
                                    </div>
                                    <div className='col-md-4'>
                                    <img src={image13} alt='alf digital' className='w-100' />
                                    </div>
                                </div>
                                <img src={image14} alt='alf digital' className='w-100' />
                            </div>
                        </div>
                    </div>
                </div>
                <WorkPagination current={props.workID} />
            </div>
            <Footer />
        </HelmetProvider>
    )
}

export default Alf