import React, { useEffect } from 'react';
import InnerpageHeader from '../InnerpageHeader';
import Footer from '../Footer';
import '../../style/digital.css';
import { Helmet } from 'react-helmet';
import image01 from '../../assets/images/unadvertising-image-01.png';
import image02 from '../../assets/images/unadvertising-image-02.png';
import image03 from '../../assets/images/unadvertising-image-03.png';

function Unadvertising( props ) {
    useEffect(() => {
        document.body.removeAttribute('class');
        document.body.classList.add('inner-page', 'unadvertising-page');
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <>
        <Helmet>
                <title>
                    {props.metaTitle}
                </title>
                <meta property="og:title" content={props.metaTitle} />
                <meta property="og:description" content={props.metaDescription} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={ window.location } />
                <meta property="og:image" content="" />
            </Helmet>
            <InnerpageHeader/>
            <div className='servicesPageContent digitalPageContent category' >
                <div className='servicespageBanner text-center'>
                    <h1 className='text-center my-0 py-5 text-white text-uppercase un-text'>
                    <img src={ props.advertBadge } alt='unpackaging badge' />
                    Advertising
                    </h1>
                </div>
                <div className='py-5'>
                    <div className='container py-4'>
                        <div className='row justify-content-center'>
                            <div className='col-lg-11 col-sm-12 col-11 d-flex flex-column align-items-center'>
                                <p className='m-0 pb-5' >
                                We hope that in the future, advertising will not become redundant because:
                                </p>
                                <div className='ad-imageWrap mb-5'>
                                    <img src={image01} alt='unbranding illustration' className='w-100' />
                                    <p className='m-0 text-center' >
                                    Imagine a world without advertising; it would be so bland, <br className='d-lg-inline d-none' />so black & white, so not right.
                                    </p>
                                </div>
                                <div className='ad-imageWrap mb-5 alt'>
                                    <img src={image02} alt='unbranding illustration' className='w-100' />
                                    <p className='m-0 text-center' >
                                    Advertising is how many people make a living, contributing to the job market in an acceptable capitalist economy that is becoming more and more equitable in the competitive spirit.
                                    </p>
                                </div>
                                <p className='m-0 pb-4' >
                                Besides, achieving a panacea of success for a brand means it is less dependent on advertising. This would be a dream come true for marketers. Our effort would be to partner with them on this journey. Somewhere, we need to find our own Raison d'être for partaking in this shifting sands of business models. We don't want to be consultants; we don't want to be agents of things that are obvious to the bare eyes unless it is about 'change'. By that logic, advertising intrinsically is Unadvertising, with its foremost objective for the product or service in question to stand on its feet independently.
                                </p>
                                <img src={image03} alt='unbranding illustration' className='w-100 py-5' />
                                <p className='m-0 pb-2 pt-4' >
                                So, to keep our job and remain relevant, our foremost goal is to assess if we can sell without advertising, and in that case, we will tell you so, so you can turn product stories into product sell, Unadvertisingly, so to speak!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Unadvertising