import React, { useEffect } from 'react';
import InnerpageHeader from '../InnerpageHeader';
import Footer from '../Footer';
import '../../style/services.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function DigitalMarketing(props) {

  useEffect(() => {
    document.body.removeAttribute('class');
    document.body.classList.add('inner-page', 'digital-page');
    window.scrollTo({ top: 0, behavior: 'smooth' });
}, []);

  return (
    <>
      <Helmet>
                <title>
                    {props.metaTitle}
                </title>
                <meta property="og:title" content={props.metaTitle} />
                <meta property="og:description" content={props.metaDescription} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={ window.location } />
                <meta property="og:image" content="" />
            </Helmet>
      <InnerpageHeader/>
      <div className='servicesPageContent' >
        <div className='servicespageBanner'>
          <h1 className='text-center my-0 py-5 text-uppercase' >
            Digital Marketing
          </h1>
        </div>
        <div className='servicesListContainer container-xxl p-0'>
          <div className='row g-2 p-2 justify-content-center'>
            <div className='col-lg-4 col-sm-9'>
              <Link to='/services/digital-marketing/lead-generation'>
                <div className='servicesListItem servicesListItem_1 text-center p-5'>
                  <h2 className='m-0 text-white' >
                    <span>Lead</span>generation
                  </h2>
                </div>
              </Link>
            </div>
            <div className='col-lg-4 col-sm-9'>
              <Link to='/services/digital-marketing/seo'>
                <div className='servicesListItem servicesListItem_2 text-center p-5'>
                  <h2 className='m-0' >
                  <span>SEARCH</span><span>ENGINE</span>OPTIMIZATION
                  </h2>
                </div>
              </Link>
            </div>
            <div className='col-lg-4 col-sm-9'>
              <Link to='/services/digital-marketing/ppc'>
                <div className='servicesListItem servicesListItem_3 text-center p-5'>
                  <h2 className='m-0' >
                  Pay per Clicks/<span>Performance</span><span>Marketing</span>
                  </h2>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default DigitalMarketing