import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import InnerpageHeader from '../../InnerpageHeader';
import Footer from '../../Footer';
import '../../../style/fonts.css';
import '../../../style/works.css';
import { Link } from 'react-router-dom';
import image01 from '../../../assets/images/works/media/palmtree/branding-palmree-image01.png';
import image02 from '../../../assets/images/works/media/palmtree/branding-palmree-image02.png';
import image03 from '../../../assets/images/works/media/palmtree/branding-palmree-image03.png';
import image04 from '../../../assets/images/works/media/palmtree/branding-palmree-image04.png';
import image05 from '../../../assets/images/works/media/palmtree/branding-palmree-image05.png';
import image06 from '../../../assets/images/works/media/palmtree/branding-palmree-image06.png';
import image07 from '../../../assets/images/works/media/palmtree/branding-palmree-image07.png';
import image08 from '../../../assets/images/works/media/palmtree/branding-palmree-image08.png';
import image09 from '../../../assets/images/works/media/palmtree/branding-palmree-image09.png';
import image10 from '../../../assets/images/works/media/palmtree/branding-palmree-image10.png';
import WorkPagination from '../WorkPagination';
import CategoryBadge from '../CategoryBadge';

function Palmtree(props) {

    useEffect(() => {
        document.body.removeAttribute('class');
        document.body.classList.add('inner-page', 'single-work-page');
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <>
            <Helmet>
                <title>
                    {props.metaTitle}
                </title>
                <meta property="og:title" content={props.metaTitle} />
                <meta property="og:description" content={props.metaDescription} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={ window.location } />
                <meta property="og:image" content="" />
            </Helmet>
            <InnerpageHeader/>
            <div className='single-work'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-xxl-12 col-11'>
                            <div className='pageBreadcrumb'>
                                <h6 className='m-0' >
                                    <Link to='/'>Home</Link> / <Link to='/works'>Works</Link> / Media / Palm Tree
                                </h6>
                            </div>
                            <div className='singleWorkHead my-5'>
                                <h1 className='singleWorkTitle text-uppercase mb-3'>
                                PALM TREE
                                </h1>
                                <p>
                                Palm Tree, a renowned brand, secures two monthly ad insertions in Vanitha, the most popular women's magazine in Kerala, and the Times Of India newspaper.  The communication in these ads, crafted in Malayalam & English respectively, effectively highlights the brand's diverse product range, its sources, associated health benefits, and a compelling call-to-action (CTA).
                                </p>
                                <CategoryBadge badgeText={props.catBadgeText} badgeCircle={props.catBadgeCircle} badgeType="category" />
                            </div>
                            <div className='workMeta row g-5 pb-5'>
                                <div className='col-auto'>
                                    <h5 className='mb-0' >
                                        Services we have done 
                                    </h5>
                                    <h4 className='mb-0' >
                                        Media
                                    </h4>
                                </div>
                            </div>
                            <div className='singleWorkContent'>
                                <img src={image01} alt='palmtree image' className='w-100' />
                                <div className='row g-0'>
                                    <div className='col-md-6'>
                                        <img src={image02} alt='palmtree image' className='w-100' />
                                    </div>
                                    <div className='col-md-6'>
                                        <img src={image03} alt='palmtree image' className='w-100' />
                                    </div>
                                    <div className='col-md-6'>
                                        <img src={image04} alt='palmtree image' className='w-100' />
                                    </div>
                                    <div className='col-md-6'>
                                        <img src={image05} alt='palmtree image' className='w-100' />
                                    </div>
                                </div>
                                <img src={image06} alt='palmtree image' className='w-100' />
                                <img src={image07} alt='palmtree image' className='w-100' />
                                <div className='row g-0'>
                                    <div className='col-md-6'>
                                        <img src={image08} alt='palmtree image' className='w-100' />
                                    </div>
                                    <div className='col-md-6'>
                                        <img src={image09} alt='palmtree image' className='w-100' />
                                    </div>
                                </div>
                                <img src={image10} alt='palmtree image' className='w-100' />
                            </div>
                        </div>
                    </div>
                </div>
                <WorkPagination current={props.workID} />
            </div>
            <Footer />
        </>
    )
}

export default Palmtree