import React from 'react';


function CategoryBadge(props) {

    return (
        <div className={ props.badgeType !== 'result' ? 'categoryBadge' : 'categoryBadge results' } >
            <img src={ props.badgeText } alt="category Text" className='cb-stator' /> 
            <img src={ props.badgeCircle } alt="category Circle" className='cb-rotor' /> 
        </div>
    )
}

export default CategoryBadge