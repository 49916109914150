import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import InnerpageHeader from '../../InnerpageHeader';
import Footer from '../../Footer';
import '../../../style/fonts.css';
import '../../../style/works.css';
import { Link } from 'react-router-dom';
import logoAnimation from '../../../assets/videos/APTAYULogoAnimation.mp4';
import aptayuImage01 from '../../../assets/images/works/branding/aptayu/branding-aptayu-image01.png';
import aptayuImage02 from '../../../assets/images/works/branding/aptayu/branding-aptayu-image02.png';
import aptayuImage03 from '../../../assets/images/works/branding/aptayu/branding-aptayu-image03.png';
import aptayuImage04 from '../../../assets/images/works/branding/aptayu/branding-aptayu-image04.png';
import aptayuImage05 from '../../../assets/images/works/branding/aptayu/branding-aptayu-image05.png';
import aptayuImage06 from '../../../assets/images/works/branding/aptayu/branding-aptayu-image06.png';
import aptayuImage07 from '../../../assets/images/works/branding/aptayu/branding-aptayu-image07.png';
import aptayuImage08 from '../../../assets/images/works/branding/aptayu/branding-aptayu-image08.png';
import aptayuImage09 from '../../../assets/images/works/branding/aptayu/branding-aptayu-image09.png';
import aptayuImage10 from '../../../assets/images/works/branding/aptayu/branding-aptayu-image10.png';
import aptayuImage11 from '../../../assets/images/works/branding/aptayu/branding-aptayu-image11.png';
import aptayuImage12 from '../../../assets/images/works/branding/aptayu/branding-aptayu-image12.png';
import WorkPagination from '../WorkPagination';
import CategoryBadge from '../CategoryBadge';

function Aptayu( props ) {

    useEffect(() => {
        document.body.removeAttribute('class');
        document.body.classList.add('inner-page', 'single-work-page');
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <>
            <Helmet>
                <title>
                    {props.metaTitle}
                </title>
                <meta property="og:title" content={props.metaTitle} />
                <meta property="og:description" content={props.metaDescription} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={ window.location } />
                <meta property="og:image" content="" />
            </Helmet>
            <InnerpageHeader/>
            <div className='single-work'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-xxl-12 col-11'>
                            <div className='pageBreadcrumb'>
                                <h6 className='m-0' >
                                    <Link to='/'>Home</Link> / <Link to='/works'>Works</Link> / Branding / Aptayu
                                </h6>
                            </div>
                            <div className='singleWorkHead my-5'>
                                <h1 className='singleWorkTitle text-uppercase mb-3'>
                                    Aptayu
                                </h1>
                                <p>
                                    SWC is excited to craft a compelling brand portfolio for our client Aptayu. It's with great delight that we showcase the look and feel we've created for the brand.<br/><br/>
                                    Aptayu is an Ayurvedic brand devoted to building a resilient immune system for a lifetime. Their immune-boosting products meet the lifestyle changes of an average human being at various stages of life, from childhood to old age.
                                </p>
                                <CategoryBadge badgeText={props.catBadgeText} badgeCircle={props.catBadgeCircle} badgeType="category" />
                            </div>
                            <div className='workMeta row g-5 pb-5'>
                                <div className='col-auto'>
                                    <h5 className='mb-0' >
                                        Services we have done 
                                    </h5>
                                    <h4 className='mb-0' >
                                        Branding
                                    </h4>
                                </div>
                            </div>
                            <div className='singleWorkContent'>
                                <video autoPlay muted loop id="logoGIF" className='w-100 aptayuLogoGif' >
                                    <source src={logoAnimation} type="video/mp4" />
                                </video>
                                <img src={aptayuImage01} alt='aptayu image' className='w-100' />
                                <img src={aptayuImage02} alt='aptayu image' className='w-100' />
                                <img src={aptayuImage03} alt='aptayu image' className='w-100' />
                                <img src={aptayuImage04} alt='aptayu image' className='w-100' />
                                <img src={aptayuImage05} alt='aptayu image' className='w-100' />
                                <img src={aptayuImage06} alt='aptayu image' className='w-100' />
                                <div className='row g-0 apttayuImgGrp01'>
                                    <div className='col-md-auto'>
                                        <img src={aptayuImage07} alt='aptayu image' className='w-100' />
                                    </div>
                                    <div className='col-md-auto'>
                                        <img src={aptayuImage08} alt='aptayu image' className='w-100' />
                                    </div>
                                </div>
                                <img src={aptayuImage09} alt='aptayu image' className='w-100' />
                                <div className='row g-0'>
                                    <div className='col-md-6'>
                                        <img src={aptayuImage10} alt='aptayu image' className='w-100' />
                                    </div>
                                    <div className='col-md-6'>
                                        <img src={aptayuImage11} alt='aptayu image' className='w-100' />
                                    </div>
                                </div>
                                <img src={aptayuImage12} alt='aptayu image' className='w-100' />
                            </div>
                        </div>
                    </div>
                </div>
                <WorkPagination current={props.workID} />
            </div>
            <Footer />
        </>
    )
}

export default Aptayu