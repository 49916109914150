import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import InnerpageHeader from '../../InnerpageHeader';
import Footer from '../../Footer';
import '../../../style/fonts.css';
import '../../../style/works.css';
import { Link } from 'react-router-dom';
import image01 from '../../../assets/images/works/campaign/manorama/campaign-ManoramaImage01.jpg';
import image02 from '../../../assets/images/works/campaign/manorama/campaign-ManoramaImage02.jpg';
import image03 from '../../../assets/images/works/campaign/manorama/campaign-ManoramaImage03.jpg';
import image04 from '../../../assets/images/works/campaign/manorama/campaign-ManoramaImage04.jpg';
import image05 from '../../../assets/images/works/campaign/manorama/campaign-ManoramaImage05.jpg';
import image06 from '../../../assets/images/works/campaign/manorama/campaign-ManoramaImage06.jpg';
import image07 from '../../../assets/images/works/campaign/manorama/campaign-ManoramaImage07.jpg';
import image08 from '../../../assets/images/works/campaign/manorama/campaign-ManoramaImage08.jpg';
import image09 from '../../../assets/images/works/campaign/manorama/campaign-ManoramaImage09.jpg';
import image10 from '../../../assets/images/works/campaign/manorama/campaign-ManoramaImage10.jpg';
import image11 from '../../../assets/images/works/campaign/manorama/campaign-ManoramaImage11.jpg';
import image12 from '../../../assets/images/works/campaign/manorama/campaign-ManoramaImage12.jpg';
import image13 from '../../../assets/images/works/campaign/manorama/campaign-ManoramaImage13.jpg';
import image14 from '../../../assets/images/works/campaign/manorama/campaign-ManoramaImage14.jpg';
import WorkPagination from '../WorkPagination';
import CategoryBadge from '../CategoryBadge';

function Lazza(props) {

    const [ accordion1, setAccordion1 ] = useState( [ false, false, false ] );

    useEffect(() => {
        document.body.removeAttribute('class');
        document.body.classList.add('inner-page', 'single-work-page');
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <HelmetProvider>
            <Helmet>
                <title>
                    {props.metaTitle}
                </title>
                <meta property="og:title" content={props.metaTitle} />
                <meta property="og:description" content={props.metaDescription} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={ window.location } />
                <meta property="og:image" content="" />
            </Helmet>
            <InnerpageHeader/>
            <div className='single-work'>
                <div className='container pb-5 mb-3'>
                    <div className='row justify-content-center'>
                        <div className='col-xxl-12 col-11'>
                            <div className='pageBreadcrumb'>
                                <h6 className='m-0' >
                                    <Link to='/'>Home</Link> / <Link to='/works'>Works</Link> / Campaigns / Manorama
                                </h6>
                            </div>
                            <div className='singleWorkHead my-5'>
                                <h1 className='singleWorkTitle text-uppercase mb-3'>
                                Manorama
                                </h1>
                                <p>
                                To introduce Manorama Horizon's updated online educational technology (ed-tech) platform, our primary focus is on Plus Two students. We aim to create a context that resonates with both parents and students. Not all students are familiar with their teachers' teaching methods. Some students who learn at a slower pace require patient instruction, while others may not find their teachers approachable. Some students need individualized attention, while others are reluctant to ask questions due to a fear of judgment.<br/><br/>Our strategy is built on the foundation of peer-to-peer learning, which we've branded as 'സഹപാഠി (Sahapadi)' in a popular context.
                                </p>
                                <CategoryBadge badgeText={props.catBadgeText} badgeCircle={props.catBadgeCircle} badgeType="category" />
                            </div>
                            <div className='singleWorkContent pt-5'>
                                <img src={image01} alt='alf digital' className='w-100' />
                                <img src={image02} alt='alf digital' className='w-100' />
                                <img src={image03} alt='alf digital' className='w-100' />
                                <img src={image04} alt='alf digital' className='w-100' />
                                <img src={image05} alt='alf digital' className='w-100' />
                                <img src={image06} alt='alf digital' className='w-100' />
                                <img src={image07} alt='alf digital' className='w-100' />
                                <img src={image08} alt='alf digital' className='w-100' />
                                <img src={image09} alt='alf digital' className='w-100' />
                                <img src={image10} alt='alf digital' className='w-100' />
                                <img src={image11} alt='alf digital' className='w-100' />
                                <img src={image12} alt='alf digital' className='w-100' />
                                <img src={image13} alt='alf digital' className='w-100' />
                                <img src={image14} alt='alf digital' className='w-100' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='workApproach'>
                    <div className='workApproachBG'>
                        <div className='container'>
                            <div className='row justify-content-center'>
                                <div className='col-xxl-12 col-11'>
                                    <div className='row justify-content-lg-end'>
                                    <CategoryBadge badgeText={props.resultText} badgeCircle={props.resultCircle} badgeType="result" />
                                        <div className='col-auto'>
                                            <h2 className='text-white text-uppercase my-5 py-lg-4' >
                                                Our <br />Approach
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='workApproachAccordion'>
                        <div className='row g-0 justify-content-lg-start justify-content-center'>
                            <div className='col-lg-auto col-md-9 col-11'>
                                <div className={ accordion1[0] ? 'wAAContent wAAOpen' : 'wAAContent' } >
                                    <div className='wAAContentTrigger' onClick={ () => { setAccordion1( [ !accordion1[0], false, false ] ) } } ></div>
                                    <div className='wAAContentHead ms-lg-0 ms-md-5 ms-4 pb-lg-0 pb-3'>
                                        <h4>
                                            01.
                                        </h4>
                                        <h3>
                                            Activation
                                        </h3>
                                    </div>
                                    <div className='wAAContentBody'>
                                        <p className='m-0 px-md-5 p-4 py-5 pt-lg-5 pt-3' >
                                        Our challenge is to address the complex issue of balancing the financial constraints of parents with the unique learning needs of students through an online learning system. We aim to establish a value proposition for Manorama Horizon online classes that appeals to both parents and students simultaneously.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-auto col-md-9 col-11'>
                                <div className={ accordion1[1] ? 'wAAContent wAAOpen' : 'wAAContent' } >
                                    <div className='wAAContentTrigger' onClick={ () => { setAccordion1( [ false, !accordion1[1], false ] ) } } ></div>
                                    <div className='wAAContentHead ms-lg-0 ms-md-5 ms-4 pb-lg-0 pb-3'>
                                        <h4>
                                            02.
                                        </h4>   
                                        <h3>
                                        Insight and Strategy
                                        </h3>
                                    </div>
                                    <div className='wAAContentBody'>
                                        <p className='m-0 px-md-5 p-4 py-5 pt-lg-5 pt-3' >
                                        In the realm of education, the decision-making process involves aligning the preferences of both parents, who often hold financial influence, and students, who have distinct learning needs and preferences. Our goal is to harmonize their goals and choices in a way that is relevant to them.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-auto col-md-9 col-11'>
                                <div className={ accordion1[2] ? 'wAAContent wAAOpen' : 'wAAContent' } >
                                    <div className='wAAContentTrigger' onClick={ () => { setAccordion1( [ false, false, !accordion1[2] ] ) } } ></div>
                                    <div className='wAAContentHead ms-lg-0 ms-md-5 ms-4 pb-lg-0 pb-3'>
                                        <h4>
                                            03.
                                        </h4>
                                        <h3>
                                        Challenges
                                        </h3>
                                    </div>
                                    <div className='wAAContentBody'>
                                        <p className='m-0 px-md-5 p-4 py-5 pt-lg-5 pt-3' >
                                        Our challenge is to address the complex issue of balancing the financial constraints of parents with the unique learning needs of students through an online learning system. We aim to establish a value proposition for Manorama Horizon online classes that appeals to both parents and students simultaneously.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='workResults py-5'>
                    <div className='container py-4'>
                        <div className='row justify-content-center'>
                            <div className='col-xxl-12 col-11'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <h2 className='text-white' >
                                            Result
                                        </h2>
                                    </div>
                                    <div className='col-lg-6'>
                                        <p className='m-0' >
                                        The concept of Sahapadi, peer-to-peer learning, was well received. It resonated with the target audience and successfully addressed the needs of both students and parents in the same context. We believe this success is attributed to the fact that the story was based on a strong consumer insight that students learn better together when studying with friends. Through this campaign, Manorama Horizon established itself as the Sahapadi or friend to turn to when students need academic support. Enquiries and registrations saw a significant increase in numbers.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <WorkPagination current={props.workID} />
            </div>
            <Footer />
        </HelmetProvider>
    )
}

export default Lazza