import React, { useEffect } from 'react';
import InnerpageHeader from '../InnerpageHeader';
import Footer from '../Footer';
import '../../style/services.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Services(props) {

  useEffect(() => {
    if( !document.body.classList.contains('from-homepage') ) {
      document.body.removeAttribute('class');
    }
    document.body.classList.add('inner-page', 'services-page');
    window.scrollTo({ top: 0, behavior: 'smooth' });
}, []);

  return (
    <>
      <Helmet>
                <title>
                    {props.metaTitle}
                </title>
                <meta property="og:title" content={props.metaTitle} />
                <meta property="og:description" content={props.metaDescription} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={ window.location } />
                <meta property="og:image" content="" />
            </Helmet>
      <InnerpageHeader/>
      <div className='servicesPageContent' >
        <div className='servicespageBanner all-services'>
          <h1 className='text-center my-0 py-5' >
            SERVICES
          </h1>
        </div>
        <div className='servicesListContainer servicesParent'>
          <div className='row g-0'>
            <div className='col-lg-3 col-sm-6'>
              <Link to="/services/branding">
                <div className='servicesListItem servicesListItem_1 text-center'>
                  <h2 className='m-0 text-white text-uppercase' >
                  unbranding
                  </h2>
                </div>
              </Link>
            </div>
            <div className='col-lg-3 col-sm-6'>
              <Link to="/services/advertising">
              <div className='servicesListItem servicesListItem_2 text-center'>
                <h2 className='m-0 text-white text-uppercase' >
                unadvertising
                </h2>
              </div>
              </Link>
            </div>
            <div className='col-lg-3 col-sm-6'>
              <Link to="/services/content-and-stories">
                <div className='servicesListItem servicesListItem_3 text-center'>
                  <h2 className='m-0 text-white' >
                  CONTENT<br /><span className='sub1' >& STORIES</span>
                  </h2>
                </div>
              </Link>
            </div>
            <div className='col-lg-3 col-sm-6'>
              <Link to="/services/digital-marketing">
                <div className='servicesListItem servicesListItem_4 text-center'>
                  <h2 className='m-0 text-white' >
                  DIGITAL<br/><span className='sub1' >MARKETING</span>
                  </h2>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='text-center py-5'>
          <Link to="/works" className=' btn-all-works'>
          See our works
          </Link>
      </div>
      <Footer />
    </>
  )
}

export default Services