import React from 'react';
import '../style/Footer.css';
import logoWhite from '../assets/images/swc_logo_white.png';
import thanathuLogo from '../assets/images/thanathu-logo.gif';
import iconFB from '../assets/icons/swc-fb.png';
import iconLK from '../assets/icons/swc-lk.png';
import iconIG from '../assets/icons/swc-ig.png';
import iconTW from '../assets/icons/swc-tw.png';
import { Link } from 'react-router-dom';

const Footer = (props) => {
  return (
    <>
     
    <div className='swcSiteFooterWrap'>
        <div className='swcFooter container-fluid'>
            <div className='row justify-content-center'>
                <div className='col-xxl-11 col-sm-12 col-11'> 
                    <div className='row gx-4 gy-5 justify-content-between pe-xl-4'>
                        <div className='col-xl-4'>
                            <div className='row gy-4 justify-content-center align-items-start'>
                                <div className="col-auto">
                                    <img src={ props.thanathuLogo ? thanathuLogo : logoWhite } alt="swc logo" width="170" className='swcFooterLogo' />
                                </div>
                                <div className='col-sm'>
                                    <p className='mb-2 text-white text-sm-start text-center footer-about' >
                                        SENSIBLY WEIRD COMPANY<br />
                                        Sensibly Weird LLP, 1st floor,<br />
                                        XIV/291 H, Suite 13F, A square,<br />
                                        Edathala PO, Kuzhivelipady,<br />
                                        Ernakulam, Kerala - 683536
                                    </p>
                                    <p className='mb-0 text-sm-start text-center' >
                                        <a href='mailto:weirdos@sensiblyweird.com' target="_blank" rel="noreferrer" >
                                            weirdos@sensiblyweird.com
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-auto text-sm-start text-center footerColFlex '>
                            <h4>
                                <Link to="/works">
                                    WORKS
                                </Link>
                            </h4>
                            <h5>
                                <Link to="/works">
                                    BRANDING
                                </Link>
                            </h5>
                            <h5>
                                <Link to="/works">
                                    PACKAGING
                                </Link>
                            </h5>
                            <h5>
                                <Link to="/works">
                                    COMMUNICATIONS
                                </Link>
                            </h5>
                            <h5>
                                <Link to="/works">
                                    MEDIA
                                </Link>
                            </h5>
                            <h5>
                                <Link to="/works">
                                    WEBSITE
                                </Link>
                            </h5>
                        </div>
                        <div className='col-sm-auto text-sm-start text-center footerColFlex '>
                            <h4>
                                <Link to='/services' >
                                    SERVICES
                                </Link>
                            </h4>
                            <h5>
                                <Link to='/services/branding/' >
                                UNBRANDING
                                </Link>
                                
                            </h5>
                            <h5>
                                <Link to='/services/advertising/' >
                                UNADVERTISING
                                </Link>
                            </h5>
                            <h5>
                                <Link to='/services/content-and-stories/' >
                                CONTENT & STORIES
                                </Link>
                            </h5>
                            <h5>
                                <Link to='/services/digital-marketing' >
                                DIGITAL MARKETING
                                </Link>
                            </h5>
                        </div>
                        <div className='col-sm-auto text-sm-start text-center footerColFlex '>
                            <h4>
                                <Link to='/thanathu' >
                                    THANATHU
                                </Link>
                            </h4>
                            <h4>
                                <Link to='/weirdos' >
                                    WEIRDOS
                                </Link>
                            </h4>
                            <h4>
                                <Link to='/thought-starters' >
                                    THOUGHT STARTERS
                                </Link>
                            </h4>
                            <h4>
                                <Link to='/blog' >
                                    BLOGS/ NEWS
                                </Link>
                            </h4>
                            <h4>
                                <Link to='/careers' >
                                    Careers
                                </Link>
                            </h4>
                            <h4>
                                <Link to='/contact-us' >
                                    Say Hello
                                </Link>
                            </h4>
                            
                        </div>
                        <div className='col-sm-auto text-sm-start text-center footerColFlex footerColConnect'>
                            <h4 className='mb-sm-1 mb-2' >
                                CONNECT
                            </h4>
                            <div className='socialLinks'>
                            <p className='mb-0 d-inline-block' >
                                <a href='https://www.linkedin.com/company/sensibly-weird-company/' title='Connect with us on Linkedin' target="_blank" rel="noreferrer" >
                                    <img src={iconLK} alt='LinkedIn' width='24' height='24' />
                                </a>
                            </p>
                            <p className='mb-0 d-inline-block' >
                                <a href='https://www.instagram.com/sensiblyweirdcompany/' title='Connect with us on Instagram' target="_blank" rel="noreferrer" >
                                <img src={iconIG} alt='LinkedIn' width='24' height='24' />
                                </a>
                            </p>
                            <p className='mb-0 d-inline-block' >
                                <a href='https://twitter.com/sensiblyweird1' title='Connect with us on Twitter' target="_blank" rel="noreferrer" >
                                <img src={iconTW} alt='LinkedIn' width='24' height='24' />
                                </a>
                            </p>
                            <p className='mb-0 d-inline-block' >
                                <a href='https://www.facebook.com/SensiblyWeirdCompany' title='Connect with us on Facebook' target="_blank" rel="noreferrer" >
                                <img src={iconFB} alt='LinkedIn' width='24' height='24' />
                                </a>
                            </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='swcCopyRight py-1'>
            <div className='row justify-content-center'>
                <div className='col-auto'>
                    <p className='m-0' >
                        <small>
                            © Sensibly Weird LLP
                        </small>
                    </p>
                </div>
            </div>
        </div>
    </div>
    </>
    
  )
}

export default Footer