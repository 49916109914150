import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import InnerpageHeader from '../../InnerpageHeader';
import Footer from '../../Footer';
import '../../../style/fonts.css';
import '../../../style/works.css';
import { Link } from 'react-router-dom';
import image01 from '../../../assets/images/works/communication/palmtree/image-01.jpg';
import image02 from '../../../assets/images/works/communication/palmtree/image-02.jpg';
import image03 from '../../../assets/images/works/communication/palmtree/image-03.jpg';
import image04 from '../../../assets/images/works/communication/palmtree/image-04.jpg';
import image05 from '../../../assets/images/works/communication/palmtree/image-05.jpg';
import image06 from '../../../assets/images/works/communication/palmtree/image-06.jpg';
import image07 from '../../../assets/images/works/communication/palmtree/image-07.jpg';
import image08 from '../../../assets/images/works/communication/palmtree/image-08.jpg';
import image09 from '../../../assets/images/works/communication/palmtree/image-09.jpg';
import image10 from '../../../assets/images/works/communication/palmtree/image-10.jpg';
import image11 from '../../../assets/images/works/communication/palmtree/image-11.jpg';
import image12 from '../../../assets/images/works/communication/palmtree/image-12.jpg';
import image13 from '../../../assets/images/works/communication/palmtree/image-13.jpg';
import WorkPagination from '../WorkPagination';
import CategoryBadge from '../CategoryBadge';

function Palmtree(props) {
    const [ accordion1, setAccordion1 ] = useState( [ false, false, false ] );

    useEffect(() => {
        document.body.removeAttribute('class');
        document.body.classList.add('inner-page', 'single-work-page');
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <>
            <Helmet>
                <title>
                    {props.metaTitle}
                </title>
                <meta property="og:title" content={props.metaTitle} />
                <meta property="og:description" content={props.metaDescription} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={ window.location } />
                <meta property="og:image" content="" />
            </Helmet>
            <InnerpageHeader/>
            <div className='single-work'>
                <div className='container pb-4'>
                    <div className='row justify-content-center'>
                        <div className='col-xxl-12 col-11'>
                            <div className='pageBreadcrumb'>
                                <h6 className='m-0' >
                                    <Link to='/'>Home</Link> / <Link to='/works'>Works</Link> / Comunication / Palm Tree
                                </h6>
                            </div>
                            <div className='singleWorkHead my-5 pb-5'>
                                <h1 className='singleWorkTitle text-uppercase mb-3'>
                                Palm Tree
                                </h1>
                                <p>
                                Palm Tree, a well-established specialty store in Kochi, prides itself on offering a diverse selection of imported dates, nuts, and dry fruits sourced from around the world. While Palm Tree enjoyed a loyal local customer base, the business recognized the need to enhance its branding and establish a strong social media presence. Sensibly Weird Company, an innovative advertising agency, stepped in to bring Palm Tree's vision to life.
                                </p>
                                <CategoryBadge badgeText={props.catBadgeText} badgeCircle={props.catBadgeCircle} badgeType="category" />
                            </div>
                            <div className='singleWorkContent ayurcentralGrid mb-5'>
                                <img src={image01} alt='ayurcentral digital' className='w-100' />
                                <img src={image02} alt='ayurcentral digital' className='w-100' />
                                <img src={image03} alt='ayurcentral digital' className='w-100' />
                                <img src={image04} alt='ayurcentral digital' className='w-100' />
                                <img src={image05} alt='ayurcentral digital' className='w-100' />
                                <img src={image06} alt='ayurcentral digital' className='w-100' />
                                <img src={image07} alt='ayurcentral digital' className='w-100' />
                                <img src={image08} alt='ayurcentral digital' className='w-100' />
                                <img src={image09} alt='ayurcentral digital' className='w-100' />
                                <img src={image10} alt='ayurcentral digital' className='w-100' />
                                <img src={image11} alt='ayurcentral digital' className='w-100' />
                                <img src={image12} alt='ayurcentral digital' className='w-100' />
                                <img src={image13} alt='ayurcentral digital' className='w-100' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='workApproach'>
                    <div className='workApproachBG'>
                        <div className='container'>
                            <div className='row justify-content-center'>
                                <div className='col-xxl-12 col-11'>
                                    <div className='row justify-content-lg-end'>
                                    <CategoryBadge badgeText={props.resultText} badgeCircle={props.resultCircle} badgeType="result" />
                                        <div className='col-auto'>
                                            <h2 className='text-white text-uppercase my-5 py-lg-4' >
                                                Our <br />Approach
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='workApproachAccordion'>
                        <div className='row g-0 justify-content-lg-start justify-content-center'>
                            <div className='col-lg-auto col-md-9 col-11'>
                                <div className={ accordion1[0] ? 'wAAContent wAAOpen' : 'wAAContent' } >
                                    <div className='wAAContentTrigger' onClick={ () => { setAccordion1( [ !accordion1[0], false, false ] ) } } ></div>
                                    <div className='wAAContentHead ms-lg-0 ms-md-5 ms-4 pb-lg-0 pb-3'>
                                        <h4>
                                            03.
                                        </h4>
                                        <h3>
                                            Activation
                                        </h3>
                                    </div>
                                    <div className='wAAContentBody'>
                                        <p className='m-0 px-md-5 p-4 py-5 pt-lg-5 pt-3' >
                                            To achieve our objectives, we devised a comprehensive content strategy centered around five engaging content buckets:<br/><br/>
                                            <b>Product Info</b>:<br/>
                                            We crafted enticing posts that showcased Palm Tree's exquisite offerings and the unparalleled value they provided to customers. These posts highlighted the origins, flavors, and stories behind the imported products, creating a sense of adventure and desire.<br/><br/>
                                            <b>Health Benefits</b>:<br/>
                                            Understanding the growing importance of health-conscious snacking, we positioned Palm Tree as a brand dedicated to promoting wellness. We regularly shared health-related facts and insights about the nutritional benefits of their products, establishing Palm Tree as a trusted destination for guilt-free indulgence.<br/><br/>
                                            <b>Nuts Over Nuts</b>:<br/>
                                            In this segment, we celebrated the vast variety of nuts available at Palm Tree. We curated captivating posts that delved into the unique characteristics, flavors, and benefits of different nut varieties. By educating and entertaining the audience, we fostered an appreciation for the world of nuts.<br/><br/>
                                            <b>Engagement</b>:<br/>
                                            To keep the target audience actively involved, we encourage user-generated content (UGC). Through exciting contests and challenges, we motivated customers to share their experiences, recipes, and photos related to Palm Tree's products. This user-centric approach strengthened the brand's connection with its loyal followers.<br/><br/>
                                            <b>Seasonal Campaigns and Giveaways</b>:<br/>
                                            To further expand Palm Tree's social media reach and attract new followers, we designed seasonal campaigns and captivating giveaways. These initiatives generated buzz, excitement, and increased brand visibility, ultimately driving more engagement and sales.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-auto col-md-9 col-11'>
                                <div className={ accordion1[1] ? 'wAAContent wAAOpen' : 'wAAContent' } >
                                    <div className='wAAContentTrigger' onClick={ () => { setAccordion1( [ false, !accordion1[1], false ] ) } } ></div>
                                    <div className='wAAContentHead ms-lg-0 ms-md-5 ms-4 pb-lg-0 pb-3'>
                                        <h4>
                                            02.
                                        </h4>   
                                        <h3>
                                        Insight and Strategy
                                        </h3>
                                    </div>
                                    <div className='wAAContentBody'>
                                        <p className='m-0 px-md-5 p-4 py-5 pt-lg-5 pt-3' >
                                        With our creative juices flowing, we pitched the idea of "Sourced From The World Over" to Palm Tree. This concept emphasized the store's remarkable assortment of globally sourced products and aimed to create intrigue and curiosity among potential customers.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-auto col-md-9 col-11'>
                                <div className={ accordion1[2] ? 'wAAContent wAAOpen' : 'wAAContent' } >
                                    <div className='wAAContentTrigger' onClick={ () => { setAccordion1( [ false, false, !accordion1[2] ] ) } } ></div>
                                    <div className='wAAContentHead ms-lg-0 ms-md-5 ms-4 pb-lg-0 pb-3'>
                                        <h4>
                                            01.
                                        </h4>
                                        <h3>
                                        Challenges
                                        </h3>
                                    </div>
                                    <div className='wAAContentBody'>
                                        <p className='m-0 px-md-5 p-4 py-5 pt-lg-5 pt-3' >
                                        Our mission was clear: to elevate Palm Tree's brand identity and create a robust social media strategy that would capture the attention of a wider audience. We aimed to highlight Palm Tree's unique selling proposition - the extensive range of edibles sourced from different corners of the globe.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='workResults py-5'>
                    <div className='container py-4'>
                        <div className='row justify-content-center'>
                            <div className='col-xxl-12 col-11'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <h2 className='text-white' >
                                            Result
                                        </h2>
                                    </div>
                                    <div className='col-lg-6'>
                                        <ul>
                                            <li>
                                                <p className='m-1 ps-2' >
                                                    Sensibly Weird Company and Palm Tree's combined efforts resulted in exceptional outcomes.
                                                </p>
                                            </li>
                                            <li>
                                                <p className='m-1 ps-2' >
                                                    Implementation of a content strategy and consistent delivery of captivating posts led to a flourishing social media presence for Palm Tree.
                                                </p>
                                            </li>
                                            <li>
                                                <p className='m-1 ps-2' >
                                                    Substantial increase in followers, likes, and comments reflected heightened brand awareness and customer engagement.
                                                </p>
                                            </li>
                                            <li>
                                                <p className='m-1 ps-2' >
                                                    Palm Tree transformed into a health-conscious and globally sourced specialty store, capturing the attention of a broader audience.
                                                </p>
                                            </li>
                                            <li>
                                                <p className='m-1 ps-2' >
                                                    The vibrant online community built around the brand contributed to increased footfall in their physical store and a significant boost in sales.
                                                </p>
                                            </li>
                                            <li>
                                                <p className='m-0 ps-2' >
                                                    The Instagram account had 1500 followers in July 2020 and grew to more than 7500 followers by March 2023.
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <WorkPagination current={props.workID} />
            </div>
            <Footer />
        </>
    )
}

export default Palmtree