import { useCallback, useEffect, useState } from 'react';
import thanathuImg from '../assets/images/thanathu_typo.png';
import logo from '../assets/images/swc_logo.png';
import workVideo from '../assets/videos/works-video.mp4';
import { useNavigate } from 'react-router-dom';

function Home() {

    const INITIAL_STATE = {
        columnOne : [ 'homeColumn', 'homeColumn1' ],
        columnOne_Inner_1: [ 'homeColumnInner', 'logoWrap', 'd-flex', 'align-items-center', 'justify-content-center' ],
        columnOne_Inner_2: [ 'homeColumnInner', 'thanathuWrap', 'd-flex', 'align-items-center', 'justify-content-center' ],
        columnTwo : [ 'homeColumn', 'homeColumn2' ],
        columnThree : [ 'homeColumn', 'homeColumn3' ],
        columnThree_Inner_1: [ 'homeColumnInner', 'weirdosWrap' ],
        columnThree_Inner_2: [ 'homeColumnInner', 'thoughtWrap', 'd-flex', 'align-items-center', 'justify-content-center' ],
        columnFour : [ 'homeColumn', 'homeColumn4' ],
        columnFour_Inner_1: [ 'homeColumnInner', 'servicesWrap', 'd-flex', 'align-items-center', 'justify-content-center' ],
        columnFour_Inner_2: [ 'homeColumnInner', 'contactWrap', 'd-flex', 'align-items-center', 'justify-content-center' ]
    }

    let [ columnStates, setColumnStates ] = useState(INITIAL_STATE);

    const resetState = useCallback(() => {
        setColumnStates(INITIAL_STATE);
    });

    useEffect(() => {
        if( document.body.classList.contains('inner-page') ) {
            document.body.classList.remove('inner-page', 'weirdos-page', 'services-page', 'works-page', 'thanathu-page', 'from-homepage' );
            document.body.classList.add('from-innerpage');
        }
        if( document.body.classList.contains('from-innerpage') ) {
            const elm = document.querySelectorAll('div');
            elm.forEach( (ele) => {
                ele.classList.remove( 'boxClose', 'boxOpen' )
            } )
            resetState();
            document.body.classList.remove('from-innerpage');
        }
    }, [resetState]);

    const navigate = useNavigate();

    const columnClickHandler = ( action ) => {
        document.body.classList.add('from-homepage');
        switch( action ) {
            case 'OPEN_THANATHU_PAGE' :
                setColumnStates({
                    ...columnStates,
                    columnOne : [ ...columnStates.columnOne, 'slideToOpen' ],
                    columnOne_Inner_1 : [ ...columnStates.columnOne_Inner_1, 'boxClose' ],
                    columnOne_Inner_2 : [ ...columnStates.columnOne_Inner_2, 'boxOpen' ],
                    columnTwo : [ ...columnStates.columnTwo, 'slideToRight' ],
                    columnThree : [ ...columnStates.columnThree, 'slideToRight' ],
                    columnFour : [ ...columnStates.columnFour, 'slideToRight' ]
                });
                navigate('/thanathu');
                break;
            case 'OPEN_WORKS_PAGE' :
                setColumnStates({
                    ...columnStates,
                    columnOne : [ ...columnStates.columnOne, 'slideToLeft' ],
                    columnTwo : [ ...columnStates.columnTwo, 'slideToOpen' ],
                    columnThree : [ ...columnStates.columnThree, 'slideToRight' ],
                    columnFour : [ ...columnStates.columnFour, 'slideToRight' ]
                });
                navigate('/works');
                break;
            case 'OPEN_WEIRDOS_PAGE' :
                setColumnStates({
                    ...columnStates,
                    columnOne : [ ...columnStates.columnOne, 'slideToLeft' ],
                    columnTwo : [ ...columnStates.columnTwo, 'slideToLeft' ],
                    columnThree : [ ...columnStates.columnThree, 'slideToOpen' ],
                    columnThree_Inner_1 : [ ...columnStates.columnThree_Inner_1, 'boxOpen' ],
                    columnThree_Inner_2 : [ ...columnStates.columnThree_Inner_2, 'boxClose' ],
                    columnFour : [ ...columnStates.columnFour, 'slideToRight' ]
                });
                navigate('/weirdos');
                break;
            case 'OPEN_THOUGHTS_PAGE' :
                setColumnStates({
                    ...columnStates,
                    columnOne : [ ...columnStates.columnOne, 'slideToLeft' ],
                    columnTwo : [ ...columnStates.columnTwo, 'slideToLeft' ],
                    columnThree : [ ...columnStates.columnThree, 'slideToOpen' ],
                    columnThree_Inner_1 : [ ...columnStates.columnThree_Inner_1, 'boxClose' ],
                    columnThree_Inner_2 : [ ...columnStates.columnThree_Inner_2, 'boxOpen' ],
                    columnFour : [ ...columnStates.columnFour, 'slideToRight' ]
                });
                navigate('/thought-starters');
                break;
            case 'OPEN_SERVICES_PAGE' :
                setColumnStates({
                    ...columnStates,
                    columnOne : [ ...columnStates.columnOne, 'slideToLeft' ],
                    columnTwo : [ ...columnStates.columnTwo, 'slideToLeft' ],
                    columnThree : [ ...columnStates.columnThree, 'slideToLeft' ],
                    columnFour : [ ...columnStates.columnFour, 'slideToOpen' ],
                    columnFour_Inner_1 : [ ...columnStates.columnFour_Inner_1, 'boxOpen' ],
                    columnFour_Inner_2 : [ ...columnStates.columnFour_Inner_2, 'boxClose' ],
                });
                navigate('/services');
                break;
            case 'OPEN_HELLO_PAGE' :
                setColumnStates({
                    ...columnStates,
                    columnOne : [ ...columnStates.columnOne, 'slideToLeft' ],
                    columnTwo : [ ...columnStates.columnTwo, 'slideToLeft' ],
                    columnThree : [ ...columnStates.columnThree, 'slideToLeft' ],
                    columnFour : [ ...columnStates.columnFour, 'slideToOpen' ],
                    columnFour_Inner_1 : [ ...columnStates.columnFour_Inner_1, 'boxClose' ],
                    columnFour_Inner_2 : [ ...columnStates.columnFour_Inner_2, 'boxOpen' ],
                });
                navigate('/contact-us');
                break;
            default: break
        }
    }

    return (
        <div className='siteHomeWrap' >
            <div className={ columnStates.columnOne.join(" ") } >
                <div className={ columnStates.columnOne_Inner_1.join(" ") } >
                    <img src={logo} width="254" height="335" alt="swc logo" />
                    <div className="swc_hamburger" data-bs-toggle="offcanvas" data-bs-target="#sidebarNavigation" aria-controls="sidebarNavigation" >
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                <div className={ columnStates.columnOne_Inner_2.join(" ") } onClick={() => columnClickHandler('OPEN_THANATHU_PAGE')} >
                    <img src={thanathuImg} width="272" height="272" alt="thanathu mnemonic" />
                </div>
            </div>
            <div className={ columnStates.columnTwo.join(" ") } >
                <div className='homeColumnInner works d-flex align-items-center justify-content-center' onClick={() => columnClickHandler('OPEN_WORKS_PAGE')} >
                    <div className='worksvideoBG'>
                        <video autoPlay muted loop id="myVideo">
                            <source src={workVideo} type="video/mp4" />
                        </video>
                    </div>
                    <h2 className="p-0 m-0 d-flex justify-content-center align-items-center">
                        <span>W</span>
                        <span className="w-100"></span>
                        <span>O</span>
                        <span>R</span>
                        <span className="w-100"></span>
                        <span>K</span>
                        <span>S</span>
                    </h2>
                </div>
            </div>
            <div className={ columnStates.columnThree.join(" ") } > 
                <div className={ columnStates.columnThree_Inner_1.join(" ") } onClick={() => columnClickHandler('OPEN_WEIRDOS_PAGE')} >
                    <h4>
                        WEIRDOS
                    </h4>
                </div>
                <div className={ columnStates.columnThree_Inner_2.join(" ") } onClick={() => columnClickHandler('OPEN_THOUGHTS_PAGE')} >
                    <h6 className='text-center m-0 p-0' >
                        THOUGHT<br />STARTERS
                    </h6>
                </div>
            </div>
            <div className={ columnStates.columnFour.join(" ") } >
                <div className={ columnStates.columnFour_Inner_1.join(" ") } onClick={() => columnClickHandler('OPEN_SERVICES_PAGE')}>
                    <h5 className='d-inline-block m-0 p-0' >
                        SERVICES
                    </h5>
                </div>
                <div className={ columnStates.columnFour_Inner_2.join(" ") } onClick={() => columnClickHandler('OPEN_HELLO_PAGE')}>
                    <h3 className='d-inline-block m-0 p-0' >
                        SAY<br />HELLO
                    </h3>
                </div>
            </div>
        </div>
    )
}

export default Home