import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import InnerpageHeader from '../../InnerpageHeader';
import Footer from '../../Footer';
import '../../../style/fonts.css';
import '../../../style/works.css';
import { Link } from 'react-router-dom';
import image01 from '../../../assets/images/works/communication/ayurcentral/campaign-ayurcentral-image01.png';
import image02 from '../../../assets/images/works/communication/ayurcentral/campaign-ayurcentral-image02.png';
import image03 from '../../../assets/images/works/communication/ayurcentral/campaign-ayurcentral-image03.png';
import image04 from '../../../assets/images/works/communication/ayurcentral/campaign-ayurcentral-image04.png';
import image05 from '../../../assets/images/works/communication/ayurcentral/campaign-ayurcentral-image05.png';
import image06 from '../../../assets/images/works/communication/ayurcentral/campaign-ayurcentral-image06.png';
import image07 from '../../../assets/images/works/communication/ayurcentral/campaign-ayurcentral-image07.png';
import image08 from '../../../assets/images/works/communication/ayurcentral/campaign-ayurcentral-image08.png';
import image09 from '../../../assets/images/works/communication/ayurcentral/campaign-ayurcentral-image09.png';
import image10 from '../../../assets/images/works/communication/ayurcentral/campaign-ayurcentral-image10.png';
import image11 from '../../../assets/images/works/communication/ayurcentral/campaign-ayurcentral-image11.png';
import image12 from '../../../assets/images/works/communication/ayurcentral/campaign-ayurcentral-image12.png';
import image13 from '../../../assets/images/works/communication/ayurcentral/campaign-ayurcentral-image13.png';
import image14 from '../../../assets/images/works/communication/ayurcentral/campaign-ayurcentral-image14.png';
import image15 from '../../../assets/images/works/communication/ayurcentral/campaign-ayurcentral-image15.png';
import image16 from '../../../assets/images/works/communication/ayurcentral/campaign-ayurcentral-image16.png';
import image17 from '../../../assets/images/works/communication/ayurcentral/campaign-ayurcentral-image17.png';
import image18 from '../../../assets/images/works/communication/ayurcentral/campaign-ayurcentral-image18.png';
import image19 from '../../../assets/images/works/communication/ayurcentral/campaign-ayurcentral-image19.png';
import image20 from '../../../assets/images/works/communication/ayurcentral/campaign-ayurcentral-image20.png';
import image21 from '../../../assets/images/works/communication/ayurcentral/campaign-ayurcentral-image21.png';
import image22 from '../../../assets/images/works/communication/ayurcentral/campaign-ayurcentral-image22.png';
import image23 from '../../../assets/images/works/communication/ayurcentral/campaign-ayurcentral-image23.png';
import WorkPagination from '../WorkPagination';
import CategoryBadge from '../CategoryBadge';

function Ayurcentral(props) {

    const [ accordion1, setAccordion1 ] = useState( [ false, false, false ] );

    useEffect(() => {
        document.body.removeAttribute('class');
        document.body.classList.add('inner-page', 'single-work-page');
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <>
            <Helmet>
                <title>
                    {props.metaTitle}
                </title>
                <meta property="og:title" content={props.metaTitle} />
                <meta property="og:description" content={props.metaDescription} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={ window.location } />
                <meta property="og:image" content="" />
            </Helmet>
            <InnerpageHeader/>
            <div className='single-work'>
                <div className='container pb-4'>
                    <div className='row justify-content-center'>
                        <div className='col-xxl-12 col-11'>
                            <div className='pageBreadcrumb'>
                                <h6 className='m-0' >
                                    <Link to='/'>Home</Link> / <Link to='/works'>Works</Link> / Comunication / AyurCentral
                                </h6>
                            </div>
                            <div className='singleWorkHead my-5'>
                                <h1 className='singleWorkTitle text-uppercase mb-3'>
                                Ayur<br className='d-sm-none' />central
                                </h1>
                                <p>
                                AyurCentral, a renowned Ayurvedic brand, faced the challenge of increasing footfall at its stores located across Karnataka. Recognizing the growing demand for a trusted Ayurvedic brand among a large group of people in the region, we devised a hyperlocal strategy that addressed customer pain points and conducted free health camps. This case study highlights how AyurCentral successfully implemented this approach, resulting in a significant 35% increase in footfall at their stores.
                                </p>
                                <CategoryBadge badgeText={props.catBadgeText} badgeCircle={props.catBadgeCircle} badgeType="category" />
                            </div>
                            <div className='workMeta row g-5 pb-5'>
                                <div className='col-lg-5 col-auto'>
                                    <h5 className='mb-0' >
                                    Services we have done 
                                    </h5>
                                    <h4 className='mb-0' >
                                    Social media creatives
                                    </h4>
                                </div>
                            </div>
                            <div className='singleWorkContent ayurcentralGrid mb-5'>
                                <img src={image01} alt='ayurcentral digital' className='w-100' />
                                <img src={image02} alt='ayurcentral digital' className='w-100' />
                                <img src={image03} alt='ayurcentral digital' className='w-100' />
                                <img src={image04} alt='ayurcentral digital' className='w-100' />
                                <img src={image05} alt='ayurcentral digital' className='w-100' />
                                <img src={image06} alt='ayurcentral digital' className='w-100' />
                                <div className='row g-1'>
                                    <div className='col-lg-3 col-6'>
                                        <img src={image07} alt='ayurcentral digital' className='w-100' />
                                    </div>
                                    <div className='col-lg-3 col-6'>
                                        <img src={image08} alt='ayurcentral digital' className='w-100' />
                                    </div>
                                    <div className='col-lg-3 col-6'>
                                        <img src={image09} alt='ayurcentral digital' className='w-100' />
                                    </div>
                                    <div className='col-lg-3 col-6'>
                                        <img src={image10} alt='ayurcentral digital' className='w-100' />
                                    </div>
                                    <div className='col-lg-3 col-6'>
                                        <img src={image11} alt='ayurcentral digital' className='w-100' />
                                    </div>
                                    <div className='col-lg-3 col-6'>
                                        <img src={image12} alt='ayurcentral digital' className='w-100' />
                                    </div>
                                    <div className='col-lg-3 col-6'>
                                        <img src={image13} alt='ayurcentral digital' className='w-100' />
                                    </div>
                                    <div className='col-lg-3 col-6'>
                                        <img src={image14} alt='ayurcentral digital' className='w-100' />
                                    </div>
                                    <div className='col-lg-3 col-6'>
                                        <img src={image15} alt='ayurcentral digital' className='w-100' />
                                    </div>
                                    <div className='col-lg-3 col-6'>
                                        <img src={image16} alt='ayurcentral digital' className='w-100' />
                                    </div>
                                    <div className='col-lg-3 col-6'>
                                        <img src={image17} alt='ayurcentral digital' className='w-100' />
                                    </div>
                                    <div className='col-lg-3 col-6'>
                                        <img src={image18} alt='ayurcentral digital' className='w-100' />
                                    </div>
                                    <div className='col-lg-3 col-6'>
                                        <img src={image19} alt='ayurcentral digital' className='w-100' />
                                    </div>
                                    <div className='col-lg-3 col-6'>
                                        <img src={image20} alt='ayurcentral digital' className='w-100' />
                                    </div>
                                    <div className='col-lg-3 col-6'>
                                        <img src={image21} alt='ayurcentral digital' className='w-100' />
                                    </div>
                                    <div className='col-lg-3 col-6'>
                                        <img src={image22} alt='ayurcentral digital' className='w-100' />
                                    </div>
                                </div>
                                <img src={image23} alt='ayurcentral digital' className='w-100' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='workApproach'>
                    <div className='workApproachBG'>
                        <div className='container'>
                            <div className='row justify-content-center'>
                                <div className='col-xxl-12 col-11'>
                                    <div className='row justify-content-lg-end'>
                                    <CategoryBadge badgeText={props.resultText} badgeCircle={props.resultCircle} badgeType="result" />
                                        <div className='col-auto'>
                                            <h2 className='text-white text-uppercase my-5 py-lg-4' >
                                                Our <br />Approach
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='workApproachAccordion'>
                        <div className='row g-0 justify-content-lg-start justify-content-center'>
                            <div className='col-lg-auto col-md-9 col-11'>
                                <div className={ accordion1[0] ? 'wAAContent wAAOpen' : 'wAAContent' } >
                                    <div className='wAAContentTrigger' onClick={ () => { setAccordion1( [ !accordion1[0], false, false ] ) } } ></div>
                                    <div className='wAAContentHead ms-lg-0 ms-md-5 ms-4 pb-lg-0 pb-3'>
                                        <h4>
                                            01.
                                        </h4>
                                        <h3>
                                            Activation
                                        </h3>
                                    </div>
                                    <div className='wAAContentBody'>
                                        <p className='m-0 px-md-5 p-4 py-5 pt-lg-5 pt-3' >
                                        The ads concentrated on the 10 regions where these stores were located, tailoring campaigns, content, and promotions to resonate with the local culture, preferences, and language. This personalised approach aimed to foster a sense of familiarity and trust among the target audience. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-auto col-md-9 col-11'>
                                <div className={ accordion1[1] ? 'wAAContent wAAOpen' : 'wAAContent' } >
                                    <div className='wAAContentTrigger' onClick={ () => { setAccordion1( [ false, !accordion1[1], false ] ) } } ></div>
                                    <div className='wAAContentHead ms-lg-0 ms-md-5 ms-4 pb-lg-0 pb-3'>
                                        <h4>
                                            02.
                                        </h4>   
                                        <h3>
                                        Insight and Strategy
                                        </h3>
                                    </div>
                                    <div className='wAAContentBody'>
                                        <p className='m-0 px-md-5 p-4 py-5 pt-lg-5 pt-3' >
                                        Extensive digital marketing research revealed that a substantial group of people in Karnataka was actively seeking a trusted Ayurvedic brand to fulfill their medical requirements. AyurCentral recognized the potential of becoming their preferred choice by offering authentic Ayurvedic products and services that surpassed the convenience and reliability provided by local stores. As a part of strategy, we attacked pin codes and targeted 10 stores in Karnataka. <br /><br/>
                                        AyurCentral's strategy focused on implementing a hyperlocal approach that would effectively engage the target audience and address their pain points. To establish credibility and showcase its commitment to customer well-being, AyurCentral organized free health camps comprising consultations and health checkups in various locations across Karnataka.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-auto col-md-9 col-11'>
                                <div className={ accordion1[2] ? 'wAAContent wAAOpen' : 'wAAContent' } >
                                    <div className='wAAContentTrigger' onClick={ () => { setAccordion1( [ false, false, !accordion1[2] ] ) } } ></div>
                                    <div className='wAAContentHead ms-lg-0 ms-md-5 ms-4 pb-lg-0 pb-3'>
                                        <h4>
                                            03.
                                        </h4>
                                        <h3>
                                        Challenges
                                        </h3>
                                    </div>
                                    <div className='wAAContentBody'>
                                        <p className='m-0 px-md-5 p-4 py-5 pt-lg-5 pt-3' >
                                        AyurCentral aimed to attract more visitors to their stores in Karnataka to become the preferred Ayurvedic brand over local stores. We identified a sizable market of individuals seeking reliable Ayurvedic products and services for their medical needs, indicating a clear opportunity to capture the target audience's attention and loyalty.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='workResults py-5'>
                    <div className='container py-4'>
                        <div className='row justify-content-center'>
                            <div className='col-xxl-12 col-11'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <h2 className='text-white' >
                                            Result
                                        </h2>
                                    </div>
                                    <div className='col-lg-6'>
                                        <p className='m-0' >
                                            The combined impact of the hyperlocal approach and free health camps led to a remarkable 35% increase in footfall at 10 AyurCentral stores in Karnataka where demand was high. More than 750 consultations clocked, and significant upselling at stores. The influx of interested visitors translated into higher engagement, increased in-store sales, and a stronger customer base.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <WorkPagination current={props.workID} />
            </div>
            <Footer />
        </>
    )
}

export default Ayurcentral