import React, { useState } from 'react';
import {Link} from 'react-router-dom';

function Navigation() {

    const linkClickHandler = () => {
        const myOffcanvas = document.getElementById('sidebarNavigatio')
        myOffcanvas.hide();
    }

    const [ showDigital, setShowDigital ] = useState('false');
    const [ showServices, setShowServices ] = useState('false');

  return (
    <>
        <div className="offcanvas text-bg-dark offcanvas-start sidebarNav" tabIndex="-1" id="sidebarNavigation" aria-labelledby="offcanvasLabel">
            <div className="offcanvas-header d-flex justify-content-end pb-0">
                <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body pt-0">
                <ul>
                    <li>
                        <Link to="/" onClick={linkClickHandler} >
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link to="/thanathu" onClick={linkClickHandler} >
                            Thanathu
                        </Link>
                    </li>
                    <li>
                        <Link to="/works" onClick={linkClickHandler} >
                            Works
                        </Link>
                    </li>
                    <li>
                        <Link to="/weirdos" onClick={linkClickHandler} >
                            Weirdos
                        </Link>
                    </li>
                    <li>
                        <span className='hasSubmenu' onClick={() => {
                            setShowDigital( 'false' );
                            setShowServices( !showServices );
                        } } >
                            Services
                        </span>
                        <div className={ !showServices? 'submenu' : 'submenu hide' }>
                            <ul className='menuLayer01' >
                                <li>
                                    <Link to="/services/branding" onClick={linkClickHandler} >
                                        Unbranding
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/services/advertising" onClick={linkClickHandler} >
                                        Unadvertising
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/services/content-and-stories" onClick={linkClickHandler} >
                                        Content & Stories
                                    </Link>
                                </li>
                                <li>
                                    <span className='hasSubmenu' onClick={() => {
                                        setShowDigital( !showDigital );
                                    } } >
                                        Digital Marketing
                                    </span>
                                    <div className={ !showDigital? 'submenu' : 'submenu hide' }>
                                        <ul className='menuLayer02' >
                                            <li>
                                                <Link to="/services/digital-marketing/lead-generation" onClick={linkClickHandler} >
                                                Lead Generation
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/services/digital-marketing/seo" onClick={linkClickHandler} >
                                                    SEO
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/services/digital-marketing/ppc" onClick={linkClickHandler} >
                                                    PPC
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <Link to="/thought-starters" onClick={linkClickHandler} >
                            Thought Starters
                        </Link>
                    </li>
                    <li>
                        <Link to="/blog" onClick={linkClickHandler} >
                            Blogs/News
                        </Link>
                    </li>
                    <li>
                        <Link to="/careers" onClick={linkClickHandler} >
                            Careers
                        </Link>
                    </li>
                    <li>
                        <Link to="/contact-us" onClick={linkClickHandler} >
                            Say Hello
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    </>
  )
}

export default Navigation