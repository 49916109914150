import React, { useEffect } from 'react';
import InnerpageHeader from '../InnerpageHeader';
import Footer from '../Footer';
import '../../style/digital.css';
import { Helmet } from 'react-helmet';
import image01 from '../../assets/images/contentandstories-image01.jpg';

function ContentAndStories(props) {
    useEffect(() => {
        document.body.removeAttribute('class');
        document.body.classList.add('inner-page', 'content-stories-page');
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <>
            <Helmet>
                <title>
                    {props.metaTitle}
                </title>
                <meta property="og:title" content={props.metaTitle} />
                <meta property="og:description" content={props.metaDescription} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={ window.location } />
                <meta property="og:image" content="" />
            </Helmet>   
            <InnerpageHeader/>
            <div className='servicesPageContent digitalPageContent category' >
                <div className='servicespageBanner'>
                    <h1 className='text-center my-0 py-5 text-white text-uppercase'>
                    Content & Stories
                    </h1>
                </div>
                <div className='py-5'>
                    <div className='container py-4'>
                        <div className='row justify-content-center'>
                            <div className='col-lg-11 col-sm-12 col-11'>
                                <p className='m-0 pb-4' >
                                Stories are what we are all made up of. We are the sum total of our experiences, our life, our learnings, and unlearning of the good, bad & the ugly. The best of these stories come in the highly popularized formats of yesteryear called 'Life is like that', 'Laughter is the best medicine', 'Life Lesson', and 'Food for thought', Points to Ponder, etc. In these anecdotes lie the innermost truth of the what & the why and a weird relevance in contemporary times.
                                </p>
                                <img src={image01} alt='content ans stories illustration' className='w-100' />
                                <p className='cs-note text-uppercase py-4 px-lg-5 px-4 text-center mt-5' >
                                *UnThinking - To unthink the obvious and hence make way for your mind to think about the 'not-so-obvious' - in other words: Ruling out scenarios to increase the strike rate for success!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default ContentAndStories