import React, { useEffect } from 'react'

export const Blogs = () => {

    useEffect(() => {
        {window.location.href = 'https://sensiblyweird.com/blog/'}
    });

  return (
    <div></div>
  )
}
