import React, { useEffect } from 'react';
import InnerpageHeader from '../InnerpageHeader';
import Footer from '../Footer';
import '../../style/digital.css';
import { Helmet } from 'react-helmet';
import image01 from '../../assets/images/unbranding-image-01.jpg';
import image02 from '../../assets/images/unbranding-image-02.jpg';

function Unbranding( props ) {
    useEffect(() => {
        document.body.removeAttribute('class');
        document.body.classList.add('inner-page', 'unbranding-page');
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <>
        <Helmet>
                <title>
                    {props.metaTitle}
                </title>
                <meta property="og:title" content={props.metaTitle} />
                <meta property="og:description" content={props.metaDescription} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={ window.location } />
                <meta property="og:image" content="" />
            </Helmet>
            <InnerpageHeader/>
            <div className='servicesPageContent digitalPageContent category' >
                <div className='servicespageBanner text-center'>
                    <h1 className='text-center my-0 py-5 text-white text-uppercase un-text'>
                    <img src={ props.brandBadge } alt='unpackaging badge' />
                    branding
                    </h1>
                </div>
                <div className='py-5'>
                    <div className='container py-4'>
                        <div className='row justify-content-center'>
                            <div className='col-lg-11 col-sm-12 col-11 d-flex flex-column align-items-center'>
                                <p className='m-0 pb-4' >
                                Feeling like your brand is a snooze fest in a sea of same-old, same-old? Ready to break free from the chains of conformity? At Sensibly Weird, we're all about unbranding. We're not afraid to challenge the norm and create something truly whacky and wonderful for your brand. Our quirky approach to branding guarantees that your brand will stand out and catch the attention of your target segment. 
                                </p>
                                <img src={image01} alt='unbranding illustration' className='w-100' />
                                <p className='cs-note text-uppercase py-4 px-lg-5 px-4 text-center mt-5 alt01 mx-auto mb-4 d-inline-block' >
                                Say bye-bye to boring and hello to the sensibly weird, <br></br>where all the magic happens!
                                </p>
                                <p className='m-0 pb-2 pt-4' >
                                In the constant churn of consumer needs and desires, brands need to continuously reinvent themselves and remain subliminally relevant in people's minds. In the future, brands need not speak out loud; instead, they become intrinsically part of people's lives for more than one good reason. If we, as brand creators, have achieved a panacea, then we put the brand into the unbranding mode because organic growth and sustenance are led by stories, which essentially become unbranded messaging.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='servicespageBanner text-center secondary'>
                    <h1 className='text-center my-0 py-5 text-white text-uppercase un-text'>
                        <img src={ props.packageBadge } alt='unpackaging badge' />
                        packaging
                    </h1>
                </div>
                <div className='py-5'>
                    <div className='container py-4'>
                        <div className='row justify-content-center'>
                            <div className='col-lg-11 col-sm-12 col-11 d-flex flex-column align-items-center'>
                                <p className='m-0 pb-4' >
                                Unpackaging is born out of our relentless pursuit to break free from the restrictions imposed by conventional packaging rules. Unpackaging is the embodiment of our commitment to unleash creative possibilities and transform the way brands captivate consumers. By embracing this innovative concept, we open doors to a world where packaging becomes a powerful tool for differentiation, leaving a lasting impact on your target audience.
                                </p>
                                <img src={image02} alt='unpackaging illustration' className='img-fluid' />
                                <p className='cs-note text-uppercase py-4 px-lg-5 px-4 text-center mt-5 mb-4 alt02' >
                                In today's fast-paced marketplace,  making a memorable impact is crucial.Unpackaging is the key to achieving this distinction, as it engages customers, builds brand recognition, and drives sales. 
                                </p>
                                <p className='m-0 pb-2 pt-4' >
                                Shelf visibility is at the core of our Unpackaging philosophy. We understand the significance of ensuring your brand's products are seen, noticed, and chosen by discerning customers. By studying market dynamics and consumer preferences, we curate packaging that immediately captivates, allowing your products to shine brightly on retail shelves.
                                </p>
                                <p className='m-0 pb-2 pt-4' >
                                Unpackaging goes beyond conventional expectations, challenging norms and reimagining possibilities. We infuse creativity and innovation into every design element, ensuring your packaging becomes a powerful tool for brand recognition, recall, and differentiation.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Unbranding