import React, { useEffect } from 'react';
import InnerpageHeader from './InnerpageHeader';
import '../style/fonts.css';
import '../style/thanathu.css';
import Footer from './Footer';
import thanathuLogo from '../assets/images/thanathu-logo.gif';
import thanathuImage01 from '../assets/images/thanathuPageImage-01.png';
import thanathuImage02 from '../assets/images/thanathuPageImage-02.png';
import thanathuImage10 from '../assets/images/thanathuImage10.png';
import thanathuIcon01 from '../assets/icons/home-icon.png';
import thanathuIcon02 from '../assets/icons/phone-icon.png';
import thanathuIcon03 from '../assets/icons/desktop-icon.png';
import { Helmet } from 'react-helmet';

function Thanathu(props) {

    useEffect(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
        document.body.classList.add('inner-page', 'thanathu-page');
    }, []);

    return (
        <>
            <Helmet>
                <title>
                    {props.metaTitle}
                </title>
                <meta property="og:title" content={props.metaTitle} />
                <meta property="og:description" content={props.metaDescription} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={ window.location } />
                <meta property="og:image" content="" />
            </Helmet>
            <InnerpageHeader whitePage="false" />
            <div className='thanathuPageWrap'>
              <div className='thanathuHeader'>
                <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-lg-4 col-sm-6 col-8 pb-5' >
                      <img src={thanathuLogo} alt='thanathu text animation' className='img-fluid' />
                    </div>
                  </div>
                </div>
              </div>
              <div className='thanathuContent text-white rooted'>
                <div className='container'>
                  <div className='row justify-content-center'>
                    <div className='col-lg-11 col-sm-12 col-11'>
                      <h2>
                        <span>R</span><span>O</span><span>O</span><span>T</span><span>E</span><span>D</span>
                      </h2>
                      <p className='pb-3 mb-0' >
                      Rooted to capture the essence of local. Globalisation hascreated openness yet it tends to erase local specificities which extends from languages, ideas, foods, ways of being and seeing, to design and the way we approach design.
                      </p>
                      <img src={thanathuImage01} alt='rooted' className='image01' />
                    </div>
                  </div>
                </div>
              </div>
              <div className='thanathuContent text-white designBetter'>
                <div className='container'>
                  <div className='row justify-content-center'>
                    <div className='col-lg-11 col-sm-12 col-11'>
                      <h4>
                        We want to  
                      </h4>
                      <h2>
                        design better
                      </h2>
                      <p className='mb-0' >
                        The flattening effects of global capitalism have made “difference” merely an item to be consumed. For too long the everyday designs that we interact with have been too monotonous, too similar, too familiar. We want to change that.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='thanathuContent text-white cultures'>
                <div className='container'>
                  <div className='row justify-content-center'>
                    <div className='col-lg-11 col-sm-12 col-11'>
                      <div className='row align-items-center'>
                        <div className='col-3'>
                          <img src={thanathuImage02} alt='cultures' className=' image02' />
                        </div>
                        <div className='col'>
                          <p className='mb-0 ps-xl-3 ps-lg-0 ps-md-3' >
                          And we can do that only when we have a deeper connection to our roots, a better relationship with the local (which will inform the global). <br />It is not just about design but about the lives we (you and us) lead which makes it impossible to disentangle from the cultures we interact with and the spaces that inhabit it.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='thanathuContent text-white whoWeAre py-5'>
                <div className='container py-4'>
                  <div className='row justify-content-center'>
                    <div className='col-lg-11 col-sm-12 col-11 whoWeAre-lg'>
                          <div className='imageWrap'></div>
                          <h3 className='mb-4' >
                          <span>civic</span> Responsiveness
                          </h3>
                          <p className='mb-0' >
                          We believe this is the time for heightened civic responsiveness, focusing on design.
                          </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='thanathuContent text-white whoWeAre pt-5 pb-0'>
                <div className='container'>
                  <div className='row justify-content-center'>
                    <div className='col-lg-11 col-sm-12 col-11'>
                      <div className='row gx-5 justify-content-center'>
                        <div className='col-auto'>
                          <h5 className='text-center' >
                          Thanthu is a concept of Sensibly Weird Company
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='thanathuContent text-white weAre'>
                <div className='container'>
                  <div className='row justify-content-center'>
                    <div className='col-lg-11 col-sm-12 col-11'>
                      <img src={thanathuImage10} alt='We are' className='w-100' />
                      <h2 className='text-center mb-0 pt-4 mt-3' >
                        WE ARE free-spirits.<br />
                        WE ARE curious.<br />
                        WE ARE excited.<br />
                        WE get carried away<br />
                        We are what we are
                      </h2>
                      <h4 className='mb-0 text-sm-end text-center' >
                        That's it
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className='thanathuContent bg-white contact d-none'>
                <div className='container'>
                  <div className='row justify-content-center'>
                    <div className='col-lg-11 col-sm-12 col-11'>
                      <div className='row gy-5 gx-4'>
                        <div className='col-12 text-center'>
                          <h3 className='mb-1' >
                            CONTACT US
                          </h3>
                          <h4 className='mb-0' >
                            WE LOVE TALKING
                          </h4>
                        </div>
                        <div className='col-md-4 text-center'>
                          <img src={thanathuIcon03} alt='mail' width='24' />
                          <p className='pt-3' >
                            <a href='weirdos@sensiblyweird.com' target='_blank' rel="noreferrer" title='Visit our website' >
                              weirdos@sensiblyweird.com
                            </a>
                          </p>
                        </div>
                        <div className='col-md-4 text-center'>
                          <img src={thanathuIcon01} alt='address' width='24' />
                          <p className='pt-3' >
                          Sensibly Weird Company,<br />1st Floor, XIV/291 M, Suite 9D,<br />A Square, Edathala P.O, Kuzhivelipady,<br />Ernakulam , Kerala - 6835361
                          </p>
                        </div>
                        <div className='col-md-4 text-center'>
                          <img src={thanathuIcon02} alt='phone' width='24' />
                          <p className='mb-1 pt-3' >
                            <a href='tel:+918056221600' target='_blank' rel="noreferrer" title='Call us @ +91 80562 21600' >
                            +91 80562 21600
                            </a>
                          </p>
                          <p>
                            <a href='tel:+918056221600' target='_blank' rel="noreferrer" title='Call us @ +91 80562 21600' >
                            +91 80562 21600
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer thanathuLogo={true} />
            </div>
        </> 
    )
}

export default Thanathu