import React, { useEffect } from 'react';
import InnerpageHeader from '../InnerpageHeader';
import Footer from '../Footer';
import '../../style/digital.css';
import { Helmet } from 'react-helmet';

function Ppc(props) {

    useEffect(() => {
        document.body.removeAttribute('class');
        document.body.classList.add('inner-page', 'digital-ppc-page');
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <>
        <Helmet>
                <title>
                    {props.metaTitle}
                </title>
                <meta property="og:title" content={props.metaTitle} />
                <meta property="og:description" content={props.metaDescription} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={ window.location } />
                <meta property="og:image" content="" />
            </Helmet>
            <InnerpageHeader/>
            <div className='servicesPageContent digitalPageContent category' >
                <div className='servicespageBanner'>
                    <h1 className='text-center my-0 py-5 text-white text-uppercase'>
                    Pay per Clicks/ <br/>Performance Marketing
                    </h1>
                </div>
                <div className='py-5'>
                    <div className='container py-4'>
                        <div className='row justify-content-center'>
                            <div className='col-lg-9 col-sm-12 col-11'>
                                <p className='m-0' >
                                At Sensibly Weird Company, we believe in providing tailored pay per click (PPC) advertising solutions that focus on your bigger picture business goals. Our team of experts works closely with you to develop a PPC advertising strategy that identifies where your most valuable customers are searching or browsing, ensuring that your product and unique selling proposition (USP) are seen by the right people.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='lgServices lgServices-bxs pb-5'>
                    <div className='container mb-4'>
                        <div className='row g-md-0 g-3 justify-content-center'>
                            <div className='col-lg-9'>
                                <div className='px-5 py-5 igbg-1'>
                                    <h4 className='text-white text-center text-uppercase mb-3' >
                                    Access to the Big Three: Direct access <br className='d-lg-inline d-none' />to teams at Google, LinkedIn, and Facebook
                                    </h4>
                                    <p className='m-0 text-dark' >
                                    We understand the importance of utilizing the top advertising platforms to get the best results for your business. Our team has access to the big three advertising platform’s marketing team: Google Ads, LinkedIn Ads, and Meta Ads. This ensures that your advertising efforts reach the maximum potential audience, generating more qualified traffic and increasing conversion rates.
                                    </p>
                                </div>
                            </div>
                            <div className='col-lg-9'>
                                <div className='igbg-2 px-5 py-5 igd'>
                                    <h4 className='text-white text-center text-uppercase mb-3' >
                                    AB Testing and Conversion Tracking
                                    </h4>
                                    <p className='m-0 text-dark' >
                                    To ensure that your advertising campaigns are effective, we conduct AB testing to determine what works best for your business. Our team constantly monitors and analyzes your campaign data to optimize your ads for maximum performance. We also implement conversion tracking to measure the success of your campaigns in terms of lead generation and sales.
                                    </p>
                                </div>
                            </div>
                            <div className='col-lg-9'>
                                <div className='igbg-9 px-5 py-5 igd'>
                                    <h4 className='text-white text-center text-uppercase mb-3' >
                                    Analytics Integration
                                    </h4>
                                    <p className='m-0 text-dark' >
                                    We have the latest technology to measure and optimize your account-level performance marketing. Our team integrates Google Analytics with your PPC campaigns to provide in-depth data and insights into your advertising performance. This helps us identify the strengths and weaknesses of your campaigns, allowing us to make data-driven decisions to improve performance.
                                    </p>
                                </div>
                            </div>
                            <div className='col-lg-9'>
                                <div className='igbg-7 px-5 py-5 igd'>
                                    <h4 className='text-white text-center text-uppercase mb-3' >
                                    Shopping Ads and Search Ads
                                    </h4>
                                    <p className='m-0 text-dark' >
                                    We offer a range of PPC advertising options, including shopping ads and search ads. Our team works closely with you to determine the best strategy to target your audience based on your products or services. Our shopping ads help drive traffic to your online store and increase sales, while our search ads ensure that your business appears at the top of search engine results pages (SERPs) when your potential customers are looking for what you offer.
                                    </p>
                                </div>
                            </div>
                            <div className='col-lg-9'>
                                <div className='igbg-10 px-5 py-5 igd'>
                                    <h4 className='text-white text-center text-uppercase mb-3' >
                                    DV 360 and Programmatic Planning
                                    </h4>
                                    <p className='m-0 text-dark' >
                                    We also provide DV 360 and programmatic planning services. With our DV360 services, we help you reach your target audience across all devices and platforms, including desktop, mobile, and tablet. Our programmatic planning services use data-driven insights to determine the best advertising strategy for your business, ensuring that your advertising campaigns are reaching the right people at the right time.<br/><br/>
                                    In conclusion, at Sensibly Weird Company, we provide cost-effective and tailored PPC advertising solutions that can help increase the trust for your brand, earn higher revenues, and reach wider audiences. Contact us today to learn more about how we can help you achieve your advertising goals.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Ppc