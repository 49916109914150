import React, { useEffect, useRef, useState } from 'react';
import Footer from './Footer.js';
import '../style/Weirdos.css';
import weirdosImage02 from '../assets/images/weirdos-image-1.jpg';
import weirdosImage03 from '../assets/images/weirdos-image-2.png';
import weirdosImage04 from '../assets/images/weirdos-image-3.png';
import weirdosImage05 from '../assets/images/weirdos-image-4.png';
import InnerpageHeader from './InnerpageHeader.js';
import { Helmet } from 'react-helmet';
import { Formik, Form, Field } from 'formik';
import axios from 'axios';

function Weirdos(props) {

  const [ formMessage, setFormMessage ] = useState('');
  const formArea = useRef();

  const initialValues = {
    cf_mail: ''
  };

  const onSubmit = ( values, { resetForm } ) => {
    setFormMessage('pending')
    axios.post( 'https://sensiblyweird.com/phpmailer/subscription_mail.php', values ).then( response => {
        setFormMessage( response.data.status )
        console.log( response.data.data )
    } )
  }

  const validate = values => {
    let errors = {}
    if( !values.cf_mail ) {
        errors.cf_mail = 'Please provide an email'
    } else if( !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.cf_mail)) ) {
        errors.cf_mail = 'Please provide a valid email'
    }
    return errors
}

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
      document.body.classList.add('inner-page', 'weirdos-page');
  }, []);

  return (
    <>
       <Helmet>
          <title>
              {props.metaTitle}
          </title>
          <meta property="og:title" content={props.metaTitle} />
          <meta property="og:description" content={props.metaDescription} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={ window.location } />
          <meta property="og:image" content="" />
      </Helmet>
      <InnerpageHeader/>
      <div className='weirdosPage' >
      <div className='weirdosSection2'>
        <div className='row'>
          <div className='col-sm-6 weirdosSection2Left p-2 text-center'>
            <img src={weirdosImage02} width="100" height="100" alt='weirdosImage02' />
          </div>
          <div className='col-sm-6 weirdosSection2Right p-2 text-center d-flex justify-content-center align-items-center'>
            <h1 className='text-white m-0 text-start d-inline-block' >
              WE ARE<br />WHO<br />WE ARE
            </h1>
          </div>
        </div>
      </div>
      <img src={weirdosImage05} className='w-100' alt='team' />
      <div className='weirdosSection3 text-center pt-5 mt-3'>
        <div className='container-fluid'>
          <div className='row justify-content-center'>
            <div className='col-xl-10'>
              <img src={weirdosImage03} width="100" heigh="80" alt='weirdosImage03' className='w-100' />
              <h5 className='pt-5 mb-0 mt-2' >
                We create new intersections between culture, design, brand and <br />technology to bring new perspectives and relevance in the “Circle of Life”
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div className='weirdosSection4 text-center pt-5 mt-3'>
        <div className='container-fluid'>
          <div className='row justify-content-center'>
            <div className='col-lg-5'>
              <h2 className='mb-4' >
                Circle of Life
              </h2>
              <img src={weirdosImage04} width="500" height="500" alt='weirdosImage04' className='w-100' />
            </div>
          </div>
        </div>
      </div>
      <div className='weirdosSection5 pt-5 pb-3 mt-3'>
        <div className='container-fluid px-3'>
          <div className='row g-3'>
            <div className='col-lg-4'>
              <div className='weirdosSection5Inner py-5'>
                <h3>
                  WHAT?
                </h3>
                <p className='m-0' >
                  <span>Discovering the deeper truth</span>: <br />We believe in uncovering the fundamental essence that underlies any brand story. By understanding the core consumer benefit we can create a more real and impactful narrative.
                </p>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='weirdosSection5Inner py-5'>
                <h3>
                  WHY?
                </h3>
                <p className='m-0' >
                  <span>Consumer journey</span>: <br />We conduct thorough market studies that are rooted in real-world insights. Our approach is based on understanding the consumer's perspective, and we continuously validate our findings to ensure accuracy. The question we ask is 'Why & how  do they do what they do?
                </p>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='weirdosSection5Inner py-5'>
                <h3>
                  HOW?
                </h3>
                <p className='m-0' >
                  <span>Go to market</span>: <br />We recognize that great ideas are only as good as their implementation. This section focuses on the last mile of our communication plan, where we go the extra mile to ensure flawless execution.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='subscription py-5 my-5 ' >
          <div className='container-xxl'>
              <div className='row justify-content-center'>
                {
                  formMessage !== '' ? (
                    <div className='col-xl-11 text-center'>
                        <p className='formResponse text-center m-0' >
                          {
                              formMessage === 'pending' ? (
                                  <>
                                      Please wait...
                                  </>
                              ) : (
                                  <>
                                      {
                                          formMessage === 'success' ? (
                                              <>
                                                  <span>Thank you!</span><br/> 
                                                  Have a great day.
                                              </>
                                          ) : (
                                              <>
                                                  <span className='text-danger' >Whoops, <br/>something went wrong!</span><br/> 
                                                  Please try again later.
                                              </>
                                          )
                                      }
                                  </>
                              )
                          }
                      </p>
                    </div>
                  ) : (
                    <div className='col-xl-9' ref={formArea} >
                        <Formik
                          initialValues={initialValues}
                          onSubmit={onSubmit}
                          validate={validate}
                        >
                          <Form>
                            <div className='row g-3 align-items-end'>
                              <div className='col-md-auto'>
                                <h6 className='m-0 pe-3' >
                                  STAY IN THE KNOW
                                </h6>
                              </div>
                              <div className='col-md-auto'>
                                <label htmlFor='cf_mail' className='text-uppercase' >Email</label>
                              </div>
                              <div className='col-md'>
                                  <div className='form-group'>
                                      <Field name="cf_mail" >
                                          {
                                              props => {
                                                  const { field, meta } = props
                                                  return (
                                                      <>
                                                          <input 
                                                              id='cf_mail' 
                                                              type="text"  
                                                              {...field}
                                                              className = { meta.touched && meta.error ? 'form-control field-error' : 'form-control' }
                                                          />
                                                          {
                                                              meta.touched && meta.error ? ( <span className='error' >{ meta.error }</span> ) : null
                                                          }
                                                      </>
                                                  )
                                              }
                                          }
                                      </Field>
                                  </div>
                              </div>
                              <div className='col-md-auto'>
                                <button className='btn btn_sub' type='submit' >Send</button>
                              </div>
                            </div>
                          </Form>
                        </Formik>
                    </div>
                  )
                }
              </div>
          </div>
      </div>
      <Footer />
    </div>
    </>
  )
}

export default Weirdos