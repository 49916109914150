import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import InnerpageHeader from '../../InnerpageHeader';
import Footer from '../../Footer';
import '../../../style/fonts.css';
import '../../../style/works.css';
import { Link } from 'react-router-dom';
import WorkPagination from '../WorkPagination';
import CategoryBadge from '../CategoryBadge';
import { InstagramEmbed } from 'react-social-media-embed';

function Hama(props) {

    useEffect(() => {
        document.body.removeAttribute('class');
        document.body.classList.add('inner-page', 'single-work-page');
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <HelmetProvider>
            <Helmet>
                <title>
                    {props.metaTitle}
                </title>
                <meta property="og:title" content={props.metaTitle} />
                <meta property="og:description" content={props.metaDescription} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={ window.location } />
                <meta property="og:image" content="" />
            </Helmet>
            <InnerpageHeader/>
            <div className='single-work'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-xxl-12 col-11'>
                            <div className='pageBreadcrumb'>
                                <h6 className='m-0' >
                                    <Link to='/'>Home</Link> / <Link to='/works'>Works</Link> / Videos / Hama
                                </h6>
                            </div>
                            <div className='singleWorkHead my-5'>
                                <h1 className='singleWorkTitle text-uppercase'>
                                Hama
                                </h1>
                                <p>
                                Sensibly Weird Company's collaboration with Hama, a renowned German-based accessories brand, resulted in a diverse range of captivating videos. From the exciting unboxing experience to engaging product demonstrations, Sensibly Weird Company expertly crafted a series of videos that showcased the essence and functionality of Hama's accessories. These videos not only captured the attention of viewers but also provided an immersive experience, allowing potential customers to visualize and understand the value of Hama's products. With their creative storytelling and meticulous attention to detail, Sensibly Weird Company successfully brought Hama's accessories to life, leaving a lasting impression on the audience and solidifying Hama's position as a leader in the industry.
                                </p>
                                <CategoryBadge badgeText={props.catBadgeText} badgeCircle={props.catBadgeCircle} badgeType="category" />
                            </div>
                            <div className='singleWorkContent videoWrap p-3'>
                                <div className='row g-3'>
                                    <div className='col-12'>
                                        <iframe className='w-100 yt-video' src="https://www.youtube.com/embed/a0VH-JlnAnU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                    </div>
                                    <div className='col-md-7'>
                                        <iframe className='w-100 yt-video mb-3' src="https://www.youtube.com/embed/It2Qw6sjUSU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                        <iframe className='w-100 yt-video' src="https://www.youtube.com/embed/InKLfddvabs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                    </div>
                                    <div className='col-md-5'>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <InstagramEmbed url="https://www.instagram.com/reel/CbXWl8LJCb4/" className="w-100" />
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <iframe className='w-100 yt-video' src="https://www.youtube.com/embed/s2OUIyZ80SE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                    </div>
                                    <div className='col-md-5'>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <InstagramEmbed url="https://www.instagram.com/reel/Cb2a3hxpPCN/" className="w-100" />
                                        </div>
                                    </div>
                                    <div className='col-md-7'>
                                        <iframe className='w-100 yt-video mb-3' src="https://www.youtube.com/embed/yoIZWQI6F_Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                        <iframe className='w-100 yt-video' src="https://www.youtube.com/embed/AX_PRqCNXjk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <WorkPagination current={props.workID} />
            </div>
            <Footer />
        </HelmetProvider>
    )
}

export default Hama