import React, { useEffect } from 'react';
import InnerpageHeader from './InnerpageHeader';
import '../style/fonts.css';
import '../style/careers.css';
import Footer from './Footer';
import { Helmet } from 'react-helmet';
import image01 from '../assets/images/careers-image-01.png';
import image02 from '../assets/images/careers-image-02.png';
import job01 from '../assets/images/careers-image-04.png';
import job02 from '../assets/images/careers-image-05.png';
import job03 from '../assets/images/careers-image-06.png';
import job04 from '../assets/images/careers-image-07.png';
import job05 from '../assets/images/careers-image-08.png';
import job06 from '../assets/images/careers-image-09.png';
import { useNavigate } from 'react-router-dom';

function Careers(props) {

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.body.classList.add('inner-page', 'contact-page');
    }, []);

    return (
        <>
           <Helmet>
                <title>
                    {props.metaTitle}
                </title>
                <meta property="og:title" content={props.metaTitle} />
                <meta property="og:description" content={props.metaDescription} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={ window.location } />
                <meta property="og:image" content="" />
            </Helmet>
            <InnerpageHeader/>
            <div className='careersPageHeader pt-md-4 pt-5 pb-4 px-3'>
                <h1 className='text-center text-uppercase m-0' >
                Careers
                </h1>
            </div>
            <div className='careersPageHeaderSub py-5'>
                <div className='container pt-5 pb-md-3'>
                    <div className='row justify-content-center pt-md-4'>
                        <div className='col-xxl-11 col-xl-10 text-center'>
                            <h2 className='m-0' >
                                <img src={image01} alt="why join us" className='sub-text' />
                                <img src={image02} alt="start decor" className='decor-1' />
                                <img src={image02} alt="start decor" className='decor-2' />
                                <img src={image02} alt="start decor" className='decor-3' />
                                <img src={image02} alt="start decor" className='decor-4' />
                                We are free-spirits.<br/>We are curious.<br/>We are excited.<br/>We get carried away<span>That’s it</span>
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className='jobOpeningsHead py-5'>
                <div className='container d-flex justify-content-center'>
                    <h2 className='text-uppercase m-0' >
                        <div className='careerArrow'></div>
                        Current<br/>Job Openings
                    </h2>
                </div>
            </div>
            <div className='jobOpeningsList pb-5'>
                <div className='container-md'>
                    <div className='row justify-content-center'>
                        <div className='col-xl-11'>
                            <div className='row g-1'>
                                <div className='col-lg-4 col-sm-6 jol-outer'>
                                    <div className='jol-item'>
                                        <img src={job01} alt="Social Media Content Specialist" className='w-100' />
                                        <h3 className='jol-title' >
                                            Social Media <br/>Content Specialist
                                        </h3>
                                        <ul className='jol-detalis' >
                                            <li>
                                                Develop and implement social media strategies to enhance brand presence and engagement.
                                            </li>
                                            <li>
                                                Create and curate compelling content across various social media platforms.
                                            </li>
                                            <li>
                                                Monitor and analyze social media performance using analytics tools.
                                            </li>
                                            <li>
                                                Stay updated with industry trends and emerging social media platforms.
                                            </li>
                                        </ul>
                                        <button onClick={() => navigate('/careers/apply-now/social-media-content-specialist')} className='jol-btn' >Apply Now</button>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-sm-6 jol-outer'>
                                    <div className='jol-item'>
                                    <img src={job02} alt="DigitalStrategist" className='w-100' />
                                        <h3 className='jol-title' >
                                        Digital <br/>Strategist
                                        </h3>
                                        <ul className='jol-detalis' >
                                            <li>
                                            Develop and execute digital marketing strategies to drive brand awareness and customer acquisition.
                                            </li>
                                            <li>
                                            Conduct market research and analyze data to identify target audience and optimize campaigns.
                                            </li>
                                            <li>
                                            Collaborate with cross-functional teams to ensure seamless execution of digital initiatives.
                                            </li>
                                            <li>
                                            Monitor and report on digital marketing performance metrics.
                                            </li>
                                        </ul>
                                        <button onClick={() => navigate('/careers/apply-now/digital-strategist')} className='jol-btn' >Apply Now</button>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-sm-6 jol-outer'>
                                    <div className='jol-item'>
                                    <img src={job03} alt="Art Director" className='w-100' />
                                        <h3 className='jol-title' >
                                        Art Director
                                        </h3>
                                        <ul className='jol-detalis' >
                                            <li>
                                            Lead and oversee the creative direction and visual storytelling of marketing campaigns.
                                            </li>
                                            <li>
                                            Collaborate with designers, copywriters, and other stakeholders to develop innovative concepts.
                                            </li>
                                            <li>
                                            Provide guidance and feedback to ensure high-quality and consistent visual execution.
                                            </li>
                                            <li>
                                            Stay updated with design trends and industry best practices.
                                            </li>
                                        </ul>
                                        <button onClick={() => navigate('/careers/apply-now/art-director')} className='jol-btn' >Apply Now</button>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-sm-6 jol-outer'>
                                    <div className='jol-item'>
                                    <img src={job04} alt="Graphic Designer" className='w-100' />
                                        <h3 className='jol-title' >
                                        Graphic Designer
                                        </h3>
                                        <ul className='jol-detalis' >
                                            <li>
                                            Create visually appealing and engaging graphics for various marketing materials.
                                            </li>
                                            <li>
                                            Translate concepts into visually appealing designs across print and digital platforms.
                                            </li>
                                            <li>
                                            Collaborate with the creative team to develop design concepts and deliverables.
                                            </li>
                                            <li>
                                            Ensure brand consistency and adherence to design guidelines.
                                            </li>
                                        </ul>
                                        <button onClick={() => navigate('/careers/apply-now/graphic-designer')} className='jol-btn' >Apply Now</button>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-sm-6 jol-outer'>
                                    <div className='jol-item'>
                                    <img src={job05} alt="Video Editor" className='w-100' />
                                        <h3 className='jol-title' >
                                        Video Editor
                                        </h3>
                                        <ul className='jol-detalis' >
                                            <li>
                                            Edit and enhance video footage to create compelling and engaging visual content.

                                            </li>
                                            <li>
                                            Collaborate with stakeholders to understand project requirements and objectives.
                                            </li>
                                            <li>
                                            Incorporate visual effects, sound, and graphics to deliver high-quality videos.
                                            </li>
                                            <li>
                                            Ensure video projects are completed on time and within budget.
                                            </li>
                                        </ul>
                                        <button onClick={() => navigate('/careers/apply-now/video-editor')} className='jol-btn' >Apply Now</button>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-sm-6 jol-outer'>
                                    <div className='jol-item'>
                                    <img src={job06} alt="Illustrator" className='w-100' />
                                        <h3 className='jol-title' >
                                        Illustrator
                                        </h3>
                                        <ul className='jol-detalis' >
                                            <li>
                                            Create original illustrations and artwork for various marketing and promotional materials.
                                            </li>
                                            <li>
                                            Collaborate with the creative team to develop visual concepts and storyboards.
                                            </li>
                                            <li>
                                            Incorporate feedback and revisions to deliver high-quality illustrations.
                                            </li>
                                            <li>
                                            Stay updated with illustration techniques and trends.
                                            </li>
                                        </ul>
                                        <button onClick={() => navigate('/careers/apply-now/illustrator')} className='jol-btn' >Apply Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Careers