import React, { useEffect, useState } from 'react';
import InnerpageHeader from '../InnerpageHeader';
import Footer from '../Footer';
import '../../style/digital.css';
import image05 from '../../assets/images/digital-marketing/leadGenerationImage-005.png';
import { Helmet } from 'react-helmet';

function LeadGeneration(props) {

    const [ showMoreContent, setShowMoreContent ] = useState({
        para1 : false,
        para2 : false
    });

    const READ_MORE = 'read more';
    const READ_LESS = 'read less';

  useEffect(() => {
    document.body.removeAttribute('class');
    document.body.classList.add('inner-page', 'digital-lead-page');
    window.scrollTo({ top: 0, behavior: 'smooth' });
}, []);

  return (
    <>
        <Helmet>
            <title>
                {props.metaTitle}
            </title>
            <meta property="og:title" content={props.metaTitle} />
            <meta property="og:description" content={props.metaDescription} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={ window.location } />
            <meta property="og:image" content="" />
        </Helmet>
        <InnerpageHeader/>
        <div className='servicesPageContent digitalPageContent category' >
            <div className='servicespageBanner pb-5'>
                <h1 className='text-center my-0 py-5 text-white'>
                    Lead generation
                </h1>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-9 col-sm-12 col-11'>
                            <p className='m-0 text-white py-4' >
                            Lead generation is the lifeblood of any successful digital marketing campaign. It's the process of identifying and nurturing potential customers for your products or services, and without it, your business could be missing out on a significant revenue stream. But lead generation is more than just a numbers game. It's about building relationships, providing value, and establishing trust with your potential customers. By doing so, you can position yourself as a thought leader in your industry and create a steady stream of qualified leads that convert into paying customers. In today's fiercely competitive digital marketplace, understanding the importance of lead generation is not just essential, it's critical to the long-term success of your business.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='igbg-10 py-5 leadSection2'>
                <div className='container py-4'>
                    <div className='row g-0 justify-content-center'>
                        <div className='col-lg-9 col-sm-12 col-11'>
                            <h3 className=' text-center mb-4 text-uppercase igText-01' >
                            The Sensibly Weird Lead generation model
                            </h3>
                        </div>
                        <div className='col-lg-9 col-sm-12 col-11'>
                            <p className='m-0 text-dark' >
                            At Sensibly Weird Company, we take a refreshingly different approach to lead generation. Our team of experts isn't afraid to get a little weird with our strategies, combining cutting-edge technology, creative content, and data-driven research to generate high-quality leads for businesses across various industries.<br/><br/>
                            We've worked with a diverse catalogue of clients, from Fintech, Telehealth, and Hospitality brands to even Ice Cream e-commerce, EdTech, and B2B businesses, wowing them with our lead generation strategies.<br/><br/>
                            {
                                showMoreContent.para1 && (
                                    <span>
                                        We tap into your target audience's mindset and connect with them in meaningful ways, using sensible and practical tactics that are anything but ordinary. We don't rely on tired, old methods that everyone else is using - we're all about exploring new and unconventional ideas that set you apart from the competition.<br/><br/>
                                        Partnering with Sensibly Weird Company means working with a team that's passionate about helping your business grow. We're approachable, friendly, and always willing to go the extra mile to achieve your goals. With our help, you can feel confident in generating the high-quality leads you need to succeed.<br/><br/>
                                    </span>
                                )
                            }
                            </p>
                            <span 
                                className='readMoreBtn-Text' 
                                onClick={() => {
                                    setShowMoreContent(prevState => {
                                        return {
                                            ...prevState,
                                            para1 : !prevState.para1
                                        }
                                    })
                                }}
                            >
                                {
                                    showMoreContent.para1 ? READ_LESS : READ_MORE
                                }
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='py-5 holyGrail'>
                <div className='container py-4'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-5 col-sm-9 col-11'>
                            <img src={image05} alt='lead generation' width="424" height="424" className='img-fluid lg-img04' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='lgHightlights'>
                <div className='container-fluid p-xxl-4 p-3 pt-0'>
                    <div className='row g-xxl-4 g-3'>
                        <div className='col-lg-3 col-sm-6'>
                            <div className='lgh-box igbg-1'>
                                <h4 className='text-white text-center' >
                                Provoke
                                </h4>
                                <p className='m-0 text-black' >
                                Don't miss out on potential prospects who want to hear from you. Our Lead Generation agency uses a variety of digital marketing methods, including paid ads, social media, and content marketing, to provoke interest from highly qualified, proactive prospects and bring them directly to your business in real-time.
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-sm-6'>
                            <div className='lgh-box igbg-2'>
                                <h4 className='text-white text-center' >
                                Engage
                                </h4>
                                <p className='m-0 text-black' >
                                We understand what works best in the customer sales cycle. With 10 years of extensive end-to-end monitoring of unique advertising sources and content placements, our Online Lead Generation company knows how to engage with your target audience. Our experience, knowledge, and lead scoring ensure we create qualified, cost-effective, and reliable lead supply for your organization.
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-sm-6'>
                            <div className='lgh-box igbg-3'>
                                <h4 className='text-white text-center' >
                                Convert
                                </h4>
                                <p className='m-0 text-black' >
                                We prioritize quality over quantity when it comes to lead generation. Our Lead Generation services provide qualified leads that convert into sales. We work with you on a long-term basis and encourage lead quality feedback to ensure a successful lead generation campaign. If you're able to convert the leads we provide, you won't need to buy more. Let us help you convert leads into growth for your business.
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-sm-6'>
                            <div className='lgh-box igbg-4'>
                                <h4 className='text-white text-center' >
                                Flourish
                                </h4>
                                <p className='m-0 text-black' >
                                At our Lead Generation agency, we're committed to helping your business flourish. We believe in a collaborative approach to lead generation, and we're dedicated to achieving sales results that exceed your expectations. With our assistance, you can be confident that your business will experience long-term growth and success. Let's work together to ensure your business flourishes.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='py-5 lgServices'>
                <div className='container py-4'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-10 col-sm-12 col-11'>
                            <h3 className='comforterText mb-1 igText-02' >
                            Get Weirdly Effective Results: <br/>
                            Why Lead Generation is Essential for Your Business Growth
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className='lgServices lgServices-bxs pb-5'>
                <div className='container mb-4'>
                    <div className='row g-md-0 g-3 justify-content-center'>
                        <div className='col-lg-10'>
                            <div className='decorativeEle decorative01'></div>
                            <div className='igbg-5 px-5 py-4 igd'>
                                <p className='m-0 text-dark' >
                                <strong>Reduced Cost-Per-Click (CPC):</strong> By generating high-quality leads, businesses can improve their ad relevance scores, resulting in lower CPCs on platforms like Google Ads and Facebook Ads.
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-10'>
                            <div className='igbg-11 px-5 py-4 igd'>
                                <p className='m-0 text-dark' >
                                <strong>Increased Return on Investment (ROI):</strong> Lead generation helps businesses get a better return on investment since they are targeting potential customers who are most likely to convert, resulting in a higher ROI for their advertising spend.
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-10'>
                            <div className='igbg-7 px-5 py-4 igd'>
                                <p className='m-0 text-dark' >
                                <strong>Cost-Per-Qualified-Lead (CPQL):</strong> By generating leads that are more likely to convert into customers, businesses can improve their cost-per-qualified-lead (CPQL) metric, which measures the cost to acquire a lead that meets the company's specific criteria for a qualified lead.
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-10'>
                            <div className='igbg-6 px-5 py-4 igd'>
                                <p className='m-0 text-dark' >
                                <strong>Better Conversion Rates:</strong> By targeting a specific audience through lead generation efforts, businesses can improve their conversion rates and turn more leads into paying customers.
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-10'>
                            <div className='decorativeEle decorative02'></div>
                            <div className='igbg-8 px-5 py-4 igd'>
                                <p className='m-0 text-dark' >
                                <strong>Long-Term Success:</strong> By consistently generating high-quality leads, businesses can ensure long-term success and sustainable growth, since they are building a strong customer base that will continue to generate revenue for their organization over time.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='igbg-12 py-5 leadSection2'>
                <div className='container py-4'>
                    <div className='row g-0 justify-content-center'>
                        <div className='col-lg-9 col-sm-12 col-11'>
                            <h3 className=' mb-4 text-uppercase igText-03' >
                            The Lead Generation Journey
                            </h3>
                        </div>
                        <div className='col-lg-9 col-sm-12 col-11 text-white'>
                            <p className='m-0 ' >
                                At Sensibly Weird Company, we pride ourselves on our ability to generate positive leads for your business from a range of online platforms. Our primary objective is to ensure that you are at the top of your game!<br/><br/>
                                We take care of ad management and optimize your CPL (Cost Per Lead) on platforms such as Google, Bing, Facebook, Instagram, LinkedIn, YouTube, Display, Pinterest, Snapchat, and more, based on CPQL (Cost Per Qualified Lead).<br/><br/>
                                {
                                    showMoreContent.para2 && (
                                        <span>
                                            Our Online Lead Generation team also specializes in creating custom-built landing pages that are optimized for high conversion rates. We constantly run A/B tests to improve your conversion rates and increase your ROI.<br/><br/>
                                            We integrate with your CRM and utilize call tracking tools to determine which ads, keywords, and campaigns bring in the most qualified leads. You can even use our tool interface to text leads directly.<br/><br/>
                                            We also help set up automated email and text message nurturing campaigns to increase your connection rates with potential leads.<br/><br/>
                                            Finally, you'll have a dedicated expert as your single point of contact who knows your business inside and out, and you can view your campaign data, trends, and performance in real-time, anytime, from any device. Let's get sensibly weird and generate leads like never before!<br/><br/>
                                        </span>
                                    )
                                }
                            </p>
                            <span 
                                className='readMoreBtn-Text' 
                                onClick={() => {
                                    setShowMoreContent(prevState => {
                                        return {
                                            ...prevState,
                                            para2 : !prevState.para2
                                        }
                                    })
                                }}
                            >
                                {
                                    showMoreContent.para2 ? READ_LESS : READ_MORE
                                }
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='igbg-2 pt-5 leadSection3'>
                <div className='container pt-4'>
                    <div className='row g-0 justify-content-center'>
                        <div className='col-lg-9 col-sm-12 col-11'>
                            <h3 className=' mb-4 text-center text-uppercase igText-04 mb-5' >
                            The Lead Generation Journey
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className='lgHightlights igbg-2 pb-5 leadSection4'>
                <div className='container-fluid px-lg-5 px-3 pb-4'>
                    <div className='row g-xxl-4 g-3'>
                        <div className='col-md-4'>
                            <div className='lgh-box igbg-4'>
                                <h4 className='text-dark' >
                                Audit
                                </h4>
                                <p className='m-0 text-black' >
                                At Sensibly Weird, we believe in a holistic approach to lead generation. We begin by conducting a thorough audit of your business to identify key decision makers and establish campaign objectives. Once we've gathered all the necessary information, we'll create a personalized lead generation strategy tailored specifically to your business needs.
                                </p>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='lgh-box igbg-1'>
                                <h4 className='text-dark' >
                                Implementation
                                </h4>
                                <p className='m-0 text-black' >
                                Implementation of the strategy is where things get really interesting. Our team of weirdly creative marketers will implement the campaign across multiple platforms using a unified approach, ensuring your brand messaging stays consistent and integrated. We'll continuously monitor the results of your campaign, making adjustments and optimizations as needed to achieve the best possible outcomes.
                                </p>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='lgh-box igbg-3'>
                                <h4 className='text-dark' >
                                Real time reporting
                                </h4>
                                <p className='m-0 text-black' >
                                Our reports are not your ordinary, boring reports. We'll provide you with real-time updates, interactive dashboards and the information you need to make informed decisions. But it doesn't stop there. We also schedule regular strategy reviews to ensure we're on the right path, and to keep things sensibly weird.<br/><br/>
                                At Sensibly Weird, we're not your average lead generation agency. We'll help you identify and reach your ideal customers with a strategy that is both sensible and delightfully weird.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </>
  )
}

export default LeadGeneration