import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import InnerpageHeader from '../../InnerpageHeader';
import Footer from '../../Footer';
import '../../../style/fonts.css';
import '../../../style/works.css';
import { Link } from 'react-router-dom';
import image01 from '../../../assets/images/works/website/ayurcentral/digital-ayurcentralImage01.png';
import image02 from '../../../assets/images/works/website/ayurcentral/digital-ayurcentralImage02.png';
import image03 from '../../../assets/images/works/website/ayurcentral/digital-ayurcentralImage03.png';
import image04 from '../../../assets/images/works/website/ayurcentral/digital-ayurcentralImage04.png';
import image05 from '../../../assets/images/works/website/ayurcentral/digital-ayurcentralImage05.png';
import image06 from '../../../assets/images/works/website/ayurcentral/digital-ayurcentralImage06.png';
import WorkPagination from '../WorkPagination';
import CategoryBadge from '../CategoryBadge';

function Ayurcentral(props) {

    useEffect(() => {
        document.body.removeAttribute('class');
        document.body.classList.add('inner-page', 'single-work-page');
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <HelmetProvider>
            <Helmet>
                <title>
                    {props.metaTitle}
                </title>
                <meta property="og:title" content={props.metaTitle} />
                <meta property="og:description" content={props.metaDescription} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={ window.location } />
                <meta property="og:image" content="" />
            </Helmet>
            <InnerpageHeader/>
            <div className='single-work'>
                <div className='container pb-4'>
                    <div className='row justify-content-center'>
                        <div className='col-xxl-12 col-11'>
                            <div className='pageBreadcrumb'>
                                <h6 className='m-0' >
                                    <Link to='/'>Home</Link> / <Link to='/works'>Works</Link> / Website / AyurCentral
                                </h6>
                            </div>
                            <div className='singleWorkHead my-5'>
                                <h1 className='singleWorkTitle text-uppercase mb-3'>
                                Ayur<br className='d-sm-none' />central
                                </h1>
                                <p>
                                AyurCentral's website, once thriving with tremendous traffic, faced a sudden decline after an oversight in the domain transfer process. The experts at Sensibly Weird Company swiftly recognized the gravity of the situation and understood the implications it had on AyurCentral's market position. Timely identification of the crisis laid the foundation for a comprehensive strategy to address the issue.
                                </p>
                                <CategoryBadge badgeText={props.catBadgeText} badgeCircle={props.catBadgeCircle} badgeType="category" />
                            </div>
                            <div className='singleWorkContent ayurcentralGrid'>
                                <img src={image01} alt='ayurcentral digital' className='w-100' />
                                <img src={image02} alt='ayurcentral digital' className='w-100' />
                                <img src={image03} alt='ayurcentral digital' className='w-100' />
                                <img src={image04} alt='ayurcentral digital' className='w-100' />
                                <img src={image05} alt='ayurcentral digital' className='w-100' />
                                <img src={image06} alt='ayurcentral digital' className='w-100' />
                            </div>
                        </div>
                    </div>
                </div>
                <WorkPagination current={props.workID} />
            </div>
            <Footer />
        </HelmetProvider>
    )
}

export default Ayurcentral