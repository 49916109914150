import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';

function HomePage( props ) {

    useEffect(() => {
        document.body.classList.add('home');
    });

    return (
        <>
            <Helmet>
                <title>
                    {props.metaTitle}
                </title>
                <meta property="og:title" content={props.metaTitle} />
                <meta property="og:description" content={props.metaDescription} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={ window.location } />
                <meta property="og:image" content="" />
            </Helmet>
            <div className='h-100'></div>
        </>
    )
}

export default HomePage