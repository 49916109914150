import React, { useEffect, useRef, useState } from 'react';
import InnerpageHeader from './InnerpageHeader';
import '../style/contact.css';
import Footer from './Footer';
import { Helmet } from 'react-helmet';
import { Formik, Form, Field } from 'formik';
import axios from 'axios';

function Contact(props) {

    const [ formMessage, setFormMessage ] = useState('');
    const formArea = useRef();

    const initialValues = {
        cf_name: '',
        cf_mail: '',
        cf_phone: '',
        cf_msg: ''
    };

    const onSubmit = ( values, { resetForm } ) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setFormMessage('pending')
        axios.post( 'https://sensiblyweird.com/phpmailer/contact_mail.php', values ).then( response => {
            setFormMessage( response.data.status )
            console.log( response.data.data )
        } )
    }

    const validate = values => {
        let errors = {}
        if(!values.cf_name) {
            errors.cf_name = 'Please provide a name'
        }
        if( !values.cf_mail ) {
            errors.cf_mail = 'Please provide an email'
        } else if( !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.cf_mail)) ) {
            errors.cf_mail = 'Please provide a valid email'
        }
        if( !values.cf_phone ) {
            errors.cf_phone = 'Please provide a contact number'
        } else if( !(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(values.cf_phone)) ) {
            errors.cf_phone = 'Please provide a valid phone number'
        }
        return errors
    }

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.body.classList.add('inner-page', 'contact-page');
    }, []);

    return (
        <>
           <Helmet>
                <title>
                    {props.metaTitle}
                </title>
                <meta property="og:title" content={props.metaTitle} />
                <meta property="og:description" content={props.metaDescription} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={ window.location } />
                <meta property="og:image" content="" />
            </Helmet>
            <InnerpageHeader/>
            <div className='contactPageWrap'>
                <div className='contactPageContent d-flex flex-column justify-content-center' >
                    <div className='container'>
                        <div className='row justify-content-center'>
                            <div className='col-xl-6 xol-md-8'>
                                <h1 className='text-center text-white m-0 contactPageHead pb-5' >
                                    SAY HELLO
                                </h1>
                                {
                                    formMessage !== '' ? (
                                        <div className='formResponseHolder' >
                                            <p className='formResponse text-center text-white m-0' >
                                                {
                                                    formMessage === 'pending' ? (
                                                        <>
                                                            Please wait...
                                                        </>
                                                    ) : (
                                                        <>
                                                            {
                                                                formMessage === 'success' ? (
                                                                    <>
                                                                        <span>Thank you for getting in touch!</span><br/> 
                                                                        We appreciate you contacting us. One of our colleagues will get back in touch with you soon! Have a great day!
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <span className='text-danger' >Whoops, <br/>something went wrong!</span><br/> 
                                                                        Please try again later.
                                                                    </>
                                                                )
                                                            }
                                                        </>
                                                    )
                                                }
                                            </p>
                                        </div>
                                    ) : (
                                        <div className='contactFormWrap px-md-0 px-2' ref={formArea} >
                                            <Formik 
                                                initialValues={initialValues}
                                                onSubmit={onSubmit}
                                                validate={validate}
                                            >
                                                <Form>
                                                    <div className='form-group'>
                                                        <Field name = 'cf_name' >
                                                            {
                                                                props => {
                                                                    const { field, meta } = props
                                                                    return (
                                                                        <>
                                                                            <input 
                                                                                placeholder = 'YOUR NAME' 
                                                                                id = 'cf_name' 
                                                                                type = "text"   
                                                                                {...field}
                                                                                className = { meta.touched && meta.error ? 'form-control field-error' : 'form-control' }
                                                                            />
                                                                            {
                                                                                meta.touched && meta.error ? ( <span className='error' >{ meta.error }</span> ) : null
                                                                            }
                                                                        </>
                                                                    )
                                                                }
                                                            }
                                                        </Field>
                                                    </div>
                                                    <div className='form-group'>
                                                        <Field name="cf_mail" >
                                                            {
                                                                props => {
                                                                    const { field, meta } = props
                                                                    return (
                                                                        <>
                                                                            <input 
                                                                                placeholder='YOUR EMAIL' 
                                                                                id='cf_mail' 
                                                                                type="text"  
                                                                                {...field}
                                                                                className = { meta.touched && meta.error ? 'form-control field-error' : 'form-control' }
                                                                            />
                                                                            {
                                                                                meta.touched && meta.error ? ( <span className='error' >{ meta.error }</span> ) : null
                                                                            }
                                                                        </>
                                                                    )
                                                                }
                                                            }
                                                        </Field>
                                                    </div>
                                                    <div className='form-group'>
                                                        <Field name='cf_phone' >
                                                            {
                                                                props => {
                                                                    const { field, meta } = props
                                                                    return (
                                                                        <>
                                                                            <input 
                                                                                id='cf_phone' 
                                                                                type="text" 
                                                                                placeholder='YOUR CONTACT NUMBER' 
                                                                                {...field}
                                                                                className = { meta.touched && meta.error ? 'form-control field-error' : 'form-control' }
                                                                            />
                                                                            {
                                                                                meta.touched && meta.error ? ( <span className='error' >{ meta.error }</span> ) : null
                                                                            }
                                                                        </>
                                                                    )
                                                                }
                                                            }
                                                        </Field>
                                                    </div>
                                                    <div className='form-group'>
                                                        <Field
                                                            placeholder='YOUR MESSAGE' 
                                                            name='cf_msg' 
                                                            id='cf_msg'
                                                            as="textarea"
                                                            rows="5" 
                                                            className = 'form-control'
                                                        />
                                                    </div>
                                                    <div className='row justify-content-center'>
                                                        <div className='col-6'>
                                                            <div className='form-group alt'>
                                                                <input className='btn form-control w-100 btnSubmit' type="submit" value="SEND MESSAGE" name='cd_submit' id='vf_submit' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Form>
                                            </Formik>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    )
}

export default Contact