import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import HomePage from "./components/HomePage";
import Navigation from "./components/Navigation";
import Works from "./components/Works";
import Services from "./components/services/Services";
import Weirdos from "./components/Weirdos";
import Thanathu from "./components/Thanathu";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import "./style/App.css";
import Contact from "./components/Contact";
import ThoughtStarter from "./components/ThoughtStarter";
import Seo from "./components/services/Seo";
import Ppc from "./components/services/Ppc";
import LeadGeneration from "./components/services/LeadGeneration";
import AptayuBranding from "./components/works/branding/Aptayu";
import PalmtreeBranding from "./components/works/media/Palmtree";
import AptayuPackaging from "./components/works/packaging/Aptayu";
import Palmtree from "./components/works/campaign/Palmtree";
import AyurcentralDigital from "./components/works/communication/Ayurcentral";
import PalmtreeCommunication from "./components/works/communication/Palmtree";
import AyurcentralWebsite from "./components/works/website/Ayurcentral";
import Alf from "./components/works/website/Alf";
import Lazza from "./components/works/campaign/Lazza";
import Manorama from "./components/works/campaign/manorama";
import Hama from "./components/works/video/Hama";
import ContentAndStories from "./components/services/ContentAndStories";
import Unbranding from "./components/services/Unbranding";
import Unadvertising from "./components/services/Unadvertising";
import DigitalMarketing from "./components/services/DigitalMarketing";
import LazzaVideos from './components/works/video/Lazza';
import GrandHayatt from './components/works/communication/GrandHayatt';

import badge01 from './assets/images/unbranding-badge.png';
import badge02 from './assets/images/unpackaging-badge.png';
import badge03 from './assets/images/unadvertising-badge.png';

import brandText from './assets/images/works/categoryBadge/Branding-B.png';
import brandCircle from './assets/images/works/categoryBadge/Branding-Circle.png';
import campText from './assets/images/works/categoryBadge/Campaigns-C.png';
import campCircle from './assets/images/works/categoryBadge/Campaigns-Circle.png';
import commuText from './assets/images/works/categoryBadge/Communications-C.png';
import commuCircle from './assets/images/works/categoryBadge/Communications-Circle.png';
import mediaCircle from './assets/images/works/categoryBadge/Media-Circle.png';
import mediaText from './assets/images/works/categoryBadge/Media-M.png';
import packCircle from './assets/images/works/categoryBadge/Packaging-Circle.png';
import packText from './assets/images/works/categoryBadge/Packaging-P.png';
import videoCircle from './assets/images/works/categoryBadge/Videos-Circle.png';
import videoText from './assets/images/works/categoryBadge/Videos-V.png';
import websiteCircle from './assets/images/works/categoryBadge/Website-Circle.png';
import websiteText from './assets/images/works/categoryBadge/Website-W.png';
import resultText from './assets/images/works/categoryBadge/Results-Arrow.png';
import resultCircle from './assets/images/works/categoryBadge/Results-Circle.png';
import Careers from "./components/Careers";
import { Blogs } from "./components/Blogs";
import { CareersApplyNow } from "./components/CareersApplyNow";

function App() {

    return ( 
        <BrowserRouter  >
            <Home />
            <Navigation />
            <Routes>
                <Route 
                    path="/" 
                    exact 
                    element={
                        <HomePage
                            metaTitle="Sensibly Weird Company"
                            metaDescription=""
                        />
                    } 
                />
                <Route 
                    path="/thanathu" 
                    exact 
                    element={
                        <Thanathu
                            metaTitle="Thanathu | Sensibly Weird Company"
                            metaDescription=""
                        />
                    } 
                />
                <Route 
                    path="/weirdos" 
                    exact 
                    element={
                        <Weirdos 
                            metaTitle="Weirdos | Sensibly Weird Company"
                            metaDescription=""  
                        />
                    } 
                />
                <Route 
                    path="/contact-us" 
                    exact 
                    element={
                        <Contact
                            metaTitle="Say Hello | Sensibly Weird Company"
                            metaDescription=""
                        />
                    } 
                />
                <Route 
                    path="/careers/" 
                    exact 
                    element={
                        <Careers
                            metaTitle="Careers | Sensibly Weird Company"
                            metaDescription=""
                        />
                    } 
                />
                <Route 
                    path="/careers/apply-now/:id" 
                    exact 
                    element={
                        <CareersApplyNow
                            metaTitle="Apply Now - Careers | Sensibly Weird Company"
                            metaDescription="Time to make your move and show us what you've got!"
                        />
                    } 
                />
                <Route 
                    path="/thought-starters" 
                    exact 
                    element={
                        <ThoughtStarter
                            metaTitle="Thought Starters | Sensibly Weird Company"
                            metaDescription=""
                        />
                    } 
                />
                <Route 
                    path="/services" 
                    exact 
                    element={
                        <Services
                            metaTitle="Services | Sensibly Weird Company"
                            metaDescription=""
                        />
                    } 
                />
                <Route 
                    path="/services/content-and-stories" 
                    exact 
                    element={
                        <ContentAndStories
                            metaTitle="Content A=and Stories | Sensibly Weird Company"
                            metaDescription=""
                        />
                    } 
                />
                <Route 
                    path="/services/digital-marketing" 
                    exact 
                    element={
                        <DigitalMarketing
                            metaTitle="Digital Marketing | Sensibly Weird Company"
                            metaDescription=""
                        />
                    } 
                />
                <Route 
                    path="/services/digital-marketing/lead-generation" 
                    exact 
                    element={
                        <LeadGeneration
                            metaTitle="Lead Generation | Sensibly Weird Company"
                            metaDescription=""
                        />
                    } 
                />
                <Route 
                    path="/services/digital-marketing/seo" 
                    exact 
                    element={
                        <Seo
                            metaTitle="Search Engine Optimization | Sensibly Weird Company"
                            metaDescription=""
                        />
                    } 
                />
                <Route 
                    path="/services/digital-marketing/ppc" 
                    exact 
                    element={
                        <Ppc
                            metaTitle="Pay Per Clicks / Performance Marketing | Sensibly Weird Company"
                            metaDescription=""
                        />
                    } 
                />
                <Route 
                    path="/services/branding" 
                    exact 
                    element={
                        <Unbranding 
                            brandBadge={badge01} 
                            packageBadge={badge02} 
                            metaTitle="Unbranding | Sensibly Weird Company"
                            metaDescription=""
                        />
                    } 
                />
                <Route 
                    path="/services/advertising" 
                    exact 
                    element={
                        <Unadvertising 
                            advertBadge={badge03} 
                            metaTitle="Unadvertising | Sensibly Weird Company"
                            metaDescription=""
                        />
                    } 
                />
                <Route 
                    path="/works" 
                    exact 
                    element={
                        <Works
                            metaTitle="Works | Sensibly Weird Company"
                            metaDescription=""
                        />
                    } 
                />
                <Route 
                    path="/works/branding/aptayu" 
                    exact 
                    element={
                        <AptayuBranding 
                            catBadgeText={brandText} 
                            catBadgeCircle={brandCircle} 
                            metaTitle="Aptayu - Branding | Sensibly Weird Company"
                            metaDescription=""
                            workID = '0'
                        /> 
                    } 
                />
                <Route 
                    path="/works/media/palmtree" 
                    exact 
                    element={
                        <PalmtreeBranding 
                            catBadgeText={mediaText} 
                            catBadgeCircle={mediaCircle} 
                            metaTitle="Palm tree - Media | Sensibly Weird Company"
                            metaDescription=""
                            workID = "1"
                        />
                    } 
                />
                <Route 
                    path="/works/communication/ayurcentral" 
                    exact 
                    element={
                        <AyurcentralDigital 
                            catBadgeText={commuText} 
                            catBadgeCircle={commuCircle} 
                            metaTitle="AyurCentral - Communication | Sensibly Weird Company"
                            metaDescription=""
                            workID = "2"
                        />
                    } 
                />
                <Route 
                    path="/works/communication/grandhayatt" 
                    exact 
                    element={
                        <GrandHayatt 
                            catBadgeText={commuText} 
                            catBadgeCircle={commuCircle} 
                            metaTitle="Grand Hyatt Kochi - Communication | Sensibly Weird Company"
                            metaDescription=""
                            workID = "12"
                        />
                    } 
                />
                <Route 
                    path="/works/campaign/lazza" 
                    exact 
                    element={
                        <Lazza 
                            catBadgeText={campText} 
                            catBadgeCircle={campCircle} 
                            resultText={resultText} 
                            resultCircle={resultCircle} 
                            metaTitle="Lazza - Campaign | Sensibly Weird Company"
                            metaDescription=""
                            workID = "3"
                        />
                    } 
                />
                <Route 
                    path="/works/campaign/palmtree/" 
                    exact 
                    element={
                        <Palmtree 
                            catBadgeText={campText} 
                            catBadgeCircle={campCircle} 
                            resultText={resultText} 
                            resultCircle={resultCircle} 
                            metaTitle="Palm Tree - Campaign | Sensibly Weird Company"
                            metaDescription=""
                            workID = "4"
                        />
                    } 
                />
                <Route 
                    path="/works/campaign/palmtree/:id" 
                    exact 
                    element={
                        <Palmtree 
                            catBadgeText={campText} 
                            catBadgeCircle={campCircle} 
                            resultText={resultText} 
                            resultCircle={resultCircle} 
                            metaTitle="Palm Tree - Campaign | Sensibly Weird Company"
                            metaDescription=""
                            workID = "4"
                        />
                    } 
                />
                <Route 
                    path="/works/campaign/manorama" 
                    exact 
                    element={
                        <Manorama 
                            catBadgeText={campText} 
                            catBadgeCircle={campCircle} 
                            resultText={resultText} 
                            resultCircle={resultCircle} 
                            metaTitle="Manorama - Campaign | Sensibly Weird Company"
                            metaDescription=""
                            workID = "5"
                        />
                    } 
                />
                <Route 
                    path="/works/packaging/aptayu" 
                    exact 
                    element={
                        <AptayuPackaging 
                            catBadgeText={packText} 
                            catBadgeCircle={packCircle} 
                            metaTitle="Aptayu - Packaging | Sensibly Weird Company"
                            metaDescription=""
                            workID = "6"
                        />
                    } 
                />
                <Route 
                    path="/works/website/alf" 
                    exact 
                    element={
                        <Alf 
                            catBadgeText={websiteText} 
                            catBadgeCircle={websiteCircle} 
                            metaTitle="ALF - Website | Sensibly Weird Company"
                            metaDescription=""
                            workID="7"
                        />
                    } 
                />
                <Route 
                    path="/works/website/ayurcentral" 
                    exact 
                    element={
                        <AyurcentralWebsite 
                            catBadgeText={websiteText} 
                            catBadgeCircle={websiteCircle} 
                            metaTitle="AyurCentral - Website | Sensibly Weird Company"
                            metaDescription=""
                            workID = "8"
                        />
                    } 
                />
                <Route 
                    path="/works/communication/palmtree" 
                    exact 
                    element={
                        <PalmtreeCommunication 
                            catBadgeText={commuText} 
                            catBadgeCircle={commuCircle} 
                            metaTitle="Palm tree - communication | Sensibly Weird Company"
                            metaDescription=""
                            workID = "9"
                        />
                    } 
                />
                <Route 
                    path="/works/videos/hama" 
                    exact 
                    element={
                        <Hama 
                            catBadgeText={videoText} 
                            catBadgeCircle={videoCircle} 
                            metaTitle="Hama - Videos | Sensibly Weird Company"
                            metaDescription=""
                            workID = "10"
                        />
                    } 
                />
                <Route 
                    path="/works/videos/lazza" 
                    exact 
                    element={
                        <LazzaVideos 
                            catBadgeText={videoText} 
                            catBadgeCircle={videoCircle} 
                            metaTitle="Lazza - Videos | Sensibly Weird Company"
                            metaDescription=""
                            workID = "11"
                        />
                    } 
                />
                <Route 
                    path="/blog" 
                    exact 
                    element={<Blogs />}
                />
                <Route 
                    path="*" 
                    exact 
                    element={
                        <HomePage
                            metaTitle="Sensibly Weird Company"
                            metaDescription=""
                        />
                    } 
                />
            </Routes>
        </BrowserRouter>
    );
}

export default App;